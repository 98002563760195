import styled from "styled-components/macro";
import { ultimath } from "./Static/ultimath";
import { arrow } from "./Static/arrow";
import { useState } from "react";
import Accordion1 from "./Accordion1";
import { API } from "../../Consts/Api";

const StyledContainer = styled.section`
    max-width: 980px;
    margin: 0 auto;
    padding-top: 100px;
    padding-bottom: 60px;
    font-family: Inter;

    header {
        display: flex;
        justify-content: center;
    }

    main {
        margin-top: 66px;
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    .accordion {
        background: #ece7db;
        padding: 32px;
    }

    .accordion-header {
        display: flex;
        /* align-items: center; */
        justify-content: space-between;
    }

    .accordion-header-text {
        color: #005840;
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
    }

    .accordion-content {
        /* border-top: 1px solid #dfd5bf; */
        /* padding-top: 24px; */
        /* margin-top: 24px; */
        font-size: 15px;
        color: #333;
        line-height: 20px;
    }

    .circle {
        margin-top: 6px;
        min-width: 5px;
        height: 5px;
        background-color: #333;
        display: inline;
        border-radius: 100%;
    }

    .rules-container {
        li {
            position: relative;
            margin-left: 24px;
            &::after {
                content: "";
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: ${(props) => props.theme.black};
                position: absolute;
                top: 5px;
                left: -18px;
                z-index: 1000;
            }
        }

        p {
            margin-bottom: 12px;
        }
    }
`;

const Rules = () => {
    // const [activeIndex, setActiveIndex] = useState(1);

    // const handleItemClick = (index) => {
    //     setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    // };

    const [content, setContent] = useState({});

    API.axios
        .get(API.createApiRoute("legal"))
        .then((response) => setContent(response.data.data))
        .catch((error) => console.error(error.response));

    function createMarkup(text) {
        return { __html: text };
    }

    return (
        <StyledContainer>
            <header>{ultimath}</header>
            <main>
                {/* <Accordion1 
                    isOpen={activeIndex === 1}
                    onClick={() => handleItemClick(1)}
                /> */}

                <div className="accordion">
                    {/* <div className="accordion-header"> */}
                        {/* <span className="accordion-header-text">ПРАВИЛА</span> */}
                        {/* {arrow} */}
                    {/* </div> */}

                    <div className="accordion-content">
                        {content.terms_and_conditions && (
                            <div
                                className="rules-container"
                                dangerouslySetInnerHTML={createMarkup(
                                    content.terms_and_conditions
                                )}
                            ></div>
                        )}
                    </div>
                </div>
            </main>
        </StyledContainer>
    );
};

export default Rules;
