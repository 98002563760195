import React, { useEffect, useState } from "react";

//npm imports
import { useTranslation } from "react-i18next";
import { withTheme } from "styled-components/macro";

//component imports
import { API } from "../../../../Consts/Api";

//other imports
import useMediaCustom from "../../../../Hooks/useMediaCustom";
import HomepageHeroDesktop from "./HomepageHeroDesktop";
import HomepageHeroMobile from "./HomepageHeroMobile";

const HomePageHero = ({ handleScrollToSection }) => {
  const { t } = useTranslation();
  const isLaptop = useMediaCustom("(min-width: 992px)");

  return (
    <HomepageHeroDesktop
      handleScrollToSection={handleScrollToSection}
      isLaptop={isLaptop}
    />
  )
};

export default withTheme(HomePageHero);
