import React from "react";
import styled from "styled-components/macro";

const Container = styled.section`
  position: absolute;
  top: 0;
  width: 100%;
  height: 250px;

  &:hover div.alert-div {
    opacity: 1;
  }

  @media (max-width: 768px) {
    height: 300px;
  }
`;

const AlertDiv = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 150px;
  background-color: #ffcc00;
  color: #333;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transition: opacity 0.4s ease-in-out;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding-left: 16px;
    padding-right: 16px;
    flex-direction: column;
  }
`;

const AlertText = styled.p`
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  text-align: center;
`;

const AlertIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;

  svg {
    width: 100%;
    height: 100%;
    fill: #333;
  }
`;

const Alert = () => {
  return (
    <Container>
      <AlertDiv className="alert-div">
        <AlertIcon>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M12 0L0 22h24L12 0zm1.5 19h-3v-3h3v3zm0-5h-3V9h3v5z" />
          </svg>
        </AlertIcon>
        <div>
          <AlertText>
            Не прави рефреш на страната и не го исклучувај табот!
          </AlertText>
          <AlertText>Во спротивно резултатот нема да биде зачуван!</AlertText>
        </div>
      </AlertDiv>
    </Container>
  );
};

export default Alert;
