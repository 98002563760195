import React from "react";

const NUCollegeLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="181"
      height="39"
      viewBox="0 0 181 39"
      fill="none"
    >
      <g clip-path="url(#clip0_304_8812)">
        <path
          d="M6.33227 35.0585H0V0.932504H6.33227V10.9626H6.28478C6.65305 9.3516 7.24999 7.80033 8.05781 6.35505C8.79197 5.05533 9.74496 3.88928 10.8757 2.90719C11.9754 1.96018 13.2419 1.22173 14.6117 0.728771C16.0575 0.207431 17.5847 -0.0578199 19.1235 -0.0548339C20.945 -0.0862144 22.7501 0.29154 24.403 1.05005C25.9554 1.76813 27.3122 2.84272 28.3606 4.18446C29.5016 5.6865 30.34 7.39178 30.8302 9.20737C31.4288 11.3852 31.7192 13.6347 31.693 15.8915V34.9723H25.3607V15.8915C25.3856 14.3746 25.2019 12.8615 24.8146 11.3936C24.5047 10.2128 23.9374 9.1135 23.1524 8.17301C22.4076 7.30949 21.4586 6.64186 20.3899 6.22967C19.1496 5.77427 17.834 5.55365 16.5114 5.57928C15.5144 5.57044 14.5201 5.68362 13.5511 5.91623C12.6757 6.11959 11.8337 6.44406 11.0498 6.88007C10.3008 7.29488 9.62233 7.82374 9.03931 8.44728C8.44193 9.08531 7.94556 9.80921 7.56707 10.5943C7.15573 11.4528 6.85266 12.3582 6.66471 13.29C6.44506 14.3468 6.33894 15.4237 6.3481 16.5027L6.33227 35.0585Z"
          fill="#024F40"
        />
        <path
          d="M61.7067 0.932495H68.039V35.0585H61.7067V25.0283H61.7542C61.3833 26.6398 60.7837 28.191 59.9732 29.6359C59.2444 30.9371 58.2938 32.1035 57.1633 33.0838C56.0636 34.0308 54.7971 34.7693 53.4272 35.2622C51.9815 35.7836 50.4542 36.0488 48.9155 36.0458C47.0945 36.0799 45.2894 35.7048 43.636 34.9488C42.0809 34.2349 40.7232 33.1595 39.6783 31.8144C38.5375 30.3094 37.6991 28.6015 37.2087 26.7836C36.6057 24.6094 36.3153 22.3618 36.346 20.1073V1.02653H42.6782V20.1073C42.6533 21.6242 42.8371 23.1374 43.2244 24.6052C43.5342 25.786 44.1016 26.8853 44.8866 27.8258C45.6297 28.691 46.5793 29.359 47.6491 29.7691C48.8898 30.2219 50.2055 30.4398 51.5276 30.4117C52.5241 30.4191 53.5179 30.3086 54.4879 30.0826C55.3628 29.8779 56.2047 29.5535 56.9892 29.1188C57.7382 28.7039 58.4166 28.1751 58.9996 27.5515C59.5951 26.912 60.0912 26.1884 60.4719 25.4045C60.8832 24.546 61.1863 23.6406 61.3742 22.7089C61.59 21.6514 61.6961 20.5749 61.6909 19.4961L61.7067 0.932495Z"
          fill="#024F40"
        />
        <path
          d="M85.8167 14.6847V14.3556C87.1702 14.2146 87.5818 13.9011 87.9142 12.8354L89.2598 7.9849H83.3471L81.9777 13C81.9367 13.1481 81.9154 13.3009 81.9144 13.4545C81.9144 14.05 82.1439 14.1911 83.3946 14.3556V14.6847H78.2812V14.3556C79.4369 14.1519 79.5873 14.0265 79.9593 12.8354L82.508 3.75343C82.6443 3.34303 82.7216 2.91571 82.7376 2.484C82.7376 1.88846 82.4526 1.70039 81.1545 1.59852V1.26941H86.8219V1.59852C85.5792 1.67688 85.0409 2.06869 84.7243 3.11872L83.6083 7.12294H89.521L90.4788 3.75343C90.6016 3.3401 90.6759 2.91416 90.7004 2.484C90.7004 1.90413 90.455 1.70039 89.2519 1.59852V1.26941H94.381V1.59852C93.1937 1.80226 93.0275 1.92764 92.6713 3.11872L89.9326 13C89.8946 13.1618 89.876 13.3275 89.8772 13.4936C89.8772 14.0657 90.1463 14.2146 91.4603 14.3556V14.6847H85.8167Z"
          fill="#024F40"
        />
        <path
          d="M95.4031 14.912C94.7778 14.912 94.4453 14.4967 94.4453 13.7366C94.5124 12.9774 94.6664 12.2282 94.9044 11.5033L95.9017 7.85958C96.0033 7.56874 96.0646 7.26559 96.0838 6.95844C96.0838 6.55096 95.9017 6.48828 94.7778 6.46477V6.13565C95.8953 6.03269 97.0055 5.8626 98.1022 5.62631H98.1655L96.3371 12.2399C96.1766 12.6784 96.0651 13.133 96.0046 13.5956C96.0105 13.6795 96.0479 13.7581 96.1094 13.8161C96.1709 13.8741 96.2521 13.9073 96.3371 13.909C96.6933 13.909 97.1286 13.4702 98.0389 12.3418L98.308 12.569C97.1286 14.293 96.3767 14.912 95.4031 14.912ZM98.1418 3.65163C98.0013 3.65361 97.862 3.62597 97.7332 3.57052C97.6043 3.51508 97.4889 3.43311 97.3946 3.33007C97.3002 3.22703 97.2291 3.10527 97.186 2.97291C97.1429 2.84055 97.1287 2.70062 97.1444 2.56242C97.1325 2.42687 97.1486 2.29035 97.1917 2.16116C97.2348 2.03196 97.304 1.9128 97.395 1.81091C97.4861 1.70902 97.5972 1.62653 97.7215 1.56848C97.8458 1.51042 97.9807 1.47801 98.118 1.47321C98.4042 1.49327 98.6717 1.6217 98.8648 1.83182C99.0578 2.04194 99.1618 2.31762 99.1549 2.6016C99.1572 2.8727 99.0526 3.134 98.8635 3.33005C98.6743 3.52609 98.4154 3.64144 98.1418 3.65163Z"
          fill="#024F40"
        />
        <path
          d="M102.877 18.9162C100.78 18.9162 99.4105 17.9916 99.3867 16.5654C99.3867 15.6251 99.7429 15.2098 101.761 13.8777C101.366 13.5877 101.183 13.337 101.183 13.0941C101.183 12.6004 101.556 12.1929 102.767 11.3701C101.587 10.8373 101.128 10.2182 101.104 9.15253C101.104 7.22486 102.957 5.64198 105.26 5.64198C105.98 5.62875 106.688 5.82786 107.294 6.21401C107.386 6.30016 107.502 6.35741 107.627 6.37857H108.885V7.16217H107.848C107.966 7.53532 108.03 7.92315 108.038 8.31407C108.038 9.88128 106.123 11.5817 104.239 11.5817C104.024 11.5814 103.809 11.5604 103.598 11.519C103.548 11.5143 103.497 11.5143 103.447 11.519C103.178 11.519 102.703 12.0519 102.703 12.3575C102.703 12.6631 103.241 12.9922 104.152 13.2194C106.146 13.7366 107.144 14.6378 107.167 15.9307C107.215 17.7016 105.489 18.9162 102.877 18.9162ZM102.98 14.3321L102.379 14.1519L102.268 14.1049C101.77 14.3642 101.327 14.7153 100.962 15.1393C100.811 15.3059 100.695 15.5003 100.62 15.7115C100.546 15.9226 100.514 16.1462 100.527 16.3695C100.527 17.686 101.524 18.4617 103.186 18.4617C104.848 18.4617 105.964 17.6781 105.964 16.5341C105.988 15.5859 105.197 15.0139 102.98 14.3321ZM106.447 7.5383C106.447 6.56663 106.028 6.07296 105.26 6.07296C103.914 6.07296 102.751 7.71853 102.774 9.60701C102.774 10.5787 103.226 11.1742 104.017 11.1742C104.409 11.1447 104.785 11.0083 105.104 10.78C105.423 10.5518 105.671 10.2407 105.822 9.88128C106.207 9.15729 106.421 8.35611 106.447 7.5383Z"
          fill="#024F40"
        />
        <path
          d="M116.468 14.865C116.347 14.8852 116.223 14.8773 116.105 14.8419C115.988 14.8065 115.88 14.7445 115.791 14.6609C115.702 14.5773 115.634 14.4744 115.592 14.3602C115.55 14.246 115.535 14.1237 115.549 14.003C115.637 13.3632 115.777 12.7316 115.969 12.1145L117.109 7.85956C117.171 7.63815 117.218 7.41306 117.251 7.18566C117.239 7.04296 117.173 6.91012 117.066 6.81409C116.958 6.71805 116.818 6.666 116.673 6.66848C115.945 6.66848 114.536 7.94576 113.452 9.56782C112.621 10.8216 112.352 11.519 111.521 14.6848H109.938L112.74 4.16879C112.791 3.90341 112.859 3.64164 112.945 3.38518C113.105 2.93246 113.203 2.46082 113.238 1.98253C113.238 1.42617 112.447 1.48886 111.845 1.48886V1.18325C112.942 1.06213 114.031 0.876345 115.106 0.626892L115.209 0.728765L112.716 9.93612C114.647 6.93491 116.119 5.60278 117.465 5.60278C117.649 5.59361 117.832 5.62292 118.004 5.6888C118.175 5.75469 118.33 5.85566 118.46 5.98511C118.589 6.11455 118.689 6.26956 118.754 6.43999C118.818 6.61041 118.846 6.79241 118.834 6.97409C118.637 8.32551 118.303 9.65388 117.837 10.9391C117.543 11.7777 117.313 12.6368 117.148 13.5094C117.144 13.6016 117.176 13.6919 117.239 13.7607C117.301 13.8296 117.388 13.8716 117.481 13.8776C117.813 13.8776 118.067 13.6504 119.183 12.2556L119.452 12.5063C118.225 14.3165 117.52 14.865 116.468 14.865Z"
          fill="#024F40"
        />
        <path
          d="M129.882 15.053C129.14 15.0321 128.41 14.8725 127.729 14.5829C127.382 14.4412 127.017 14.3514 126.644 14.3164C126.233 14.3164 126.003 14.5202 125.853 14.9903H125.473L126.185 10.4141H126.597C126.566 10.5476 126.552 10.6846 126.557 10.8216C126.557 12.8354 127.839 14.2929 129.589 14.2929C129.948 14.2975 130.305 14.2295 130.636 14.0931C130.968 13.9566 131.269 13.7546 131.519 13.4994C131.769 13.2442 131.965 12.9411 132.093 12.6087C132.221 12.2762 132.279 11.9215 132.264 11.566C132.264 10.6805 131.995 10.1477 131.013 9.13681L130.222 8.27485C129.953 7.96924 129.684 7.67931 129.43 7.41288C128.188 6.09643 127.847 5.50089 127.847 4.39601C127.843 3.92449 127.937 3.45716 128.124 3.02357C128.311 2.58998 128.587 2.19956 128.935 1.87699C129.282 1.55441 129.693 1.3067 130.143 1.14952C130.592 0.992341 131.069 0.929105 131.544 0.963815C132.144 0.952657 132.742 1.0482 133.309 1.24591C133.608 1.38141 133.93 1.45857 134.259 1.47316C134.451 1.49317 134.645 1.45241 134.813 1.35671C134.981 1.26101 135.114 1.11527 135.193 0.940308H135.676L134.844 5.05423L134.472 4.99154C134.425 3.6124 134.259 2.91499 133.784 2.40565C133.557 2.14918 133.275 1.94584 132.959 1.81032C132.642 1.67481 132.3 1.61051 131.955 1.62205C131.661 1.58764 131.363 1.61701 131.081 1.70814C130.8 1.79926 130.541 1.94995 130.325 2.14976C130.108 2.34956 129.937 2.59369 129.825 2.8652C129.713 3.13671 129.662 3.42912 129.676 3.72211C129.676 4.72512 129.842 5.01505 131.734 6.85652C133.625 8.69799 134.108 9.52861 134.108 10.8686C134.129 11.4237 134.034 11.9771 133.83 12.4943C133.625 13.0114 133.314 13.4811 132.917 13.8739C132.52 14.2667 132.046 14.5742 131.524 14.7771C131.001 14.98 130.442 15.0739 129.882 15.053Z"
          fill="#024F40"
        />
        <path
          d="M139.176 14.912C137.253 14.912 136.129 13.7836 136.129 11.8246C136.129 8.61965 139.034 5.62628 142.145 5.62628C143.411 5.62628 144.329 6.32369 144.329 7.28752C144.332 7.41604 144.307 7.54367 144.257 7.66239C144.208 7.78111 144.134 7.88836 144.04 7.97739C143.946 8.06643 143.835 8.13534 143.713 8.17979C143.591 8.22424 143.461 8.24326 143.332 8.23568C143.226 8.24393 143.119 8.22932 143.019 8.19286C142.92 8.1564 142.829 8.09895 142.754 8.02447C142.678 7.95 142.62 7.86026 142.584 7.76144C142.547 7.66263 142.532 7.55708 142.54 7.45208C142.543 7.24046 142.606 7.03388 142.722 6.85654C142.799 6.73944 142.843 6.60417 142.849 6.46474C142.849 6.22182 142.556 6.05726 142.058 6.05726C141.633 6.063 141.213 6.15337 140.824 6.323C140.435 6.49263 140.085 6.73804 139.794 7.0446C138.589 8.35217 137.915 10.0547 137.902 11.8246C137.902 13.3056 138.614 14.1754 139.794 14.1754C140.815 14.1754 141.622 13.7287 142.762 12.5142L143.094 12.7179C141.836 14.2929 140.736 14.912 139.176 14.912Z"
          fill="#024F40"
        />
        <path
          d="M150.983 14.865C150.863 14.8839 150.74 14.8749 150.623 14.8389C150.507 14.8029 150.4 14.7407 150.312 14.6573C150.224 14.5738 150.157 14.4714 150.115 14.3579C150.074 14.2444 150.059 14.123 150.073 14.003C150.156 13.3631 150.294 12.7314 150.485 12.1145L151.624 7.85956C151.691 7.63896 151.742 7.4137 151.775 7.18566C151.761 7.04229 151.693 6.90939 151.584 6.81351C151.476 6.71764 151.334 6.66585 151.189 6.66848C150.461 6.66848 149.052 7.94576 147.975 9.56782C147.144 10.8216 146.875 11.519 146.044 14.6848H144.461L147.263 4.16879C147.314 3.90341 147.383 3.64164 147.469 3.38518C147.628 2.93246 147.727 2.46082 147.762 1.98253C147.762 1.42617 146.97 1.48886 146.376 1.48886V1.18325C147.471 1.06211 148.557 0.876331 149.63 0.626892L149.733 0.728765L147.247 9.93612C149.171 6.93491 150.651 5.60278 151.996 5.60278C152.18 5.59361 152.364 5.62292 152.535 5.6888C152.706 5.75469 152.862 5.85566 152.991 5.98511C153.12 6.11455 153.22 6.26956 153.285 6.43999C153.35 6.61041 153.377 6.79241 153.366 6.97409C153.172 8.32627 152.838 9.65502 152.368 10.9391C152.08 11.7786 151.852 12.6376 151.688 13.5094C151.684 13.5553 151.691 13.6014 151.706 13.6449C151.721 13.6885 151.744 13.7286 151.775 13.7629C151.806 13.7972 151.844 13.8251 151.886 13.8448C151.928 13.8645 151.974 13.8757 152.02 13.8776C152.353 13.8776 152.598 13.6504 153.722 12.2556L153.991 12.5063C152.748 14.3165 152.044 14.865 150.983 14.865Z"
          fill="#024F40"
        />
        <path
          d="M161.981 13.1254C161.484 13.6655 160.882 14.1018 160.212 14.4086C159.542 14.7155 158.817 14.8866 158.079 14.912C156.195 14.912 155.031 13.8228 155.031 12.0361C155.1 10.8368 155.48 9.67535 156.135 8.66377C156.789 7.65219 157.696 6.82464 158.767 6.26097C159.514 5.8462 160.356 5.6277 161.213 5.62626C161.604 5.60176 161.996 5.65886 162.363 5.79387C162.73 5.92887 163.064 6.13877 163.344 6.40993C163.624 6.6811 163.843 7.00747 163.987 7.36789C164.132 7.72831 164.198 8.11474 164.181 8.50208C164.08 10.2671 163.292 11.9246 161.981 13.1254ZM160.968 6.07291C160.05 6.07291 159.139 6.70763 158.308 7.93789C157.364 9.33284 156.832 10.9613 156.773 12.6395C156.742 12.8672 156.759 13.0988 156.823 13.3196C156.888 13.5403 156.998 13.7453 157.147 13.9216C157.296 14.0978 157.48 14.2413 157.688 14.343C157.897 14.4448 158.124 14.5024 158.356 14.5123C159.266 14.5123 160.073 13.956 160.865 12.8276C161.792 11.3838 162.321 9.72513 162.4 8.01625C162.44 6.81733 161.854 6.05724 160.968 6.05724V6.07291Z"
          fill="#024F40"
        />
        <path
          d="M172.356 13.1254C171.859 13.6655 171.257 14.1018 170.587 14.4086C169.917 14.7155 169.192 14.8866 168.454 14.912C166.562 14.912 165.398 13.8228 165.398 12.0361C165.469 10.8372 165.85 9.67635 166.504 8.66502C167.158 7.65368 168.064 6.82584 169.134 6.26098C169.884 5.84524 170.729 5.62671 171.588 5.62626C171.978 5.6029 172.369 5.66085 172.735 5.79637C173.101 5.93189 173.435 6.14196 173.714 6.413C173.993 6.68404 174.211 7.01002 174.355 7.36987C174.499 7.72972 174.565 8.11544 174.549 8.50209C174.45 10.2662 173.664 11.9238 172.356 13.1254ZM171.335 6.07291C170.425 6.07291 169.506 6.70763 168.683 7.93789C167.739 9.33284 167.207 10.9613 167.148 12.6395C167.148 13.7679 167.789 14.5123 168.731 14.5123C169.673 14.5123 170.456 13.956 171.24 12.8276C172.17 11.3853 172.7 9.72578 172.776 8.01625C172.807 6.81733 172.229 6.05724 171.335 6.05724V6.07291Z"
          fill="#024F40"
        />
        <path
          d="M177.084 14.912C176.419 14.912 176.07 14.5202 176.07 13.7601C176.068 13.5795 176.095 13.3996 176.15 13.2272L178.991 2.37432C179.04 2.25685 179.069 2.13256 179.078 2.00603C179.078 1.72393 178.659 1.53587 178.018 1.53587H177.661V1.20675C178.75 1.08296 179.831 0.897198 180.899 0.650391L181.01 0.752263L177.843 12.6474C177.741 12.9772 177.67 13.3156 177.63 13.6582C177.63 13.9011 177.741 14.0265 177.946 14.0265C178.318 14.0265 178.69 13.7209 179.379 12.859C179.545 12.6317 179.727 12.381 179.917 12.1537L180.186 12.3418C178.865 14.3165 178.16 14.912 177.084 14.912Z"
          fill="#024F40"
        />
        <path
          d="M85.2388 35.1447C84.5227 35.1702 83.809 35.0488 83.1426 34.7879C82.4762 34.5271 81.8716 34.1325 81.3668 33.6291C80.8619 33.1256 80.4678 32.5242 80.2092 31.8626C79.9507 31.201 79.8332 30.4936 79.8643 29.7848C79.8643 25.2008 83.8219 21.0947 88.2862 21.0947C89.1072 21.1132 89.923 21.2289 90.7162 21.4395C90.9924 21.5183 91.2763 21.5682 91.5631 21.5883C91.7263 21.603 91.8902 21.5672 92.0319 21.4858C92.1737 21.4044 92.2865 21.2814 92.3547 21.1338H92.79L91.9985 25.2243L91.6264 25.1616C91.4206 22.9414 90.3653 21.8313 88.4603 21.8313C87.7727 21.8316 87.0923 21.9708 86.4608 22.2402C85.8294 22.5097 85.26 22.9038 84.7876 23.3985C83.1074 25.1935 82.172 27.5492 82.1676 29.9964C82.1676 32.6607 83.4974 34.2044 85.8008 34.2044C87.5026 34.2044 88.7057 33.6088 90.55 31.783L90.9061 32.0651C89.0381 34.2592 87.3759 35.1447 85.2388 35.1447Z"
          fill="#024F40"
        />
        <path
          d="M99.8051 33.217C99.3077 33.7572 98.7063 34.1935 98.0362 34.5003C97.366 34.8071 96.6407 34.9782 95.9029 35.0036C94.0111 35.0036 92.8555 33.9144 92.8555 32.1278C92.9229 30.9281 93.3023 29.766 93.9569 28.7542C94.6115 27.7423 95.519 26.9151 96.5915 26.3526C97.3384 25.9372 98.1806 25.7187 99.0373 25.7179C99.4273 25.6903 99.8188 25.7441 100.187 25.8757C100.554 26.0072 100.89 26.2138 101.172 26.4819C101.454 26.75 101.676 27.0737 101.824 27.4321C101.971 27.7904 102.041 28.1755 102.029 28.5624C101.93 30.3419 101.132 32.0125 99.8051 33.217ZM98.784 26.1646C97.8738 26.1646 96.9635 26.7993 96.1324 28.0295C95.1881 29.4245 94.6562 31.0529 94.5968 32.7312C94.5968 33.8596 95.238 34.604 96.1799 34.604C97.1218 34.604 97.9054 34.0476 98.6891 32.9192C99.6357 31.4815 100.182 29.8217 100.272 28.1079C100.264 26.909 99.6547 26.1489 98.784 26.1489V26.1646Z"
          fill="#024F40"
        />
        <path
          d="M104.529 35.0036C103.864 35.0036 103.516 34.6118 103.516 33.8517C103.514 33.6711 103.54 33.4912 103.595 33.3188L106.437 22.4659C106.485 22.3485 106.514 22.2242 106.524 22.0976C106.524 21.8077 106.104 21.6275 105.463 21.6275H105.115V21.2984C106.206 21.1684 107.29 20.9774 108.36 20.7263L108.463 20.8282L105.297 32.7233C105.196 33.0532 105.128 33.3916 105.091 33.7342C105.091 33.9771 105.194 34.1024 105.4 34.1024C105.772 34.1024 106.144 33.7968 106.832 32.927C106.998 32.7076 107.181 32.4569 107.371 32.2296L107.64 32.4177C106.318 34.4081 105.613 35.0036 104.529 35.0036Z"
          fill="#024F40"
        />
        <path
          d="M110.298 35.0036C109.634 35.0036 109.277 34.6118 109.277 33.8517C109.278 33.6707 109.308 33.4909 109.364 33.3189L112.206 22.4659C112.254 22.3485 112.284 22.2242 112.293 22.0976C112.293 21.8077 111.874 21.6275 111.232 21.6275H110.876V21.2984C111.965 21.1746 113.046 20.9888 114.114 20.742L114.217 20.8439L111.05 32.739C110.945 33.0679 110.873 33.4066 110.837 33.7498C110.837 33.9928 110.94 34.1181 111.153 34.1181C111.525 34.1181 111.897 33.8125 112.586 32.9427C112.752 32.7233 112.934 32.4726 113.124 32.2453L113.393 32.4334C112.079 34.4081 111.375 35.0036 110.298 35.0036Z"
          fill="#024F40"
        />
        <path
          d="M117.581 35.0036C117.209 35.0143 116.839 34.9478 116.494 34.8084C116.149 34.6689 115.838 34.4596 115.58 34.1939C115.322 33.9282 115.123 33.612 114.996 33.2656C114.869 32.9192 114.817 32.5503 114.842 32.1826C114.842 29.0482 118.103 25.7179 121.087 25.7179C122.124 25.7179 122.75 26.2507 122.75 27.1127C122.75 28.8758 120.549 30.443 117.438 30.874L116.853 30.9524C116.729 31.3596 116.66 31.7811 116.647 32.2061C116.639 32.4563 116.683 32.7054 116.778 32.9373C116.873 33.1692 117.017 33.3787 117.199 33.5523C117.381 33.7259 117.598 33.8597 117.835 33.945C118.073 34.0304 118.326 34.0653 118.578 34.0476C119.489 34.0476 120.161 33.7185 121.626 32.4804L121.879 32.7233C121.365 33.3819 120.717 33.9255 119.976 34.3185C119.236 34.7115 118.419 34.945 117.581 35.0036ZM120.652 26.2116C119.591 26.2116 118.056 27.7788 117.351 29.6202L117.003 30.5214C118.784 30.0904 119.576 29.7378 120.343 29.0012C120.94 28.4918 121.312 27.772 121.38 26.9952C121.39 26.8951 121.379 26.7941 121.347 26.6987C121.315 26.6033 121.262 26.5158 121.194 26.4418C121.125 26.3678 121.041 26.309 120.948 26.2693C120.854 26.2296 120.754 26.21 120.652 26.2116Z"
          fill="#024F40"
        />
        <path
          d="M127.08 39.0079C124.991 39.0079 123.613 38.0832 123.598 36.657C123.598 35.7089 123.946 35.3014 125.972 33.9693C125.576 33.6793 125.394 33.4286 125.394 33.1857C125.394 32.692 125.766 32.2845 126.977 31.4617C125.79 30.9289 125.339 30.3098 125.315 29.2441C125.315 27.3086 127.16 25.7336 129.463 25.7336C130.184 25.7184 130.892 25.9177 131.497 26.3056C131.589 26.3918 131.705 26.449 131.83 26.4702H133.096V27.2538H132.107C132.219 27.6281 132.28 28.0154 132.289 28.4057C132.289 29.9729 130.381 31.6733 128.497 31.6733C128.279 31.673 128.062 31.652 127.848 31.6106C127.801 31.6035 127.753 31.6035 127.706 31.6106C127.437 31.6106 126.962 32.1513 126.962 32.4569C126.962 32.7625 127.5 33.0916 128.41 33.3189C130.405 33.8361 131.402 34.7372 131.418 36.0302C131.418 37.7933 129.732 39.0079 127.08 39.0079ZM127.183 34.4238L126.582 34.2435L126.479 34.1965C125.98 34.4538 125.536 34.8052 125.173 35.2309C125.022 35.3975 124.906 35.592 124.831 35.8031C124.757 36.0142 124.725 36.2378 124.737 36.4611C124.737 37.7776 125.735 38.5534 127.397 38.5534C129.059 38.5534 130.175 37.7698 130.175 36.6257C130.152 35.6775 129.384 35.1055 127.183 34.4238ZM130.65 27.6221C130.65 26.6582 130.239 26.1646 129.471 26.1646C128.117 26.1646 126.962 27.8101 126.977 29.6986C126.977 30.6625 127.437 31.2658 128.228 31.2658C128.62 31.2363 128.996 31.0999 129.315 30.8717C129.633 30.6434 129.882 30.3324 130.033 29.9729C130.417 29.246 130.628 28.442 130.65 27.6221Z"
          fill="#024F40"
        />
        <path
          d="M136.229 35.0036C135.857 35.0143 135.487 34.9478 135.142 34.8084C134.798 34.6689 134.487 34.4596 134.229 34.1939C133.971 33.9282 133.772 33.612 133.645 33.2656C133.518 32.9192 133.465 32.5503 133.491 32.1826C133.491 29.0482 136.752 25.7179 139.736 25.7179C140.773 25.7179 141.398 26.2507 141.398 27.1127C141.398 28.8758 139.198 30.443 136.087 30.874L135.501 30.9524C135.381 31.3605 135.312 31.7815 135.295 32.2061C135.287 32.4563 135.332 32.7054 135.427 32.9373C135.522 33.1692 135.665 33.3787 135.847 33.5523C136.029 33.7259 136.246 33.8597 136.484 33.945C136.721 34.0304 136.975 34.0653 137.227 34.0476C138.137 34.0476 138.81 33.7185 140.274 32.4804L140.527 32.7233C140.014 33.3819 139.365 33.9255 138.625 34.3185C137.884 34.7115 137.068 34.945 136.229 35.0036ZM139.301 26.2116C138.248 26.2116 136.712 27.7788 136 29.6202L135.652 30.5214C137.433 30.0904 138.224 29.7378 138.992 29.0012C139.585 28.4904 139.954 27.7709 140.021 26.9952C140.031 26.8958 140.02 26.7954 139.988 26.7006C139.956 26.6058 139.905 26.5186 139.837 26.4447C139.769 26.3708 139.686 26.3119 139.594 26.2717C139.501 26.2316 139.401 26.2111 139.301 26.2116Z"
          fill="#024F40"
        />
      </g>
      <defs>
        <clipPath id="clip0_304_8812">
          <rect width="181" height="39" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NUCollegeLogo;
