import React from "react";

const BrainsterNextLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="190"
      height="41"
      viewBox="0 0 190 41"
      fill="none"
    >
      <path
        d="M11.2731 32.6381C11.1164 32.5434 11.1164 32.3854 11.2418 32.2906C12.1819 31.6271 12.7773 30.6476 12.7773 29.2258C12.7773 26.7613 11.0851 24.9288 7.76338 24.9288H0.58724C0.336545 24.9288 0.117188 25.1499 0.117188 25.4027V40C0.117188 40.2527 0.336545 40.4739 0.58724 40.4739H8.45279C11.5865 40.4739 13.4667 38.6729 13.4667 36.0821C13.4667 34.4075 12.6206 33.2701 11.2731 32.6381ZM4.06563 28.9414C4.06563 28.7203 4.25365 28.5307 4.47301 28.5307H7.54402C8.35878 28.5307 8.79749 29.0362 8.79749 29.7629C8.79749 30.4896 8.39011 30.9952 7.32466 30.9952H4.47301C4.25365 30.9952 4.06563 30.8056 4.06563 30.5844V28.9414ZM8.01407 36.872H4.47301C4.25365 36.872 4.06563 36.6824 4.06563 36.4612V34.5339C4.06563 34.3127 4.25365 34.1232 4.47301 34.1232H8.04541C9.11086 34.1232 9.51824 34.6603 9.51824 35.4502C9.4869 36.2717 9.07952 36.872 8.01407 36.872Z"
        fill="#F4F1EA"
      />
      <path
        d="M26.6619 36.1137C26.5366 35.9241 26.5679 35.7029 26.7872 35.6082C28.6361 34.5971 29.4822 32.6697 29.4822 30.616C29.4822 27.5196 27.6647 24.9288 23.7162 24.9288H16.6341C16.3834 24.9288 16.1641 25.1499 16.1641 25.4027V40C16.1641 40.2527 16.3834 40.4739 16.6341 40.4739H19.6111C19.8618 40.4739 20.0812 40.2527 20.0812 40V36.714C20.0812 36.4928 20.2692 36.3033 20.4885 36.3033H21.7107C21.9614 36.3033 22.2121 36.4296 22.3374 36.6192L24.7504 40.0632C24.9384 40.3159 25.2204 40.4739 25.5024 40.4739H29.2629C29.4509 40.4739 29.5762 40.2527 29.4509 40.0632L26.6619 36.1137ZM23.4969 32.7013H20.5199C20.3005 32.7013 20.1125 32.5118 20.1125 32.2906V28.9414C20.1125 28.7203 20.3005 28.5307 20.5199 28.5307H23.4969C25.0637 28.5307 25.6591 29.4154 25.6591 30.616C25.6591 31.7219 25.0637 32.7013 23.4969 32.7013Z"
        fill="#F4F1EA"
      />
      <path
        d="M41.1654 25.3711C41.0714 25.1183 40.7894 24.9288 40.5074 24.9288H37.5304C37.2483 24.9288 36.9663 25.1183 36.8723 25.3711L30.981 40.0316C30.8869 40.2211 31.0436 40.4739 31.263 40.4739H34.616C34.8981 40.4739 35.1488 40.2843 35.2428 40.0316L35.8068 38.5782C35.9008 38.357 36.0889 38.199 36.3396 38.199H41.6355C41.8862 38.199 42.1055 38.357 42.1682 38.5782L42.7323 40.0316C42.8263 40.2843 43.077 40.4739 43.359 40.4739H46.7121C46.9628 40.4739 47.0881 40.2211 46.9941 40.0316L41.1654 25.3711ZM40.288 34.5971H37.687C37.499 34.5971 37.3737 34.4075 37.4363 34.2179L38.8465 30.4264C38.8778 30.3001 39.0972 30.3001 39.1285 30.4264L40.5387 34.2179C40.6327 34.3759 40.5074 34.5971 40.288 34.5971Z"
        fill="#F4F1EA"
      />
      <path
        d="M52.8573 24.9288H49.849C49.5983 24.9288 49.3789 25.1499 49.3789 25.4027V40C49.3789 40.2527 49.5983 40.4739 49.849 40.4739H52.8573C53.108 40.4739 53.3274 40.2527 53.3274 40V25.4027C53.3274 25.1499 53.108 24.9288 52.8573 24.9288Z"
        fill="#F4F1EA"
      />
      <path
        d="M70.3777 24.9288H67.4007C67.15 24.9288 66.9306 25.1499 66.9306 25.4027V33.2069C66.9306 33.3333 66.774 33.3964 66.68 33.3017L60.3499 25.3079C60.1619 25.0552 59.8799 24.9288 59.5665 24.9288H57.1849C56.9342 24.9288 56.7148 25.1499 56.7148 25.4027V40C56.7148 40.2527 56.9342 40.4739 57.1849 40.4739H60.1619C60.4126 40.4739 60.6319 40.2527 60.6319 40V32.1958C60.6319 32.0694 60.7886 32.0062 60.8826 32.101L67.244 40.0632C67.432 40.3159 67.7141 40.4423 68.0274 40.4423H70.409C70.6597 40.4423 70.8791 40.2211 70.8791 39.9684V25.4027C70.8478 25.1499 70.6284 24.9288 70.3777 24.9288Z"
        fill="#F4F1EA"
      />
      <path
        d="M80.4982 30.7109C78.6493 30.079 77.9599 29.763 77.9599 29.1311C77.9599 28.6256 78.4926 28.0885 79.8401 28.0885C81.1562 28.0885 81.9083 28.6888 82.1277 29.3523C82.253 29.6999 82.4097 29.8894 82.7857 29.8894H85.5434C85.8254 29.8894 86.0761 29.6051 86.0448 29.3207C85.8568 26.5403 83.3498 24.6445 79.7461 24.6445C76.2363 24.6445 73.7294 26.5087 73.7294 29.4787C73.7294 32.7015 76.9571 33.8705 79.4014 34.692C81.2502 35.3239 82.0337 35.7347 82.0337 36.3982C82.0337 36.8721 81.5636 37.346 80.0908 37.346C78.712 37.346 77.8972 36.8721 77.6152 36.335C77.3645 35.9242 77.2705 35.8294 76.8317 35.8294H74.1681C73.8861 35.8294 73.6354 36.1138 73.6667 36.3982C73.9488 39.2418 76.7064 40.79 80.1848 40.79C83.6945 40.79 86.3268 39.2102 86.3268 36.177C86.2641 32.7331 82.6291 31.4692 80.4982 30.7109Z"
        fill="#F4F1EA"
      />
      <path
        d="M101.146 24.9288H88.5482C88.2975 24.9288 88.0781 25.1499 88.0781 25.4027V28.3095C88.0781 28.5623 88.2975 28.7835 88.5482 28.7835H92.4966C92.716 28.7835 92.904 28.973 92.904 29.1942V40C92.904 40.2527 93.1234 40.4739 93.374 40.4739H96.3824C96.6331 40.4739 96.8524 40.2527 96.8524 40V29.1942C96.8524 28.973 97.0405 28.7835 97.2598 28.7835H101.208C101.459 28.7835 101.678 28.5623 101.678 28.3095V25.4027C101.647 25.1499 101.428 24.9288 101.146 24.9288Z"
        fill="#F4F1EA"
      />
      <path
        d="M115.407 24.9288H104.501C104.251 24.9288 104.031 25.1499 104.031 25.4027V40C104.031 40.2527 104.251 40.4739 104.501 40.4739H115.407C115.657 40.4739 115.877 40.2527 115.877 40V37.1248C115.877 36.872 115.657 36.6508 115.407 36.6508H108.387C108.168 36.6508 107.98 36.4612 107.98 36.2401V35.0394C107.98 34.8183 108.168 34.6287 108.387 34.6287H113.996C114.247 34.6287 114.466 34.4075 114.466 34.1547V31.5007C114.466 31.2479 114.247 31.0268 113.996 31.0268H108.387C108.168 31.0268 107.98 30.8372 107.98 30.616V29.1942C107.98 28.973 108.168 28.7835 108.387 28.7835H115.407C115.657 28.7835 115.877 28.5623 115.877 28.3095V25.4027C115.877 25.1499 115.657 24.9288 115.407 24.9288Z"
        fill="#F4F1EA"
      />
      <path
        d="M132.111 40.0633L129.291 36.0822C129.165 35.8927 129.197 35.6715 129.416 35.5767C131.265 34.5656 132.111 32.6383 132.111 30.5846C132.111 27.4882 130.294 24.8973 126.345 24.8973H119.294C119.044 24.8973 118.824 25.1185 118.824 25.3713V39.9685C118.824 40.2213 119.044 40.4425 119.294 40.4425H122.271C122.522 40.4425 122.741 40.2213 122.741 39.9685V36.6826C122.741 36.4614 122.929 36.2718 123.149 36.2718H124.371C124.622 36.2718 124.872 36.3982 124.998 36.5878L127.411 40.0317C127.599 40.2845 127.881 40.4425 128.163 40.4425H131.923C132.111 40.4741 132.236 40.2529 132.111 40.0633ZM126.126 32.7015H123.117C122.898 32.7015 122.71 32.5119 122.71 32.2907V28.9416C122.71 28.7204 122.898 28.5309 123.117 28.5309H126.094C127.661 28.5309 128.257 29.4155 128.257 30.6162C128.288 31.722 127.693 32.7015 126.126 32.7015Z"
        fill="#F4F1EA"
      />
      <path
        d="M148.128 24.9919V40.5371H144.901L137.474 25.7502V40.5371H135.625V24.9919H138.853L146.28 39.7788V24.9919H148.128Z"
        fill="#F4F1EA"
      />
      <path
        d="M153.146 26.5716V31.6269H160.667V33.2067H153.146V38.9572H161.544V40.5369H151.203V24.9918H161.544V26.5716H153.146Z"
        fill="#F4F1EA"
      />
      <path
        d="M171.761 32.5117L176.462 40.5055H174.299L170.069 33.0173L165.588 40.5055H163.551L168.377 32.5117L163.77 24.9603H165.932L170.1 32.0062L174.205 24.9603H176.305L171.761 32.5117Z"
        fill="#F4F1EA"
      />
      <path
        d="M189.997 26.6664H185.171V40.5053H183.228V26.6664H178.434V24.9918H189.997V26.6664Z"
        fill="#F4F1EA"
      />
      <path
        d="M101.726 10.9005C100.661 14.7552 98.6552 16.4298 94.9261 16.4298C89.9436 16.4298 87.75 13.9653 87.75 8.2149C87.75 2.46447 89.9436 0 94.9261 0C99.0313 0 101.005 1.67458 101.726 5.52926H99.7833C99.1253 2.74883 97.7778 1.67458 94.9261 1.67458C91.3537 1.67458 89.7869 3.57032 89.7869 8.18331C89.7869 12.7963 91.3224 14.692 94.9261 14.692C97.5271 14.692 98.8746 13.6178 99.7833 10.8374H101.726V10.9005Z"
        fill="#F4F1EA"
      />
      <path
        d="M118.994 8.2149C118.994 13.9653 116.8 16.4298 111.692 16.4298C106.553 16.4298 104.359 13.9653 104.359 8.2149C104.359 2.46447 106.553 0 111.692 0C116.8 0 118.994 2.46447 118.994 8.2149ZM106.365 8.2149C106.365 12.8279 107.932 14.7236 111.661 14.7236C115.39 14.7236 116.957 12.8279 116.957 8.2149C116.957 3.60192 115.39 1.70617 111.661 1.70617C107.932 1.70617 106.365 3.60192 106.365 8.2149Z"
        fill="#F4F1EA"
      />
      <path
        d="M131.968 15.9876H122.504V0.442444H124.447V14.2814H131.968V15.9876Z"
        fill="#F4F1EA"
      />
      <path
        d="M144.757 15.9876H135.293V0.442444H137.236V14.2814H144.757V15.9876Z"
        fill="#F4F1EA"
      />
      <path
        d="M149.982 2.02223V7.07756H157.503V8.65735H149.982V14.4078H158.38V15.9876H148.039V0.442444H158.38V2.02223H149.982Z"
        fill="#F4F1EA"
      />
      <path
        d="M168.126 7.39341H174.989V8.2149C174.989 13.9653 172.952 16.4298 168.126 16.4298C162.987 16.4298 160.793 13.9653 160.793 8.2149C160.793 2.46447 162.987 0 168.126 0C172.043 0 174.048 1.61138 174.769 5.33969H172.92C172.325 2.78043 170.915 1.70617 168.126 1.70617C164.365 1.70617 162.83 3.60192 162.83 8.2149C162.83 12.8279 164.397 14.7236 168.126 14.7236C171.667 14.7236 173.14 13.0175 173.14 8.87842H168.126V7.39341Z"
        fill="#F4F1EA"
      />
      <path
        d="M180.439 2.02223V7.07756H187.96V8.65735H180.439V14.4078H188.837V15.9876H178.496V0.442444H188.837V2.02223H180.439Z"
        fill="#F4F1EA"
      />
      <path
        d="M1.63719 1.05267H2.37423L4.00539 5.37103H3.14752L2.7488 4.29448H1.25055L0.857866 5.37103H0L1.63719 1.05267ZM2.59776 3.70451L2.00571 2.00758L1.3895 3.70451H2.59776Z"
        fill="#F4F1EA"
      />
      <path
        d="M5.50288 2.82867H5.84119L7.14611 1.05267H8.08856L6.54802 3.13886L8.22751 5.37103H7.29715L5.91973 3.58286H5.50288V5.37103H4.67522V1.05267H5.50288V2.82867Z"
        fill="#F4F1EA"
      />
      <path
        d="M8.80337 5.37103V1.05267H10.6218C10.8192 1.05267 11.0004 1.09525 11.1655 1.1804C11.3347 1.2615 11.4797 1.37097 11.6005 1.50884C11.7213 1.64265 11.816 1.79673 11.8844 1.97108C11.9529 2.14139 11.9871 2.31371 11.9871 2.48807C11.9871 2.67054 11.9549 2.84895 11.8905 3.0233C11.826 3.1936 11.7354 3.34566 11.6186 3.47947C11.5018 3.61328 11.3609 3.72073 11.1957 3.80182C11.0306 3.88292 10.8494 3.92347 10.652 3.92347H9.63707V5.37103H8.80337ZM9.63707 3.18752H10.6037C10.7567 3.18752 10.8836 3.12467 10.9843 2.99897C11.089 2.87328 11.1414 2.70297 11.1414 2.48807C11.1414 2.37859 11.1252 2.28128 11.093 2.19613C11.0608 2.10692 11.0185 2.03191 10.9662 1.97108C10.9138 1.91026 10.8514 1.86566 10.7789 1.83728C10.7104 1.80484 10.6399 1.78862 10.5674 1.78862H9.63707V3.18752Z"
        fill="#F4F1EA"
      />
      <path
        d="M15.7447 4.63508V5.37103H12.7663V1.05267H15.6903V1.78862H13.6V2.82867H15.4064V3.50988H13.6V4.63508H15.7447Z"
        fill="#F4F1EA"
      />
      <path
        d="M16.2311 4.63508H16.3821C16.4989 4.63103 16.6016 4.59656 16.6902 4.53169C16.7789 4.46681 16.8574 4.34719 16.9259 4.17284C16.9943 3.99848 17.0547 3.75925 17.1071 3.45514C17.1595 3.14697 17.2078 2.75163 17.2521 2.26911L17.3608 1.05267H19.9586V4.63508H20.5929V6.44758H19.8136V5.37103H17.0165V6.44758H16.2311V4.63508ZM19.1249 4.63508V1.78862H18.116L18.0556 2.4455C18.0073 3.00911 17.9388 3.4673 17.8502 3.82007C17.7656 4.17284 17.6548 4.44451 17.5179 4.63508H19.1249Z"
        fill="#F4F1EA"
      />
      <path
        d="M22.1748 4.0816L24.3617 1.05267H24.9961V5.37103H24.1684V2.409L22.0117 5.37103H21.3471V1.05267H22.1748V4.0816Z"
        fill="#F4F1EA"
      />
      <path
        d="M29.2963 1.78862H27.925V5.37103H27.0973V1.78862H25.7259V1.05267H29.2963V1.78862Z"
        fill="#F4F1EA"
      />
      <path
        d="M30.8558 4.0816L33.0428 1.05267H33.6771V5.37103H32.8495V2.409L30.6927 5.37103H30.0282V1.05267H30.8558V4.0816Z"
        fill="#F4F1EA"
      />
      <path
        d="M34.7876 5.37103V1.05267H36.606C36.8034 1.05267 36.9846 1.09525 37.1498 1.1804C37.3189 1.2615 37.4639 1.37097 37.5847 1.50884C37.7056 1.64265 37.8002 1.79673 37.8687 1.97108C37.9371 2.14139 37.9714 2.31371 37.9714 2.48807C37.9714 2.67054 37.9392 2.84895 37.8747 3.0233C37.8103 3.1936 37.7197 3.34566 37.6029 3.47947C37.4861 3.61328 37.3451 3.72073 37.18 3.80182C37.0148 3.88292 36.8336 3.92347 36.6362 3.92347H35.6213V5.37103H34.7876ZM35.6213 3.18752H36.5879C36.741 3.18752 36.8678 3.12467 36.9685 2.99897C37.0732 2.87328 37.1256 2.70297 37.1256 2.48807C37.1256 2.37859 37.1095 2.28128 37.0773 2.19613C37.045 2.10692 37.0028 2.03191 36.9504 1.97108C36.898 1.91026 36.8356 1.86566 36.7631 1.83728C36.6946 1.80484 36.6242 1.78862 36.5517 1.78862H35.6213V3.18752Z"
        fill="#F4F1EA"
      />
      <path
        d="M39.6814 1.05267H40.4184L42.0496 5.37103H41.1917L40.793 4.29448H39.2948L38.9021 5.37103H38.0442L39.6814 1.05267ZM40.642 3.70451L40.0499 2.00758L39.4337 3.70451H40.642Z"
        fill="#F4F1EA"
      />
      <path
        d="M46.3261 1.05267V5.37103H45.4984V3.52813H43.5531V5.37103H42.7194V1.05267H43.5531V2.79826H45.4984V1.05267H46.3261Z"
        fill="#F4F1EA"
      />
      <path
        d="M48.6398 1.05267H49.3768L51.0079 5.37103H50.1501L49.7514 4.29448H48.2531L47.8604 5.37103H47.0026L48.6398 1.05267ZM49.6003 3.70451L49.0083 2.00758L48.3921 3.70451H49.6003Z"
        fill="#F4F1EA"
      />
      <path
        d="M3.82415 10.8931C3.82415 11.0756 3.7879 11.2357 3.71541 11.3736C3.64291 11.5115 3.54222 11.627 3.41334 11.7203C3.28849 11.8135 3.14148 11.8845 2.97232 11.9332C2.80719 11.9818 2.62998 12.0061 2.44069 12.0061H0.36852V7.68779H2.66422C2.81324 7.68779 2.94816 7.72023 3.06898 7.78511C3.18981 7.84998 3.29251 7.93513 3.37709 8.04056C3.46167 8.14193 3.52611 8.25952 3.57041 8.39332C3.61874 8.52308 3.64291 8.65486 3.64291 8.78867C3.64291 8.99952 3.59055 9.1982 3.48584 9.38472C3.38112 9.56719 3.22807 9.70302 3.0267 9.79223C3.27238 9.86522 3.4657 9.99699 3.60666 10.1876C3.75165 10.3741 3.82415 10.6093 3.82415 10.8931ZM2.98441 10.735C2.98441 10.6579 2.97031 10.5849 2.94212 10.516C2.91795 10.443 2.88372 10.3802 2.83942 10.3275C2.79511 10.2747 2.74275 10.2342 2.68234 10.2058C2.62193 10.1734 2.55547 10.1572 2.48298 10.1572H1.20222V11.2945H2.44069C2.51721 11.2945 2.58769 11.2803 2.65213 11.252C2.7206 11.2236 2.779 11.1851 2.82733 11.1364C2.87566 11.0837 2.91392 11.0229 2.94212 10.9539C2.97031 10.885 2.98441 10.812 2.98441 10.735ZM1.20222 8.40549V9.4942H2.31382C2.45479 9.4942 2.5736 9.44554 2.67026 9.34823C2.77095 9.25091 2.82129 9.1171 2.82129 8.9468C2.82129 8.78056 2.77497 8.64878 2.68234 8.55146C2.59374 8.45415 2.48298 8.40549 2.35007 8.40549H1.20222Z"
        fill="#F4F1EA"
      />
      <path
        d="M5.56187 10.7167L7.74883 7.68779H8.38317V12.0061H7.55551V9.04412L5.39876 12.0061H4.73421V7.68779H5.56187V10.7167Z"
        fill="#F4F1EA"
      />
      <path
        d="M9.21573 9.81656C9.21573 9.553 9.26205 9.29349 9.35468 9.03804C9.45134 8.77853 9.59029 8.54741 9.77153 8.34467C9.95277 8.14193 10.1743 7.97771 10.4361 7.85201C10.6979 7.72631 10.9959 7.66346 11.3302 7.66346C11.7249 7.66346 12.0652 7.75064 12.3512 7.925C12.6412 8.09935 12.8566 8.32642 12.9976 8.6062L12.3572 9.0502C12.3008 8.92856 12.2324 8.82719 12.1518 8.74609C12.0713 8.66094 11.9847 8.59404 11.892 8.54538C11.7994 8.49672 11.7027 8.46226 11.6021 8.44198C11.5014 8.41765 11.4027 8.40549 11.306 8.40549C11.0966 8.40549 10.9133 8.44806 10.7563 8.53322C10.6032 8.61837 10.4743 8.72987 10.3696 8.86774C10.2689 9.00154 10.1924 9.15563 10.1401 9.32998C10.0877 9.50028 10.0615 9.67058 10.0615 9.84089C10.0615 10.0315 10.0917 10.216 10.1521 10.3944C10.2125 10.5687 10.2971 10.7228 10.4059 10.8566C10.5186 10.9904 10.6516 11.0979 10.8046 11.179C10.9617 11.2601 11.1328 11.3006 11.3181 11.3006C11.4148 11.3006 11.5155 11.2884 11.6202 11.2641C11.7249 11.2398 11.8236 11.2013 11.9162 11.1486C12.0129 11.0958 12.1015 11.0289 12.182 10.9478C12.2626 10.8627 12.327 10.7613 12.3753 10.6437L13.058 11.0391C12.9895 11.2013 12.8909 11.3452 12.762 11.4709C12.6371 11.5926 12.4942 11.696 12.333 11.7811C12.176 11.8663 12.0068 11.9311 11.8256 11.9757C11.6443 12.0203 11.4671 12.0426 11.2939 12.0426C10.9879 12.0426 10.7079 11.9778 10.4542 11.848C10.2005 11.7183 9.98097 11.55 9.7957 11.3432C9.61446 11.1323 9.47148 10.8951 9.36677 10.6316C9.26608 10.364 9.21573 10.0923 9.21573 9.81656Z"
        fill="#F4F1EA"
      />
      <path
        d="M15.5877 12.0366C15.2736 12.0366 14.9876 11.9757 14.7298 11.8541C14.4761 11.7284 14.2566 11.5642 14.0713 11.3614C13.8901 11.1546 13.7491 10.9195 13.6484 10.6559C13.5478 10.3923 13.4974 10.1227 13.4974 9.84697C13.4974 9.55908 13.5498 9.28335 13.6545 9.01979C13.7632 8.75623 13.9102 8.52308 14.0955 8.32034C14.2848 8.1176 14.5083 7.95743 14.7661 7.83985C15.0239 7.7182 15.3038 7.65738 15.6058 7.65738C15.916 7.65738 16.1979 7.72226 16.4516 7.85201C16.7094 7.97771 16.9289 8.14395 17.1101 8.35075C17.2914 8.55754 17.4323 8.79272 17.533 9.05628C17.6337 9.31984 17.684 9.58746 17.684 9.85913C17.684 10.147 17.6317 10.4227 17.527 10.6863C17.4223 10.9458 17.2773 11.1769 17.092 11.3797C16.9067 11.5784 16.6852 11.7385 16.4275 11.8602C16.1697 11.9778 15.8898 12.0366 15.5877 12.0366ZM14.3432 9.84697C14.3432 10.0335 14.3714 10.2139 14.4278 10.3883C14.4842 10.5626 14.5647 10.7167 14.6694 10.8505C14.7782 10.9843 14.9091 11.0918 15.0621 11.1729C15.2192 11.254 15.3964 11.2945 15.5938 11.2945C15.7951 11.2945 15.9723 11.254 16.1254 11.1729C16.2825 11.0877 16.4134 10.9762 16.5181 10.8384C16.6268 10.7005 16.7074 10.5464 16.7597 10.3761C16.8161 10.2018 16.8443 10.0254 16.8443 9.84697C16.8443 9.66045 16.8141 9.48001 16.7537 9.30565C16.6973 9.1313 16.6147 8.97721 16.506 8.84341C16.4013 8.7096 16.2704 8.60417 16.1133 8.52713C15.9603 8.44604 15.7871 8.40549 15.5938 8.40549C15.3924 8.40549 15.2131 8.44806 15.0561 8.53322C14.899 8.61431 14.7681 8.72379 14.6634 8.86165C14.5587 8.99546 14.4781 9.14954 14.4217 9.3239C14.3694 9.4942 14.3432 9.66856 14.3432 9.84697Z"
        fill="#F4F1EA"
      />
      <path
        d="M19.3446 9.46379H19.6829L20.9878 7.68779H21.9302L20.3897 9.77398L22.0692 12.0061H21.1388L19.7614 10.218H19.3446V12.0061H18.5169V7.68779H19.3446V9.46379Z"
        fill="#F4F1EA"
      />
      <path
        d="M24.2865 12.0366C23.9723 12.0366 23.6864 11.9757 23.4286 11.8541C23.1749 11.7284 22.9554 11.5642 22.7701 11.3614C22.5889 11.1546 22.4479 10.9195 22.3472 10.6559C22.2465 10.3923 22.1962 10.1227 22.1962 9.84697C22.1962 9.55908 22.2486 9.28335 22.3533 9.01979C22.462 8.75623 22.609 8.52308 22.7943 8.32034C22.9836 8.1176 23.2071 7.95743 23.4649 7.83985C23.7226 7.7182 24.0025 7.65738 24.3046 7.65738C24.6147 7.65738 24.8967 7.72226 25.1504 7.85201C25.4082 7.97771 25.6277 8.14395 25.8089 8.35075C25.9901 8.55754 26.1311 8.79272 26.2318 9.05628C26.3325 9.31984 26.3828 9.58746 26.3828 9.85913C26.3828 10.147 26.3305 10.4227 26.2257 10.6863C26.121 10.9458 25.976 11.1769 25.7908 11.3797C25.6055 11.5784 25.384 11.7385 25.1262 11.8602C24.8685 11.9778 24.5886 12.0366 24.2865 12.0366ZM23.042 9.84697C23.042 10.0335 23.0702 10.2139 23.1266 10.3883C23.1829 10.5626 23.2635 10.7167 23.3682 10.8505C23.477 10.9843 23.6078 11.0918 23.7609 11.1729C23.918 11.254 24.0952 11.2945 24.2925 11.2945C24.4939 11.2945 24.6711 11.254 24.8242 11.1729C24.9812 11.0877 25.1121 10.9762 25.2168 10.8384C25.3256 10.7005 25.4061 10.5464 25.4585 10.3761C25.5149 10.2018 25.5431 10.0254 25.5431 9.84697C25.5431 9.66045 25.5129 9.48001 25.4525 9.30565C25.3961 9.1313 25.3135 8.97721 25.2048 8.84341C25.1 8.7096 24.9692 8.60417 24.8121 8.52713C24.659 8.44604 24.4858 8.40549 24.2925 8.40549C24.0912 8.40549 23.9119 8.44806 23.7549 8.53322C23.5978 8.61431 23.4669 8.72379 23.3622 8.86165C23.2575 8.99546 23.1769 9.14954 23.1205 9.3239C23.0682 9.4942 23.042 9.66856 23.042 9.84697Z"
        fill="#F4F1EA"
      />
      <path
        d="M29.0341 12.0366C28.72 12.0366 28.434 11.9757 28.1762 11.8541C27.9225 11.7284 27.703 11.5642 27.5177 11.3614C27.3365 11.1546 27.1955 10.9195 27.0948 10.6559C26.9942 10.3923 26.9438 10.1227 26.9438 9.84697C26.9438 9.55908 26.9962 9.28335 27.1009 9.01979C27.2096 8.75623 27.3566 8.52308 27.5419 8.32034C27.7312 8.1176 27.9547 7.95743 28.2125 7.83985C28.4702 7.7182 28.7502 7.65738 29.0522 7.65738C29.3624 7.65738 29.6443 7.72226 29.898 7.85201C30.1558 7.97771 30.3753 8.14395 30.5565 8.35075C30.7378 8.55754 30.8787 8.79272 30.9794 9.05628C31.0801 9.31984 31.1304 9.58746 31.1304 9.85913C31.1304 10.147 31.0781 10.4227 30.9734 10.6863C30.8686 10.9458 30.7237 11.1769 30.5384 11.3797C30.3531 11.5784 30.1316 11.7385 29.8738 11.8602C29.6161 11.9778 29.3362 12.0366 29.0341 12.0366ZM27.7896 9.84697C27.7896 10.0335 27.8178 10.2139 27.8742 10.3883C27.9306 10.5626 28.0111 10.7167 28.1158 10.8505C28.2246 10.9843 28.3555 11.0918 28.5085 11.1729C28.6656 11.254 28.8428 11.2945 29.0401 11.2945C29.2415 11.2945 29.4187 11.254 29.5718 11.1729C29.7289 11.0877 29.8598 10.9762 29.9645 10.8384C30.0732 10.7005 30.1538 10.5464 30.2061 10.3761C30.2625 10.2018 30.2907 10.0254 30.2907 9.84697C30.2907 9.66045 30.2605 9.48001 30.2001 9.30565C30.1437 9.1313 30.0611 8.97721 29.9524 8.84341C29.8477 8.7096 29.7168 8.60417 29.5597 8.52713C29.4067 8.44604 29.2335 8.40549 29.0401 8.40549C28.8388 8.40549 28.6595 8.44806 28.5025 8.53322C28.3454 8.61431 28.2145 8.72379 28.1098 8.86165C28.0051 8.99546 27.9245 9.14954 27.8681 9.3239C27.8158 9.4942 27.7896 9.66856 27.7896 9.84697Z"
        fill="#F4F1EA"
      />
      <path
        d="M31.9633 7.68779H35.0987V8.42374H32.791V9.41513H33.975C34.1845 9.41513 34.3738 9.44352 34.5429 9.50028C34.7161 9.55705 34.8631 9.64017 34.9839 9.74965C35.1088 9.85913 35.2034 9.99294 35.2679 10.1511C35.3364 10.3092 35.3706 10.4917 35.3706 10.6985C35.3706 11.1202 35.2457 11.4446 34.996 11.6716C34.7503 11.8946 34.4201 12.0061 34.0053 12.0061H31.9633V7.68779ZM33.9267 11.2945C34.1241 11.2945 34.2731 11.2418 34.3738 11.1364C34.4745 11.0269 34.5248 10.8789 34.5248 10.6924C34.5248 10.514 34.4745 10.37 34.3738 10.2606C34.2731 10.1511 34.1241 10.0963 33.9267 10.0963H32.791V11.2945H33.9267Z"
        fill="#F4F1EA"
      />
      <path
        d="M36.1799 12.0061V7.68779H37.9984C38.1957 7.68779 38.377 7.73037 38.5421 7.81552C38.7112 7.89661 38.8562 8.00609 38.9771 8.14395C39.0979 8.27776 39.1925 8.43185 39.261 8.6062C39.3295 8.7765 39.3637 8.94883 39.3637 9.12319C39.3637 9.30565 39.3315 9.48406 39.267 9.65842C39.2026 9.82872 39.112 9.98078 38.9952 10.1146C38.8784 10.2484 38.7374 10.3558 38.5723 10.4369C38.4072 10.518 38.2259 10.5586 38.0286 10.5586H37.0136V12.0061H36.1799ZM37.0136 9.82264H37.9802C38.1333 9.82264 38.2602 9.75979 38.3608 9.63409C38.4656 9.50839 38.5179 9.33809 38.5179 9.12319C38.5179 9.01371 38.5018 8.91639 38.4696 8.83124C38.4374 8.74204 38.3951 8.66702 38.3427 8.6062C38.2904 8.54538 38.2279 8.50078 38.1554 8.47239C38.087 8.43995 38.0165 8.42374 37.944 8.42374H37.0136V9.82264Z"
        fill="#F4F1EA"
      />
      <path
        d="M41.0737 7.68779H41.8108L43.4419 12.0061H42.5841L42.1853 10.9296H40.6871L40.2944 12.0061H39.4365L41.0737 7.68779ZM42.0343 10.3396L41.4422 8.64269L40.826 10.3396H42.0343Z"
        fill="#F4F1EA"
      />
      <path
        d="M43.8459 8.24127C44.0191 8.04664 44.2185 7.90067 44.444 7.80335C44.6696 7.70198 44.9394 7.6513 45.2536 7.6513C45.6644 7.6513 45.9886 7.74253 46.2262 7.925C46.4679 8.10746 46.5887 8.37508 46.5887 8.72784C46.5887 8.9468 46.5343 9.14549 46.4256 9.3239C46.3209 9.49826 46.1537 9.62193 45.9241 9.69491C46.19 9.74763 46.3954 9.86724 46.5404 10.0538C46.6853 10.2362 46.7578 10.4633 46.7578 10.735C46.7578 10.9377 46.7176 11.1202 46.637 11.2824C46.5605 11.4405 46.4538 11.5763 46.3168 11.6899C46.1799 11.7994 46.0148 11.8845 45.8214 11.9453C45.6321 12.0061 45.4267 12.0366 45.2052 12.0366C44.8951 12.0366 44.6212 11.9859 44.3836 11.8845C44.146 11.7791 43.9345 11.623 43.7493 11.4162L44.2326 10.8323C44.3534 10.9742 44.4883 11.0857 44.6373 11.1668C44.7904 11.2479 44.9736 11.2884 45.1871 11.2884C45.4006 11.2884 45.5758 11.2317 45.7127 11.1181C45.8496 11.0046 45.9181 10.8465 45.9181 10.6437C45.9181 10.4572 45.8456 10.3031 45.7006 10.1815C45.5597 10.0598 45.3442 9.99902 45.0542 9.99902H44.6071V9.37864H45.0059C45.2636 9.37864 45.461 9.33201 45.5979 9.23875C45.7348 9.14143 45.8033 9.0056 45.8033 8.83124C45.8033 8.67716 45.751 8.55957 45.6462 8.47847C45.5456 8.39738 45.4006 8.35683 45.2113 8.35683C45.022 8.35683 44.8528 8.39332 44.7038 8.46631C44.5588 8.53524 44.4299 8.63458 44.3172 8.76434L43.8459 8.24127Z"
        fill="#F4F1EA"
      />
      <path
        d="M49.4271 12.0366C49.1129 12.0366 48.827 11.9757 48.5692 11.8541C48.3155 11.7284 48.096 11.5642 47.9107 11.3614C47.7295 11.1546 47.5885 10.9195 47.4878 10.6559C47.3871 10.3923 47.3368 10.1227 47.3368 9.84697C47.3368 9.55908 47.3891 9.28335 47.4938 9.01979C47.6026 8.75623 47.7496 8.52308 47.9349 8.32034C48.1242 8.1176 48.3477 7.95743 48.6054 7.83985C48.8632 7.7182 49.1431 7.65738 49.4452 7.65738C49.7553 7.65738 50.0372 7.72226 50.291 7.85201C50.5487 7.97771 50.7682 8.14395 50.9495 8.35075C51.1307 8.55754 51.2717 8.79272 51.3724 9.05628C51.473 9.31984 51.5234 9.58746 51.5234 9.85913C51.5234 10.147 51.471 10.4227 51.3663 10.6863C51.2616 10.9458 51.1166 11.1769 50.9313 11.3797C50.7461 11.5784 50.5246 11.7385 50.2668 11.8602C50.009 11.9778 49.7291 12.0366 49.4271 12.0366ZM48.1825 9.84697C48.1825 10.0335 48.2107 10.2139 48.2671 10.3883C48.3235 10.5626 48.4041 10.7167 48.5088 10.8505C48.6175 10.9843 48.7484 11.0918 48.9015 11.1729C49.0585 11.254 49.2358 11.2945 49.4331 11.2945C49.6345 11.2945 49.8117 11.254 49.9647 11.1729C50.1218 11.0877 50.2527 10.9762 50.3574 10.8384C50.4662 10.7005 50.5467 10.5464 50.5991 10.3761C50.6555 10.2018 50.6837 10.0254 50.6837 9.84697C50.6837 9.66045 50.6534 9.48001 50.593 9.30565C50.5366 9.1313 50.4541 8.97721 50.3453 8.84341C50.2406 8.7096 50.1097 8.60417 49.9527 8.52713C49.7996 8.44604 49.6264 8.40549 49.4331 8.40549C49.2317 8.40549 49.0525 8.44806 48.8954 8.53322C48.7384 8.61431 48.6075 8.72379 48.5027 8.86165C48.398 8.99546 48.3175 9.14954 48.2611 9.3239C48.2087 9.4942 48.1825 9.66856 48.1825 9.84697Z"
        fill="#F4F1EA"
      />
      <path
        d="M55.7454 10.8931C55.7454 11.0756 55.7092 11.2357 55.6367 11.3736C55.5642 11.5115 55.4635 11.627 55.3346 11.7203C55.2098 11.8135 55.0628 11.8845 54.8936 11.9332C54.7285 11.9818 54.5513 12.0061 54.362 12.0061H52.2898V7.68779H54.5855C54.7345 7.68779 54.8694 7.72023 54.9903 7.78511C55.1111 7.84998 55.2138 7.93513 55.2984 8.04056C55.3829 8.14193 55.4474 8.25952 55.4917 8.39332C55.54 8.52308 55.5642 8.65486 55.5642 8.78867C55.5642 8.99952 55.5118 9.1982 55.4071 9.38472C55.3024 9.56719 55.1493 9.70302 54.948 9.79223C55.1936 9.86522 55.387 9.99699 55.5279 10.1876C55.6729 10.3741 55.7454 10.6093 55.7454 10.8931ZM54.9057 10.735C54.9057 10.6579 54.8916 10.5849 54.8634 10.516C54.8392 10.443 54.805 10.3802 54.7607 10.3275C54.7164 10.2747 54.664 10.2342 54.6036 10.2058C54.5432 10.1734 54.4767 10.1572 54.4042 10.1572H53.1235V11.2945H54.362C54.4385 11.2945 54.509 11.2803 54.5734 11.252C54.6419 11.2236 54.7003 11.1851 54.7486 11.1364C54.7969 11.0837 54.8352 11.0229 54.8634 10.9539C54.8916 10.885 54.9057 10.812 54.9057 10.735ZM53.1235 8.40549V9.4942H54.2351C54.3761 9.4942 54.4949 9.44554 54.5915 9.34823C54.6922 9.25091 54.7426 9.1171 54.7426 8.9468C54.7426 8.78056 54.6962 8.64878 54.6036 8.55146C54.515 8.45415 54.4043 8.40549 54.2713 8.40549H53.1235Z"
        fill="#F4F1EA"
      />
      <path
        d="M60.2621 7.68779V12.0061H59.4345V10.1632H57.4892V12.0061H56.6555V7.68779H57.4892V9.43338H59.4345V7.68779H60.2621Z"
        fill="#F4F1EA"
      />
      <path
        d="M62.5758 7.68779H63.3129L64.944 12.0061H64.0862L63.6874 10.9296H62.1892L61.7965 12.0061H60.9386L62.5758 7.68779ZM63.5364 10.3396L62.9444 8.64269L62.3281 10.3396H63.5364Z"
        fill="#F4F1EA"
      />
      <path
        d="M1.26263 17.3518L3.44959 14.3229H4.08393V18.6413H3.25627V15.6792L1.09952 18.6413H0.434974V14.3229H1.26263V17.3518Z"
        fill="#F4F1EA"
      />
      <path
        d="M8.80105 14.3229V18.6413H7.97339V16.7984H6.02809V18.6413H5.19439V14.3229H6.02809V16.0685H7.97339V14.3229H8.80105Z"
        fill="#F4F1EA"
      />
      <path
        d="M9.63461 16.4517C9.63461 16.1881 9.68093 15.9286 9.77356 15.6732C9.87022 15.4136 10.0092 15.1825 10.1904 14.9798C10.3717 14.777 10.5932 14.6128 10.855 14.4871C11.1167 14.3614 11.4148 14.2986 11.7491 14.2986C12.1438 14.2986 12.4841 14.3858 12.7701 14.5601C13.06 14.7345 13.2755 14.9615 13.4165 15.2413L12.7761 15.6853C12.7197 15.5637 12.6512 15.4623 12.5707 15.3812C12.4901 15.2961 12.4035 15.2292 12.3109 15.1805C12.2183 15.1318 12.1216 15.0974 12.0209 15.0771C11.9202 15.0528 11.8216 15.0406 11.7249 15.0406C11.5155 15.0406 11.3322 15.0832 11.1751 15.1683C11.0221 15.2535 10.8932 15.365 10.7885 15.5029C10.6878 15.6367 10.6113 15.7907 10.5589 15.9651C10.5066 16.1354 10.4804 16.3057 10.4804 16.476C10.4804 16.6666 10.5106 16.8511 10.571 17.0295C10.6314 17.2038 10.716 17.3579 10.8248 17.4917C10.9375 17.6255 11.0704 17.733 11.2235 17.8141C11.3806 17.8952 11.5517 17.9357 11.737 17.9357C11.8336 17.9357 11.9343 17.9236 12.0391 17.8992C12.1438 17.8749 12.2424 17.8364 12.3351 17.7837C12.4317 17.731 12.5203 17.6641 12.6009 17.583C12.6814 17.4978 12.7459 17.3964 12.7942 17.2789L13.4769 17.6742C13.4084 17.8364 13.3097 17.9803 13.1809 18.106C13.056 18.2277 12.913 18.3311 12.7519 18.4162C12.5949 18.5014 12.4257 18.5662 12.2445 18.6109C12.0632 18.6555 11.886 18.6778 11.7128 18.6778C11.4067 18.6778 11.1268 18.6129 10.8731 18.4831C10.6193 18.3534 10.3998 18.1851 10.2146 17.9783C10.0333 17.7675 9.89036 17.5303 9.78565 17.2667C9.68496 16.9991 9.63461 16.7274 9.63461 16.4517Z"
        fill="#F4F1EA"
      />
      <path
        d="M17.3839 15.0589H16.0125V18.6413H15.1848V15.0589H13.8135V14.3229H17.3839V15.0589Z"
        fill="#F4F1EA"
      />
      <path
        d="M18.9434 17.3518L21.1303 14.3229H21.7647V18.6413H20.937V15.6792L18.7803 18.6413H18.1157V14.3229H18.9434V17.3518Z"
        fill="#F4F1EA"
      />
      <path
        d="M26.0649 15.0589H24.6936V18.6413H23.8659V15.0589H22.4945V14.3229H26.0649V15.0589Z"
        fill="#F4F1EA"
      />
      <path
        d="M27.4915 18.6838C27.4472 18.6838 27.3989 18.6798 27.3465 18.6717C27.2942 18.6676 27.2398 18.6595 27.1834 18.6473C27.127 18.6352 27.0707 18.619 27.0143 18.5987C26.9619 18.5784 26.9156 18.5541 26.8753 18.5257L26.9841 17.8323C27.0284 17.8567 27.0888 17.885 27.1653 17.9175C27.2459 17.9459 27.3284 17.9601 27.413 17.9601C27.5298 17.9601 27.6305 17.9276 27.7151 17.8627C27.8037 17.7938 27.8802 17.6722 27.9446 17.4978L26.3679 14.3229H27.268L28.3192 16.6524L29.2979 14.3229H30.1739L28.6636 17.6073C28.5749 17.8019 28.4884 17.9682 28.4038 18.106C28.3192 18.2398 28.2306 18.3513 28.138 18.4406C28.0494 18.5257 27.9527 18.5886 27.848 18.6291C27.7433 18.6656 27.6245 18.6838 27.4915 18.6838Z"
        fill="#F4F1EA"
      />
      <path
        d="M34.0086 18.6413H30.8248V14.3229H31.6524V17.9053H33.3259V14.3229H34.1535V17.9053H34.7939V19.7178H34.0086V18.6413Z"
        fill="#F4F1EA"
      />
      <path
        d="M36.3763 17.3518L38.5633 14.3229H39.1976V18.6413H38.37V15.6792L36.2132 18.6413H35.5487V14.3229H36.3763V17.3518Z"
        fill="#F4F1EA"
      />
      <path
        d="M40.0725 17.7593C40.1289 17.7999 40.2235 17.8445 40.3564 17.8932C40.4893 17.9418 40.6424 17.9661 40.8156 17.9661C40.9847 17.9661 41.1196 17.9378 41.2203 17.881C41.321 17.8202 41.3975 17.7289 41.4499 17.6073C41.5023 17.4856 41.5365 17.3356 41.5526 17.1572C41.5687 16.9747 41.5768 16.7619 41.5768 16.5186V14.3229H42.4105V16.5186C42.4105 16.847 42.3923 17.1471 42.3561 17.4187C42.3239 17.6864 42.2514 17.9155 42.1386 18.106C42.0299 18.2966 41.8708 18.4446 41.6613 18.55C41.4519 18.6555 41.17 18.7082 40.8156 18.7082C40.4531 18.7082 40.143 18.619 39.8852 18.4406L40.0725 17.7593Z"
        fill="#F4F1EA"
      />
      <path
        d="M44.5824 14.3229H45.3194L46.9506 18.6413H46.0927L45.694 17.5647H44.1958L43.8031 18.6413H42.9452L44.5824 14.3229ZM45.543 16.9747L44.9509 15.2778L44.3347 16.9747H45.543Z"
        fill="#F4F1EA"
      />
      <path
        d="M75.759 6.47723L76.1479 8.58962L77.6959 7.11083L76.7773 9.04995L78.893 8.76961L77.0177 9.79479L78.893 10.82L76.7773 10.5396L77.6959 12.4788L76.1479 11L75.759 13.1124L75.37 11L73.8221 12.4788L74.7407 10.5396L72.625 10.82L74.5003 9.79479L72.625 8.76961L74.7407 9.04995L73.8221 7.11083L75.37 8.58962L75.759 6.47723Z"
        fill="#F4F1EA"
      />
    </svg>
  );
};

export default BrainsterNextLogo;
