export const nuLogo = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
    >
        <circle cx="40" cy="40" r="40" fill="#024F40" />
        <path
            d="M20.4281 52.6528H15.8545V28.0046H20.4281V35.2491H20.3938C20.6598 34.0855 21.0909 32.965 21.6744 31.9211C22.2047 30.9824 22.893 30.1402 23.7097 29.4308C24.504 28.7469 25.4187 28.2135 26.4081 27.8574C27.4524 27.4809 28.5554 27.2893 29.6668 27.2915C30.9825 27.2688 32.2862 27.5416 33.48 28.0895C34.6013 28.6081 35.5813 29.3843 36.3385 30.3534C37.1626 31.4383 37.7682 32.6699 38.1223 33.9813C38.5546 35.5542 38.7643 37.179 38.7454 38.809V52.5905H34.1718V38.809C34.1898 37.7134 34.0571 36.6205 33.7773 35.5603C33.5535 34.7075 33.1437 33.9135 32.5768 33.2342C32.0389 32.6105 31.3534 32.1283 30.5815 31.8306C29.6857 31.5017 28.7354 31.3423 27.7802 31.3608C27.0601 31.3544 26.3419 31.4362 25.642 31.6042C25.0098 31.7511 24.4016 31.9854 23.8354 32.3003C23.2944 32.5999 22.8044 32.9819 22.3833 33.4323C21.9518 33.8931 21.5933 34.416 21.32 34.9831C21.0229 35.6031 20.804 36.257 20.6682 36.93C20.5096 37.6934 20.4329 38.4712 20.4395 39.2505L20.4281 52.6528Z"
            fill="#F4F1EA"
        />
        <path
            d="M60.4263 26.6298H64.9999V51.2779H60.4263V44.0335H60.4606C60.1927 45.1974 59.7597 46.3178 59.1743 47.3614C58.6479 48.3012 57.9613 49.1437 57.1448 49.8517C56.3505 50.5357 55.4357 51.069 54.4463 51.4251C53.4021 51.8016 52.299 51.9932 51.1876 51.9911C49.8724 52.0157 48.5686 51.7448 47.3744 51.1987C46.2512 50.6831 45.2706 49.9064 44.5159 48.9348C43.6919 47.8478 43.0864 46.6143 42.7322 45.3013C42.2966 43.7309 42.0868 42.1075 42.109 40.4792V26.6977H46.6826V40.4792C46.6646 41.5748 46.7974 42.6677 47.0771 43.7278C47.3009 44.5807 47.7107 45.3747 48.2777 46.054C48.8144 46.6789 49.5002 47.1614 50.2729 47.4576C51.1691 47.7846 52.1193 47.942 53.0742 47.9217C53.794 47.927 54.5118 47.8472 55.2124 47.684C55.8443 47.5361 56.4524 47.3018 57.019 46.9879C57.56 46.6882 58.05 46.3063 58.4711 45.8559C58.9012 45.394 59.2595 44.8713 59.5345 44.3051C59.8316 43.6851 60.0505 43.0312 60.1862 42.3582C60.342 41.5944 60.4186 40.8169 60.4149 40.0377L60.4263 26.6298Z"
            fill="#F4F1EA"
        />
    </svg>
);
