import React from "react";
import styled from "styled-components";

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  width: 100%;
  background: ${(props) => props.bgColor && props.bgColor};
  color: ${(props) => props.color && props.color};
  border-radius: 6px;
  cursor: pointer;
  transition: 0.2s ease-in-out 0s;
  padding: 16px 0;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
  text-transform: uppercase;
`;

const CustomButton = ({ color, bgColor, children, onClick }) => {
  return (
    <Button bgColor={bgColor} color={color} onClick={onClick}>
      {children}
    </Button>
  );
};

export default CustomButton;
