import { useEffect, useState, useRef, useContext } from "react";

//npm imports
import styled, { withTheme } from "styled-components/macro";
import { API } from "../../Consts/Api";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import brainsterNextCover from "./Static/brainster-next-cover.jpg";
import ultimathCover from "./Static/ultimath-cover.jpg";

//component imports

import {
  Caption12,
  Header25,
  Header32,
  HeaderLarge40,
  Subheader18,
  Text12,
  Text14,
  Text18,
} from "../../Components/Text/Text";
//other imports
import { mediaBlogShape, nextBlogShape } from "./Static/shapes.js";
import result_page_background from "./Static/hero-bars.png";
import nextImage from "./Static/next_platform_image.png";
import mediaImage from "./Static/media_blog_image.png";
import { GlobalContext } from "../../Context/GlobalContext";
import Footer from "../../Pages/Homepage/Blocks/Footer/Footer.jsx";
import UltimathWhiteLogo from "../../Components/UltimathLogo/UltimathWhiteLogo.jsx";
import BrainsterNextLogo from "./Blocks/BrainsterNextLogo.jsx";
import NUCollegeLogo from "./Blocks/NUCollegeLogo.jsx";

//styled components
const ResultWrapper = styled.main`
  min-height: 100vh;
  background-color: ${(props) => props.theme.forestGreen};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 0px 00px 0;
  font-family: Inter;
  position: relative;
  background-image: url(${result_page_background});
  background-position: top;
  background-repeat: no-repeat;

  .mt-12 {
    margin-top: 12px;
  }
  & > section {
    width: 100%;
    height: max-content;
    min-height: 500px;
    padding: 40px 0 0 0;
    .brainster-logo-container {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 42px;
    }
    .brainster-header-container {
      padding-bottom: 154px;
      position: relative;
      & > img {
        position: absolute;
      }
      & > img:first-of-type {
        top: 3%;
        right: 10%;

        z-index: 0;
      }

      & > img:last-of-type {
        top: 85%;
        left: 1%;
        z-index: 1;
      }
    }

    ${Header32}, ${Text18} {
      text-align: center;
    }

    ${Header32} {
      width: 47%;
      margin: 0 auto 16px auto;
      z-index: 3;
      position: relative;
    }

    ${Text18} {
      margin-bottom: 40px;
    }

    .result-content-container {
      width: 100%;
      min-width: 515px;
      margin: 80px auto 0;
      background-color: ${(props) => props.theme.warmBeige};
      position: relative;

      & > ${Text12} {
        display: none;
      }
      & > img {
        position: absolute;
        bottom: 30%;
        right: 0;
      }
      .result-code-container {
        width: 86%;
        max-width: 1240px;
        background-color: ${(props) => props.theme.neonYellow};
        transform: translateY(-154px);
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 2;
        padding: 48px 0;
        p,
        h4 {
          text-align: center;
        }
        ${Caption12} {
          margin-bottom: 20px;
          color: #005840;
          font-size: 12px;
          font-weight: 700;
          text-transform: uppercase;
        }
        ${HeaderLarge40} {
          margin-bottom: 16px;
          text-decoration: underline;
        }
        ${Text14} {
          margin-bottom: 12px;
          max-width: 432px;
          font-size: 12px;
        }
      }
      .refer-friend-container {
        width: 42%;
        max-width: 612px;
        border-bottom: 1px solid ${(props) => props.theme.veryLightGray};
        margin: 0 auto;
        padding-bottom: 64px;
        transform: ${(props) => (props.isResult ? "" : "translateY(-94px)")};
        & > ${Text14} {
          margin-bottom: 20px;
        }
        .copy-link-input-container {
          width: 100%;
          height: 64px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: ${(props) => props.theme.forestGreen};
          border-radius: 4px;
          padding: 0px 24px;
          margin-bottom: 8px;
          & > div {
            width: 85%;
            display: flex;
            align-items: center;
            svg {
              width: 24px;
              margin-right: 24px;
            }
            input {
              font-family: "Raleway", sans-serif;
              color: ${(props) => props.theme.white};
              width: calc(100% - 48px);
              background: unset;
              border: none;
              font-weight: 500;
              &:focus,
              &:active,
              &:visited {
                outline: none;
                box-shadow: none;
                border: none;
              }
            }
          }
          ${Text14} {
            color: ${(props) => props.theme.neonYellow};
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
    .cta-container {
      width: 100%;
      background-color: ${(props) => props.theme.warmBeige};
      min-width: 515px;
      padding-top: 64px;
      ${Header25} {
        color: #005840;
        font-size: 18px;
        line-height: 24px;
      }
      & > div:first-of-type {
        display: block;
      }
      & > p {
        width: 85%;
        max-width: 1030px;
        margin: 0 auto 65px auto;
        text-align: center;
      }
      .blogs-container {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 15px;
        margin-top: 26px;
        padding-bottom: 180px;
      }
      .blogs-container .ultimath-cover-wrap,
      .blogs-container .brainster-next-cover-wrap {
        position: relative;
        cursor: pointer;
        overflow: hidden;

        img {
          width: 100%;
          height: auto;
          transition: transform 0.3s ease;
        }

        &:hover img {
          transform: scale(1.2);
        }
      }
      .blogs-container .ultimath-cover-wrap .overlay,
      .blogs-container .brainster-next-cover-wrap .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: end;
        padding-left: 32px;
        padding-bottom: 32px;
        z-index: 1; /* Ensures the overlay stays on top of the image */
      }
      .blogs-container .ultimath-cover-wrap .overlay {
        background: radial-gradient(
          232.98% 151.16% at 64.3% -37.28%,
          rgba(205, 245, 69, 0) 0%,
          #cdf545 80.84%
        );
      }
      .blogs-container .brainster-next-cover-wrap .overlay {
        background: radial-gradient(
          232.98% 151.16% at 64.3% -37.28%,
          rgba(116, 151, 255, 0) 0%,
          #7497ff 80.84%
        );
      }
      .blogs-container .brainster-next-cover-wrap .overlay p.title,
      .blogs-container .ultimath-cover-wrap .overlay p.title {
        font-size: 14px;
        line-height: 18px;
        margin-top: 10px;
      }
      .blogs-container .brainster-next-cover-wrap .overlay p.title {
        color: #fff;
      }
      .blogs-container .ultimath-cover-wrap .overlay p.title {
        color: #024f40;
      }
    }
  }
  @media (max-width: 1100px) {
    & > section {
      .brainster-header-container {
        & > img:first-of-type {
          right: 4%;
        }
      }
      ${Header32} {
        width: 66%;
      }
      .refer-friend-container {
        width: 90% !important;
      }

      .cta-container {
        .blogs-container {
          flex-direction: column;
          align-items: center;
          gap: 48px;
        }
      }
    }
  }
  @media (max-width: 768px) {
    padding: 30px 0 0px 0;
    & > section {
      .result-content-container,
      .cta-container {
        min-width: 420px;
      }
      ${Header32} {
        width: 85%;
        margin: 0 auto 16px auto;
      }

      ${Subheader18} {
        margin-bottom: 40px;
      }
    }
  }
  @media (max-width: 550px) {
    padding: 0px;
    & > section {
      width: 100%;
      padding-top: 0px;
      background-image: none;
      .brainster-header-container {
        & > img {
          display: none;
        }
        padding-top: 59px;
        padding-bottom: ${(props) => (props.isResult ? "194px" : "0px")};
        display: flex;
        flex-direction: column;
        align-items: center;
        .brainster-logo-container {
          margin-bottom: 32px;
          svg {
            path {
              fill: ${(props) => props.theme.white};
            }
          }
        }
        ${Header32} {
          font-size: 25px;
          line-height: 30px;
          font-weight: 500;
          width: 80%;
          margin: 50px auto 24px auto;
        }
        ${Text18} {
          width: 80%;
          margin-bottom: 0;
        }
        ${Header32}, ${Subheader18} {
          color: ${(props) => props.theme.white};
        }
      }
      .result-content-container {
        min-width: 290px;
        margin-top: -41px;
        padding-left: 16px;
        padding-right: 16px;
        & > ${Text12} {
          width: 90%;
          margin: 0 auto;
          text-align: center;
          line-height: 18px;
          padding-bottom: 40px;
          border-bottom: 1px solid ${(props) => props.theme.veryLightGray};
          display: ${(props) => (props.isResult ? "" : "block")};
          transform: translateY(-64px);
        }
        & > img {
          display: none;
        }
        .result-code-container {
          width: 100%;
          padding-left: 20px;
          padding-right: 20px;

          transform: translateY(-94px);
          & > p:last-of-type {
            /* display: none; */
          }
        }
        .refer-friend-container {
          padding-bottom: 42px;
          border-bottom: unset;
          margin-bottom: 0px;
          & > ${Text14} {
            margin-bottom: 14px;
          }
          .copy-link-input-container {
            padding: 0 18px;
            & > div {
              svg {
                margin-right: 18px;
              }
            }
          }
        }
        .refer-friend-container {
          margin-top: ${(props) => (props.isResult ? "-30px" : "72px")};
        }
      }
      .cta-container {
        min-width: 290px;
        padding: 0px 0px 56px 0px;
        position: relative;
        z-index: 9;
        & > div:first-child {
          display: block;
          width: 90%;
          height: 1px;
          background-color: ${(props) => props.theme.veryLightGray};
          margin: 0px auto 42px auto;
        }
        & > ${Header25} {
          font-size: 18px;
          line-height: 30px;
          font-weight: 500;
        }
        .blogs-container {
          width: 100%;
          margin: 45px 0 0 0;
          padding-bottom: 45px;
          flex-direction: column;
          padding-left: 16px;
          padding-right: 16px;
        }
      }
    }
  }
  @media (max-width: 350px) {
    .result-code-container {
      background-size: contain;
      background-position: right;
    }
  }
`;

const HeroOverlay = styled.div`
  width: 100%;
  background: radial-gradient(
    56.6% 77.35% at 50% -7.65%,
    /* Adjust the position to start from the top */ rgba(205, 245, 69, 0.4) 0%,
    /* Light color at the top */ rgba(0, 88, 64, 0) 100%
      /* Darker color at the bottom */
  );
  position: absolute;
  top: 0;
  left: 0;
  height: 340px;
`;
export const BlogCta = styled.div`
  width: 480px;
  height: 180px;
  border-radius: 90px;
  position: relative;
  background-color: ${(props) => props.theme.violet};
  background-size: cover;
  background-repeat: no-repeat;
  border: 1px solid ${(props) => props.theme.black};
  ${Header25} {
    max-width: 164px;
    font-weight: 600;
  }
  & > p {
    z-index: 2;
    position: relative;
  }
  & > svg {
    z-index: 1;
  }
  img {
    position: absolute;
    z-index: 999999;
  }
  &.brainster_blog {
    img {
      top: 20px;
      right: 20px;
    }
    & > svg {
      position: absolute;
      top: -30px;
      left: 75%;
    }
    ${Header25} {
      position: absolute;
      top: 50%;
      left: 10%;
      transform: translateY(-50%);
    }
  }

  .circle {
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    aspect-ratio: 1 / 1;
    background-color: ${(props) => props.theme.altBlue};
    border-radius: 50%;
    transition: right 0.1s linear, left 0.1s linear;
  }
  &:hover {
    .circle {
      left: 0%;
    }

    &.media_blog {
      .circle {
        right: 0%;
      }
    }
  }
  &.media_blog {
    .circle {
      left: unset;
      right: 50%;
    }
    img {
      bottom: -20px;
      left: 30%;
    }

    & > svg {
      position: absolute;
      top: unset;
      bottom: -25px;
      left: 20%;
    }
    ${Header25} {
      position: absolute;
      top: 50%;
      right: 10%;
      transform: translateY(-50%);
    }
  }
  @media (max-width: 550px) {
    width: 317px;
    height: 130px;
    .block-mobile-shape {
      display: block;
    }
    ${Header25} {
      font-size: 16px;
      line-height: 22px;
      font-weight: 500;
    }
    & > img {
      height: 90%;
    }

    &.media_blog {
      & > img {
        height: 140%;
      }
      &.block-shape {
        display: none;
      }
    }
  }
`;

//main component
const Result = (props) => {
  const { t } = useTranslation();
  let params = useParams();
  const referFriendInputRef = useRef();
  // States
  const { userInfo, setUserInfo, formatResultsDate, setDuration } =
    useContext(GlobalContext);
  const [result, setResult] = useState(false);
  const [isLinkCopied, setIsLinkCopied] = useState(false);

  useEffect(() => {
    API.axios
      .get(API.createApiRoute("result", { GUID: params.guid }))
      .then(
        (response) => response.data.success && setResult(response.data.data)
      )
      .catch((error) => console.error(error.response));
    setDuration(false);
    //eslint-disable-next-line
  }, []);



  const copyLinkToClipboard = (e) => {
    referFriendInputRef.current.select();
    document.execCommand("copy");
    referFriendInputRef.current.blur();
    setIsLinkCopied(true);
  };

  return (
    <ResultWrapper isResult={result}>
      <HeroOverlay></HeroOverlay>
      <section>
        <div className="brainster-header-container">
          <div className="brainster-logo-container">
            <Link to={API.createRoute("home")} target="_blank">
              <UltimathWhiteLogo />
            </Link>
          </div>
          <Header32 color={props.theme.white}>
            Честитки! Успешно ја комплетираше квалификациската рунда од
            натпреварот
          </Header32>
        </div>
        <div className="result-content-container">
          {result && (
            <div className="result-code-container">
              <Caption12 color={props.theme.forestGreen}>
                {t("result.main.completed-with-results.your_code")}
              </Caption12>
              <HeaderLarge40 color={props.theme.forestGreen}>
                {result.token}
              </HeaderLarge40>
              <Text14 color={props.theme.forestGreen} fontWeight={500}>
                {t("result.main.completed-with-results.winners_date")
                  .replace("{EXPIRATION_DATE}", () => formatResultsDate())
                  .replace(
                    "{DOMAIN}",
                    `${process.env.REACT_APP_DOMAIN_URL}${API.createRoute(
                      "awards"
                    )
                      .replace("{ACADEMY_ID}", "")
                      .replace("/", "")}`
                  )}
              </Text14>
            </div>
          )}
          {result && (
            <>
              <div className="refer-friend-container">
                <Text14 fontWeight={700}>
                  {t("result.refer_a_friend.header")}
                </Text14>
                <div className="copy-link-input-container">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_297_8727)">
                        <path
                          d="M17 7H13V9H17C18.65 9 20 10.35 20 12C20 13.65 18.65 15 17 15H13V17H17C19.76 17 22 14.76 22 12C22 9.24 19.76 7 17 7ZM11 15H7C5.35 15 4 13.65 4 12C4 10.35 5.35 9 7 9H11V7H7C4.24 7 2 9.24 2 12C2 14.76 4.24 17 7 17H11V15ZM8 11H16V13H8V11Z"
                          fill="#CDF545"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_297_8727">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <input
                      ref={referFriendInputRef}
                      type="text"
                      readOnly={true}
                      value={`${process.env.REACT_APP_DOMAIN_URL}reffer/${params.guid}`}
                    />
                  </div>
                  <Text14
                    fontWeight={700}
                    onClick={(e) => copyLinkToClipboard(e)}
                  >
                    {!isLinkCopied ? t("general.copy") : t("general.copied")}
                  </Text14>
                </div>
                <Text12>{t("result.refer_a_friend.description")}</Text12>
              </div>
            </>
          )}
        </div>
        <div className="cta-container">
          <div></div>
          <Header25 color={props.theme.altBlack} fontWeight={600}>
            {t("result.cta.header")}
          </Header25>
          <div className="blogs-container">
            <div className="brainster-next-cover-wrap">
              <a href="https://next.edu.mk/" target="_blank">
                <div className="overlay">
                  <div className="brainster-next-logo-container">
                    <BrainsterNextLogo />
                    <p className="title">
                      Aкредитиран практичен <br />
                      IT факултет
                    </p>
                  </div>
                </div>
              </a>
              <img src={brainsterNextCover} alt="" />
            </div>
            <div className="ultimath-cover-wrap">
              <a href="https://nucollege.ai/" target="_blank">
                <div className="overlay">
                  <div className="brainster-next-logo-container">
                    <NUCollegeLogo />
                    <p className="title">
                      Прв кoлеџ за <br />
                      средношколци
                    </p>
                  </div>
                </div>
              </a>
              <img src={ultimathCover} alt="" />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </ResultWrapper>
  );
};

export default withTheme(Result);
