import { Routes, Route } from "react-router-dom";

//pages imports
import Homepage from './Pages/Homepage/Homepage';
import Assessment from './Pages/Assessment/Assessment'
import RulesAgreement from "./Pages/RulesAgreement/RulesAgreement";
import Result from "./Pages/Result/Result";
import ApplicationFormNew from "./Pages/ApplicationFormNew/ApplicationFormNew";
import Rules from "./Pages/Rules/Rules";

//npm imports
import { ThemeProvider } from 'styled-components'

//other imports
import theme from './Consts/Theme';
import GlobalStyle from "./Consts/GlobalStyle";
import AllRulesPopup from "./Components/AllRulesPopup/AllRulesPopup";
import AwardsPending from "./Pages/AwardsPending/AwardsPending";
import SpeedArithmeticGame from "./Pages/SpeedArithmeticGame/SpeedArithmeticGame";
import MemoryMatrixGame from "./Pages/MemoryGame/MemoryMatrixGame";
import ErrorComponent from "./Components/ErrorComponent/ErrorComponent";


function App() {

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <ErrorComponent></ErrorComponent>
      <Routes>
        <Route index path="/" element={<Homepage />} />
        <Route index path="/reffer/:referralGuid" element={<Homepage />} />
        <Route path="/apply/:academyId" element={<ApplicationFormNew />} />
        <Route path="/apply/reffer/:referralGuid/:academyId" element={<ApplicationFormNew />} />
        <Route path="/assessment/confirm/:academyId" element={<RulesAgreement />} />
        <Route path="/memory-game" element={<MemoryMatrixGame />} />
        <Route path="/speed-arithmetic-game" element={<SpeedArithmeticGame />} />
        <Route path="/assessment" element={<Assessment />} />
        <Route path="/result/:guid" element={<Result />} />
        <Route path="/pending" element={<AwardsPending />} />
        <Route path="/rules" element={<Rules />} />
        

        {/* <Route path="/apply" element={<ApplicationFormNew />} /> */}
        {/* <Route path="/register-old1" element={<RegistrationFormOld1 />} /> */}
        {/* <Route path="/register-old" element={<ApplicationForm />} />/ */}

      </Routes>
      <AllRulesPopup />
    </ThemeProvider>
  );
}

export default App;
