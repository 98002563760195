import styled from "styled-components";
import ContentWrapper from "../../../../Components/ContentWrapper/ContentWrapper";
import {eastGateLogo} from "./Static/east-gate-logo";
import { wvpLogo } from "./Static/wvp-logo";
import { iBornLogo } from "./Static/iborn-logo";
import { theBeetlesLogo } from "./Static/the-beetles-logo";
import { mofLogo } from "./Static/mof-logo";
import { urbanFmLogo } from "./Static/urban-fm-logo";
import { gorskaLogo } from "./Static/gorska-logo";
import { crvenKrstLogo } from "./Static/crven-krst-logo";


const StyledContainer = styled.section`
    font-family: Inter;

    .heading-2 {
        color: #000;
        font-family: Inter;
        font-size: 40px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 40px;
        text-transform: uppercase;
    }

    .heading-3 {
        color: #000;
        font-family: Inter;
        font-size: 40px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 40px;
        text-transform: uppercase;
    }

    .section-container {
        padding-top: 88px;
        padding-bottom: 88px;
        background-color: #cdf545;
    }

    .items {
        display: flex;
        gap: 16px;
        /* justify-content: center; */
        flex-wrap: wrap;

    }

    .item {
        width: 316px;
        height: 204px;
        background: rgba(0, 88, 64, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 1 calc(25% - 20px);
        max-width: calc(25% - 20px);
        svg {
            width: 200px;
        }
    }

    .mb-30 {
        margin-bottom: 40px;
    }

    @media (max-width: 767px) {
        padding-top: 64px;

        .items {
            justify-content: center;
        }

        .heading-2 {
            font-size: 30px;
        }

        .section-container {
            padding-top: 44px;
            padding-bottom: 44px;
            background-color: #cdf545;
        }

        .item {
            width: 177px;
            height: 168px;
            flex: 1 1 calc(50% - 20px); /* Two blocks per row */
            max-width: calc(50% - 20px);
        }
    }
`;

const SponsorsSection = () => {
    return (
        <StyledContainer>
            <div className="section-container">
                <ContentWrapper>
                    <div className="mb-30">
                        <h2 className="heading-2">СПОНЗОРИ</h2>
                        <div className="items">
                            <a href="https://eastgatemall.mk" target="_blank" className="item">
                                {eastGateLogo}
                            </a>
                            <a href="https://www.wvpfondovi.mk/mk/" target="_blank" className="item">
                                { wvpLogo }
                            </a>
                            <a href="https://iborn.net/" target="_blank" className="item">
                                { iBornLogo }
                            </a>
                            <a href="https://www.instagram.com/thebeetlesclub/" target="_blank" className="item">
                                { theBeetlesLogo }
                            </a>
                        </div>
                    </div>
                    <div>
                    <h2 className="heading-3">Поддржувачи</h2>
                        <div className="items">
                            <a href="https://mof.mk/" target="_blank" className="item">
                                { mofLogo }
                            </a>
                            <a href="https://urbanfm.mk/" target="_blank" className="item">
                                { urbanFmLogo }
                            </a>
                            <a href="https://www.kupigorska.mk/" target="_blank" className="item">
                                { gorskaLogo }
                            </a>
                            <a href="https://ckgs.org.mk/" target="_blank" className="item">
                                { crvenKrstLogo }
                            </a>
                        </div>
                    </div>
                </ContentWrapper>
            </div>
        </StyledContainer>
    );
};

export default SponsorsSection;
