import styled from "styled-components";
import ContentWrapper from "../../../../Components/ContentWrapper/ContentWrapper";
import { next } from "./Static/next";
import { nu } from "./Static/nu";
import { arrow } from "./Static/arrow";
import { ultimath } from "./Static/ultimath";

const StyledContainer = styled.section`
    font-family: Inter;

    .section-container {
        padding-top: 64px;
        padding-bottom: 64px;
        background-color: #005840;
        height: 648px;
    }

    .logos-wrapper {
        display: flex;
        gap: 56px;
    }

    .logo-link-wrapper {
        display: flex;
        justify-content: space-between;
    }

    .links-wrapper {
        margin-top: 48px;
    }

    .links-wrapper div {
        color: #f2f1ee;
        font-size: 14px;
        margin-bottom: 24px;
    }

    .links-wrapper .link:nth-child(2) {
        margin-bottom: 16px;
    }

    .links-wrapper .link {
        color: #f2f1ee;
        font-size: 18px;
        line-height: 23px;
        font-family: Ligo;
        display: inline-flex;
        align-items: center;
        gap: 8px;
        padding-bottom: 2px;
        border-bottom: 1px dashed #cdf545;
    }

    .ultimath-logo {
        margin-top: auto;
        position: absolute;
        right: 0;
        left: 0;
        bottom: 0;
    }

    .content-wrapper {
        width: 95%;
        margin: 0 auto;
        max-width: 1312px;
        position: relative;
        height: 100%;
    }

    @media (max-width: 767px) {
        .section-container {
            padding-bottom: 44px;
            height: 669px;
        }

        .logos-wrapper {
            gap: 40px;
            margin-bottom: 96px;
        }

        .logo-link-wrapper {
            display: block;
            justify-content: space-between;
        }

        .links-wrapper {
            margin-top: 40px;
        }
    }
`;

const Footer = () => {
    return (
        <StyledContainer>
            <div className="section-container">
                <div className="content-wrapper">
                    <div className="logo-link-wrapper">
                        <div className="logos-wrapper">
                            <div>
                                <a href="https://next.edu.mk/" target="_blank">
                                    {next}
                                </a>

                                <div className="links-wrapper">
                                    <div>
                                        <a
                                            href="https://www.instagram.com/brainsternext/"
                                            className="link"
                                            target="_blank"
                                        >
                                            <span>Instagram</span>
                                            {arrow}
                                        </a>
                                    </div>

                                    <div>
                                        <a
                                            href="https://www.tiktok.com/@brainsterco"
                                            className="link"
                                            target="_blank"
                                        >
                                            <span>TikTok</span>
                                            {arrow}
                                        </a>
                                    </div>

                                    <div>
                                        <a
                                            href="https://next.edu.mk/"
                                            className="link"
                                            target="_blank"
                                        >
                                            <span>next.edu.mk</span>
                                            {arrow}
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <a href="https://nucollege.ai/" target="_blank">
                                    {nu}
                                </a>

                                <div className="links-wrapper">
                                    <div>
                                        <a
                                            href="https://www.instagram.com/nucollege_/"
                                            className="link"
                                            target="_blank"
                                        >
                                            <span>Instagram</span>
                                            {arrow}
                                        </a>
                                    </div>

                                    <div>
                                        <a
                                            href="https://www.tiktok.com/@nucollege"
                                            className="link"
                                            target="_blank"
                                        >
                                            <span>TikTok</span>
                                            {arrow}
                                        </a>
                                    </div>

                                    <div>
                                        <a
                                            href="https://nucollege.ai/"
                                            className="link"
                                            target="_blank"
                                        >
                                            <span>nucollege.ai</span>
                                            {arrow}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ultimath-logo">{ultimath}</div>
                </div>
            </div>
        </StyledContainer>
    );
};

export default Footer;
