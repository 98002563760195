import { forwardRef } from "react";

//npm imports
import styled, { withTheme } from "styled-components/macro";
import useArrayLength from "../../Hooks/useArrayLength";

//component imports
import { Caption12 } from "../Text/Text";

const InputFieldWrapper = styled.div`
    width: 100%;
    margin-bottom: 32px;

    .label {
        margin-bottom: 6px;
    }

    .error {
        height: 14px;
        margin-top: 8px;
    }
`;

const InputContainer = styled.aside`
    width: 100%;
`;

const Input = styled.input`
    width: 100%;
    padding: 20px 16px;
    font-family: "Inter", sans-serif;
    font-size: ${(props) => props.theme.text14}px;
    line-height: ${(props) => props.theme.text16LineHeight}px;
    font-weight: 400;
    color: ${(props) =>
        props.errors?.length > 0
            ? props.theme.textError
            : props.val !== ""
            ? props.theme.black
            : "#A49E92"};
    border-radius: 8px;
    border: 1px solid
        ${(props) =>
            props.errors?.length > 0
                ? props.theme.textError
                : props.val !== ""
                ? "#D5CFC3"
                : "#D5CFC3"};
    background-color: ${(props) =>
        props.inputBackground ? props.inputBackground : "#FBFAF6"};
    outline: none;

    &:focus,
    &:active,
    &:target {
        outline: none;
    }

    &:focus {
        color: ${(props) => props.theme.black};
    }

    ::placeholder {
        color: #A49E92;
    }

    @media (max-width: 550px) {
        //
    }
`;

const InputFieldLight = forwardRef(
    (
        {
            type,
            required,
            placeholder,
            disabled,
            autocomplete,
            borderColor,
            value,
            defaultValue,
            name,
            onChange,
            label,
            errors,
            theme,
            className,
            placeholderColor,
            inputBackground,
            labelColor,
        },
        ref
    ) => {
        return (
            <InputFieldWrapper className={className}>
                <Caption12 className="label" color={labelColor || theme.black}>
                    {label}
                </Caption12>
                <InputContainer>
                    <Input
                        errors={errors}
                        type={type}
                        ref={ref}
                        placeholder={placeholder}
                        disabled={disabled}
                        name={name && name}
                        onChange={onChange}
                        defaultValue={defaultValue}
                        val={value}
                        borderColor={borderColor}
                        autocomplete={autocomplete}
                        placeholderColor={placeholderColor}
                        inputBackground={inputBackground}
                    />
                </InputContainer>
                <Caption12 className="error" color={theme.textError}>
                    {useArrayLength(errors) &&
                        errors.map((error, index) => (
                            <span key={index}>
                                <span>{error}</span>
                                <br />
                            </span>
                        ))}
                </Caption12>
            </InputFieldWrapper>
        );
    }
);

export default withTheme(InputFieldLight);
