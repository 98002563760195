import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import ContentWrapper from "../../../../Components/ContentWrapper/ContentWrapper";
import nextImage from "./Static/next-image.png";
import nuImage from "./Static/nu-image.png";
import { nextLogo } from "./Static/next-logo";
import { nuLogo } from "./Static/nu-logo";
import nextLogoContainer from "./Static/next-logo-container.png";
import nuLogoContainer from "./Static/nu-logo-container.png";
import { Link } from "react-router-dom";

const StyledContainer = styled.section`
    padding-bottom: 120px;
    font-family: Inter;

    .header-section {
        display: flex;
        flex-direction: column;
        align-items: center;

        & > :first-child {
            display: inline-flex;
            padding: 16px 32px;
            justify-content: center;
            align-items: center;
            gap: 12px;
            border-radius: 100px;
            background: #005840;
            margin-bottom: 10px;
            color: #cdf545;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            text-transform: uppercase;
        }

        p {
            text-align: center;
            color: #005840;
            text-align: center;
            font-family: Inter;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 26px; /* 130% */
            text-transform: uppercase;
        }
    }

    .wrapper {
        display: flex;
        gap: 16px;
        justify-content: center;
        align-items: center;
        margin-top: 88px;

        .block {
            max-width: 427px;

            position: relative;
            border-radius: 16px;
            background: #ece7db;
            padding: 24px;

            .image-container {
                margin-top: 24px;

                img {
                    width: 100%;
                }
            }

            img.logo-container {
                position: absolute;
                width: 210px;
                z-index: 9;
                left: 50%;
                transform: translateX(-50%);
                top: -40.3px;
            }
        }

        .logo-button-wrapper {
            display: flex;
            align-items: center;
            gap: 24px;
        }

        .text-button-wrapper {
            display: flex;
            align-items: center;
            gap: 16px;

            span {
                font-size: 14px;
                font-weight: 500;
            }

            .follow-button {
                display: inline-flex;
                padding: 10px 32px;
                justify-content: center;
                align-items: center;
                border-radius: 12px;
                background: #000;
                color: #fff;
                font-size: 14px;
            }
        }
    }

    @media (max-width: 940px) {
        .wrapper {
            flex-direction: column;
            gap: 44px;

            .block {
                img.logo-container {
                    width: 165px;
                    top: -32px;
                }
            }
        }
    }

    @media (max-width: 767px) {
        padding-top: 64px;
        padding-bottom: 57px;
    }
`;

const FollowUsSection = (props) => {
    return (
        <StyledContainer>
            <ContentWrapper>
                <div className="header-section">
                    <div>СЛЕДИ Нѐ</div>
                    <p>
                        на Instagram за повеќе информации <br /> поврзани со
                        UltiMATH
                    </p>
                </div>
                <div className="wrapper">
                    <a href="https://www.instagram.com/brainsternext/" className="block">
                        <img
                            className="logo-container"
                            src={nextLogoContainer}
                            alt="Next College Logo"
                        />
                        <div className="logo-button-wrapper">
                            {nextLogo}
                            <div className="text-button-wrapper">
                                <span>brainsternext</span>
                                <div className="follow-button" target="_blank">
                                    Follow
                                </div>
                            </div>
                        </div>
                        <div className="image-container">
                            <img src={nextImage} alt="Next Instagram Wall" />
                        </div>
                    </a>
                    <a href="https://www.instagram.com/nucollege_/?igsh=NWEya3Q1anRjOTZ6" className="block">
                        <img
                            className="logo-container"
                            src={nuLogoContainer}
                            alt="Nu College Logo"
                        />
                        <div className="logo-button-wrapper">
                            {nuLogo}
                            <div className="text-button-wrapper">
                                <span>nucollege_</span>
                                <div className="follow-button" target="_blank">
                                    Follow
                                </div>
                            </div>
                        </div>
                        <div className="image-container">
                            <img
                                src={nuImage}
                                alt="Nu College Instagram Wall"
                            />
                        </div>
                    </a>
                </div>
            </ContentWrapper>
        </StyledContainer>
    );
};

export default FollowUsSection;
