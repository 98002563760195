export const eastGateLogo = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 188" fill="none">
<path d="M77.8008 91.5842H122.2V47.1389H77.8008V91.5842ZM119.799 49.5576V54.1256H87.7158V56.533H119.799V61.0224H87.7158V63.4131H119.799V68.0708H80.1954V49.552L119.799 49.5576ZM80.1954 89.1767V70.4839H119.794V74.8779H87.5139V84.1766H110.72V81.7748H89.9198V77.2854H119.799V89.1767H80.1954Z" fill="#010101"/>
<path d="M35.6396 108.077V124.357H46.9343V121.742H38.5951V117.471H46.3903V114.772H38.6287V110.675H46.7772V108.077H35.6396Z" fill="#010101"/>
<path d="M52.5585 124.357H49.4629L56.372 108.077H59.6135L66.6684 124.357H63.4269L61.5707 119.918H56.1645L57.202 117.303H60.4771L57.9198 111.186L52.5585 124.357Z" fill="#010101"/>
<path d="M69.5401 123.678V120.945C71.3143 121.575 73.187 121.883 75.0696 121.854C77.5633 121.854 78.8102 121.02 78.8102 119.351C78.8102 118.132 78.0456 117.524 76.5165 117.527H73.9648C70.6336 117.527 68.9699 116.01 68.9736 112.976C68.9736 109.632 71.344 107.961 76.0847 107.965C77.8414 107.954 79.5885 108.223 81.2609 108.762V111.495C79.6037 110.884 77.8505 110.576 76.0847 110.586C73.3554 110.586 71.9889 111.382 71.9852 112.976C71.9852 114.188 72.645 114.794 73.9648 114.794H76.5165C80.057 114.794 81.8273 116.313 81.8273 119.351C81.8273 122.763 79.5841 124.471 75.0976 124.475C73.2242 124.5 71.3584 124.231 69.5681 123.678" fill="#010101"/>
<path d="M96.189 108.077V110.698H91.5231V124.357H88.5116V110.698H83.8457V108.077H96.189Z" fill="#010101"/>
<path d="M118.33 123.605C116.764 124.231 115.086 124.527 113.4 124.475C107.657 124.475 104.786 121.646 104.786 115.99C104.786 110.64 107.706 107.965 113.546 107.965C115.176 107.939 116.796 108.209 118.33 108.762V111.495C116.89 110.882 115.34 110.572 113.776 110.586C109.873 110.586 107.919 112.387 107.915 115.99C107.915 119.899 109.759 121.854 113.445 121.854C114.077 121.843 114.704 121.75 115.312 121.579V116.843H118.33V123.605Z" fill="#010101"/>
<path d="M124 124.357H120.904L127.808 108.077H131.055L138.104 124.357H134.863L133.006 119.918H127.606L128.638 117.303H131.919L129.356 111.186L124 124.357Z" fill="#010101"/>
<path d="M150.195 108.077V110.698H145.529V124.357H142.512V110.698H137.852V108.077H150.195Z" fill="#010101"/>
<path d="M164.204 108.077V110.675H156.055V114.772H163.817V117.471H156.022V121.742H164.361V124.357H153.066V108.077H164.204Z" fill="#010101"/>
<path d="M81.81 138.97H81.838L83.6718 133.7H85.5393V140.85H84.1093V135.968L84.0812 135.962L82.3147 140.85H81.3389L79.5724 135.951L79.5443 135.956V140.85H78.1143V133.7H79.993L81.81 138.97Z" fill="#010101"/>
<path d="M96.6327 139.307H94.3166L93.8343 140.85H92.3369L94.7484 133.7H96.2177L98.6179 140.855H97.1206L96.6327 139.307ZM94.6699 138.184H96.285L95.4943 135.665H95.4662L94.6699 138.184Z" fill="#010101"/>
<path d="M106.481 139.75H109.677V140.85H105.045V133.7H106.481V139.75Z" fill="#010101"/>
<path d="M117.87 139.75H121.067V140.85H116.435V133.7H117.87V139.75Z" fill="#010101"/>
<path d="M71.8845 136.663H35.6396V137.892H71.8845V136.663Z" fill="#010101"/>
<path d="M164.361 136.663H127.701V137.892H164.361V136.663Z" fill="#010101"/>
</svg>