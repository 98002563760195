import { useContext, useEffect, useState } from "react";
//npm imports
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components/macro";

//component imports
import HomePageHero from "./Blocks/HomepageHero/HomepageHero";
import HomepageSubHeroSection from "./Blocks/HomepageSubHeroSection/HomepageSubHeroSection";
import StructureCompetitionSection from "./Blocks/StructureCompetitionSection/StructureCompetitionSection";
import CompetitionInfo from "./Blocks/CompetitionInfo/CompetitionInfo";
import SponsorsSection from "./Blocks/SponsorsSection/SponsorsSection";
import Footer from "./Blocks/Footer/Footer";
import { API } from "../../Consts/Api";
//other imports
import { GlobalContext } from "../../Context/GlobalContext";
import FollowUsSection from "./Blocks/FollowUsSection/FollowUsSection";

// styled-components
const StyledContainer = styled.section`
  width: 100%;
`;

const Homepage = () => {
  let params = useParams();
  let navigate = useNavigate();
  const guid = localStorage.getItem("guid");
  // context
  const { userInfo, setUserInfo } = useContext(GlobalContext);


  useEffect(() => {
    if (params.referralGuid) {
      setUserInfo({ ...userInfo, referred_by: params.referralGuid });
    }
    //eslint-disable-next-line
  }, [params.referralGuid]);

  useEffect(() => {
    // if (guid && !hasFinishedMemoryMatrixGame) {
    //   navigate(API.createRoute("memoryGame"));
    //   return;
    // }

    const queryString = window.location.search;

    // Removes the '?' from the start of the string
    const cleanedQueryString = queryString.substring(1);

    if (cleanedQueryString !== "") {
      localStorage.setItem("queryString", cleanedQueryString);
    }
  }, []);

  useEffect(() => {
    if (!guid) return;

    if (!userInfo.memoryMatrixResult && !userInfo.speedArithmeticResult) {
      navigate(API.createRoute("memoryGame"));
      return;
    } else if (userInfo.memoryMatrixResult && !userInfo.speedArithmeticResult) {
      navigate(API.createRoute("speedArithmeticGame"));
      return;
    } else if (userInfo.memoryMatrixResult && userInfo.speedArithmeticResult) {
      navigate(API.createRoute("assessment"));
      return;
    }
  }, [userInfo]);

  const resetButton = () => {
    setScrollToStructureSection(false);
    setScrollToCompetitionInfoSection(false);
  };

  const [scrollToStructureSection, setScrollToStructureSection] =
    useState(false);

  const [scrollToCompetitionInfoSection, setScrollToCompetitionInfoSection] =
    useState(false);

  const handleScrollToSection = (section) => {
    if (section == "structure-section") {
      setScrollToStructureSection(true);
    } else if (section == "competition-info-section") {
      setScrollToCompetitionInfoSection(true);
    }
  };

  return (
    <StyledContainer>
      <HomePageHero handleScrollToSection={handleScrollToSection} />
      <HomepageSubHeroSection />
      <StructureCompetitionSection
        resetButton={resetButton}
        scrollToStructureSection={scrollToStructureSection}
      />
      <CompetitionInfo
        resetButton={resetButton}
        scrollToCompetitionInfoSection={scrollToCompetitionInfoSection}
      />
      <FollowUsSection />
      <SponsorsSection />
      <Footer />
    </StyledContainer>
  );
};

export default Homepage;
