import styled from "styled-components";
import ContentWrapper from "../../../../Components/ContentWrapper/ContentWrapper";
import { ellipseRed } from "./Static/ellipseRed";
import { twoArrowsDown } from "./Static/twoArrowsDown";

const StyledContainer = styled.section`
    padding-top: 120px;
    padding-bottom: 72px;

    .grouped-badges {
        display: inline-flex;
        flex-direction: column;
        align-items: start;
        gap: 8px;
        margin-bottom: 24px;
        min-width: 320px;
    }

    .badge {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 10px 24px;
        border-radius: 100px;
        background: #81003b;
        height: 40px;
    }

    .badge-date {
        display: flex;
        align-items: center;
    }

    .text {
        color: #ffb3d3;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        text-transform: uppercase;
    }

    .text-date {
        color: #ffb3d3;
        font-family: Ligo;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
    }

    .grouped-badges-text {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 20px;
    }

    .text-30 {
        max-width: 925px;
        font-family: Inter;
        font-size: 30px;
        font-style: normal;
        font-weight: 500;
        line-height: 40px;
        background: linear-gradient(75deg, #81003b 34.46%, #ffb3d3 116.44%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .arrows-wrapper {
        display: flex;
        justify-content: flex-end;
        margin-top: 46px;
    }

    @media (max-width: 767px) {
        padding-top: 56px;

        .grouped-badges-text {
            flex-direction: column;
        }

        .text-30 {
            margin-top: 46px;
            font-size: 24px;
            line-height: 35px;
        }

        .arrows-wrapper {
            margin-top: 26px;
        }
    }
`;

const HomepageSubHeroSection = () => {
    return (
        <StyledContainer>
            <ContentWrapper>
                <div>
                    <div className="grouped-badges-text">
                        <div className="grouped-badges">
                            <div className="badge">
                                <span className="text">
                                    РУНДА 0 - КВАЛИФИКАЦИСКА РУНДА
                                </span>
                            </div>
                            <div className="badge badge-date">
                                {ellipseRed}
                                <div className="text-date">29.10 - 26.11</div>
                            </div>
                        </div>

                        <div className="text-30">
                            Најголемиот натпревар по математика за средношколци
                            во Македонија. UltiMATH е годишен натпревар
                            организиран од „Brainster Next” и „NU - High School
                            College“ кој ќе ги награди најдобрите математичари
                            во земјата и ќе ги инспирира младите кон кариера во
                            математика, технологија и наука.
                        </div>
                    </div>
                    <div className="arrows-wrapper">{twoArrowsDown}</div>
                </div>
            </ContentWrapper>
        </StyledContainer>
    );
};

export default HomepageSubHeroSection;
