import React, { useEffect, useState } from "react";
import styled from "styled-components";
import StepComponent from "../../../Components/StepNavigation/StepComponent";
import theme from "../../../Consts/Theme";
export const STEPS = [
  {
    id: 1,
    title: "Мемори матрикс",
    showActiveClass: false,
  },
  {
    id: 2,
    title: "Ментална математика",
    showActiveClass: false,
  },
  {
    id: 3,
    title: "Аритмофобија",
    showActiveClass: false,
  },
];

const Container = styled.div`
  margin-top: 56px;
  display: grid;
  grid-template-columns: repeat(${STEPS.length}, 1fr);
  grid-gap: 8px;

  @media (max-width: 768px) {
    display: flex;
    margin-top: 32px;
    background-color: white;
    border-radius: 8px;
    padding: 16px;
    grid-gap: 6px;
    align-items: end;
  }
`;

const Stepper = ({activeStep, currentChallenge}) => {
  const [challenges, setChallenges] = useState(STEPS);

  useEffect(() => { 
    if (currentChallenge === 'memory-matrix') { 
      STEPS.map((step, index) => {
        if (index === 0) {
          step.showActiveClass = true
        }
        return step
      })
    } else if (currentChallenge === 'speed-arithmetic') {
      STEPS.map((step, index) => {
        if (index === 0 || index === 1) {
          step.showActiveClass = true
        }
        return step
      })
    } else if (currentChallenge === 'assessment'){
      STEPS.map((step) => step.showActiveClass = true)
    }

    setChallenges(STEPS)

  }, [currentChallenge])
  return (
    <Container>
      {challenges.map((step, index) => (
        <StepComponent
          key={step.id}
          title={step.title}
          color={theme.forestGreen}
          showActiveClass={step.showActiveClass}
          // completedStep={index === 1}
          activeStep={index === activeStep }
          orderNo={index + 1}
        />
      ))}
    </Container>
  );
};

export default Stepper;
