import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import ContentWrapper from "../../../../Components/ContentWrapper/ContentWrapper";
import group1 from "./Static/group-1.png";
import group2 from "./Static/group-2.png";
import group3 from "./Static/group-3.png";
import group4 from "./Static/group-4.png";
import { Link } from "react-router-dom";

const StyledContainer = styled.section`
    padding-bottom: 120px;
    font-family: Inter;

    .heading-2 {
        color: #005840;
        font-family: Inter;
        font-size: 40px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 40px;
        text-transform: uppercase;

        & > br {
            display: none;
        }
    }

    .wrapper {
        display: flex;
        gap: 16px;
        margin: 0 auto;
        max-width: 1312px;

        .item {
            position: relative;
            flex: 0 0 auto;
        }

        .item-content {
            position: absolute;
            bottom: 24px;
            left: 24px;

            .label {
                margin-bottom: 8px;
                font-size: 14px;
            }

            .text {
                font-size: 20px;
                font-weight: 500;
                line-height: 24px;
            }
        }

        .item-1 .label {
            color: rgba(255, 209, 228, 0.8);
        }

        .item-1 .text {
            color: #ffb3d3;
        }

        .item-2 .label {
            color: rgba(205, 245, 69, 0.5);
        }

        .item-2 .text {
            color: #cdf545;
        }

        .item-3 .label {
            color: rgba(255, 209, 228, 0.8);
        }

        .item-3 .text {
            color: #ffb3d3;

            div:first-child {
                margin-bottom: 16px;
            }

            span {
                font-size: 14px;
            }
        }

        .item-4 .label {
            color: rgba(205, 245, 69, 0.5);
        }

        .item-4 .text {
            color: #cdf545;
            font-size: 20px;
            font-weight: 700;
        }
    }

    .details-paragraph {
        text-align: center;
        margin-top: 64px;
        color: #aaa79d;
        font-size: 18px;

        a {
            color: #005840;
            text-decoration: underline;
        }

        br {
            display: none;
        }
    }

    .follow-us-paragraph {
        font-size: 16px;
        color: #000;
        text-align: center;
        margin-top: 100px;
    }

    @media (max-width: 1310px) {
        .wrapper {
            overflow-x: auto;
            padding-left: 14px;
            padding-right: 14px;
            scroll-behavior: smooth;
            -webkit-overflow-scrolling: touch;

            scrollbar-width: none;
            -ms-overflow-style: none;

            &::-webkit-scrollbar {
                display: none;
            }

            .item {
                cursor: pointer;
            }
        }
    }

    @media (max-width: 767px) {
        padding-top: 64px;
        padding-bottom: 20px;

        .heading-2 {
            font-size: 30px;
        }

        .details-paragraph {
            font-size: 20px;
            line-height: 24px;
            margin-top: 48px;

            br {
                display: block;
            }
        }

        .follow-us-paragraph {
            display: none;
        }
    }
`;

const CompetitionInfo = (props) => {

    const scrollToSectionRef = useRef(null);
    const offset = 20;

    useEffect(() => {
        if (props.scrollToCompetitionInfoSection) {
            const topPosition =
                scrollToSectionRef?.current?.getBoundingClientRect()?.top +
                window.scrollY -
                offset;
            window.scrollTo({ top: topPosition, behavior: "smooth" });
            props.resetButton();
        }
    }, [props.scrollToCompetitionInfoSection]);

    return (
        <StyledContainer>
            <div>
                <div ref={scrollToSectionRef}>
                    <ContentWrapper>
                        <h2 className="heading-2">ЗА НАТПРЕВАРОТ</h2>
                    </ContentWrapper>

                    <div className="wrapper">
                        <div className="item item-1">
                            <img src={group1} alt="for" />
                            <div className="item-content">
                                <div className="label">ЗА КОГО</div>
                                <div className="text">
                                    Сите средношколци <br />
                                    од 1-4 година
                                </div>
                            </div>
                        </div>
                        <div className="item item-2">
                            <img src={group2} alt="how" />
                            <div className="item-content">
                                <div className="label">KAKO</div>
                                <div className="text">
                                    Пријави се БЕСПЛАТНО!
                                </div>
                            </div>
                        </div>
                        <div className="item item-3">
                            <img src={group3} alt="phase" />
                            <div className="item-content">
                                <div className="label">ФАЗИ</div>
                                <div className="text">
                                    <div>
                                        Квалификации <span>[online]</span>{" "}
                                        <br /> до 26.11, 23:59ч
                                    </div>
                                    <div>
                                        Главен натпревар <span>[во живо]</span> <br />{" "}
                                        30.11 и 01.12
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item item-4">
                            <img src={group4} alt="reward" />
                            <div className="item-content">
                                <div className="label">НАГРАДА</div>
                                <div className="text">
                                    само 1 победник <br />
                                    10.000€
                                </div>
                            </div>
                        </div>
                    </div>

                    <ContentWrapper>
                        <p className="details-paragraph">
                            Детални правила <br /> за натпреварот{" "}
                            <Link to="/rules">на овој линк</Link>
                        </p>
                    </ContentWrapper>
                </div>
            </div>
        </StyledContainer>
    );
};

export default CompetitionInfo;
