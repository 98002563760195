import React from "react";
import styled from "styled-components";

const Step = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media (max-width: 768px) {
    width: ${(props) => (props.activeStep ? "60%" : "20%")};
    height: 100%;
  }
`;

const StepIndicator = styled.div`
  width: 100%;
  height: 4px;
  background: rgba(0, 88, 64, 0.2);
  margin-top: 4px;

  &.active {
    background: #005840;
  }
`;

const Tile = styled.p`
  color: ${(props) => props.color};
  font-family: Inter;
  font-size: 13px;
  line-height: 16px; /* 123.077% */

  span.order-number {
    display: none;
  }

  @media (max-width: 768px) {
    font-weight: 500;

    span.order-number {
      display: inline;
    }
    span.title {
      display: ${(props) => (props.activeStep ? "inline" : "none")};
    }
  }
`;

const StepComponent = ({
  title,
  color,
  showActiveClass,
  // completedStep,
  activeStep,
  orderNo,
}) => {

  return (
    <Step activeStep={activeStep} className={showActiveClass ? "active" : ""}>
      <Tile color={color} activeStep={activeStep}>
        <span className="order-number">{orderNo}.</span>
        <span className="title">{title}</span>
      </Tile>
      <StepIndicator
        bgColor={color}
        className={showActiveClass ? "active" : ""}
      ></StepIndicator>
    </Step>
  );
};

export default StepComponent;
