export const ellipseRed = <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
<g filter="url(#filter0_d_69_4249)">
  <circle cx="14" cy="14" r="4" fill="#FFB3D3"/>
</g>
<defs>
  <filter id="filter0_d_69_4249" x="0" y="0" width="28" height="28" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset/>
    <feGaussianBlur stdDeviation="5"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.701961 0 0 0 0 0.827451 0 0 0 1 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_69_4249"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_69_4249" result="shape"/>
  </filter>
</defs>
</svg>