export const nextLogo = <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
<circle cx="40" cy="40" r="40" fill="#2E2F30"/>
<path d="M15.2972 40.3625C15.2508 40.3345 15.2508 40.2878 15.2879 40.2598C15.5657 40.0637 15.7417 39.7742 15.7417 39.354C15.7417 38.6256 15.2416 38.084 14.2598 38.084H12.1389C12.0648 38.084 12 38.1494 12 38.2241V42.5383C12 42.613 12.0648 42.6784 12.1389 42.6784H14.4636C15.3898 42.6784 15.9455 42.1461 15.9455 41.3804C15.9455 40.8854 15.6954 40.5493 15.2972 40.3625ZM13.167 39.2699C13.167 39.2046 13.2225 39.1485 13.2874 39.1485H14.195C14.4358 39.1485 14.5655 39.298 14.5655 39.5127C14.5655 39.7275 14.4451 39.8769 14.1302 39.8769H13.2874C13.2225 39.8769 13.167 39.8209 13.167 39.7555V39.2699ZM14.3339 41.6138H13.2874C13.2225 41.6138 13.167 41.5578 13.167 41.4924V40.9228C13.167 40.8574 13.2225 40.8014 13.2874 40.8014H14.3432C14.6581 40.8014 14.7785 40.9602 14.7785 41.1936C14.7692 41.4364 14.6488 41.6138 14.3339 41.6138Z" fill="white"/>
<path d="M19.8448 41.3897C19.8078 41.3337 19.8171 41.2683 19.8819 41.2403C20.4283 40.9415 20.6784 40.3718 20.6784 39.7649C20.6784 38.8497 20.1412 38.084 18.9742 38.084H16.8811C16.807 38.084 16.7422 38.1494 16.7422 38.2241V42.5383C16.7422 42.613 16.807 42.6784 16.8811 42.6784H17.761C17.8351 42.6784 17.8999 42.613 17.8999 42.5383V41.5671C17.8999 41.5018 17.9555 41.4457 18.0203 41.4457H18.3815C18.4556 41.4457 18.5297 41.4831 18.5667 41.5391L19.2799 42.557C19.3355 42.6317 19.4188 42.6784 19.5022 42.6784H20.6136C20.6691 42.6784 20.7062 42.613 20.6691 42.557L19.8448 41.3897ZM18.9094 40.3812H18.0296C17.9647 40.3812 17.9092 40.3252 17.9092 40.2598V39.2699C17.9092 39.2046 17.9647 39.1485 18.0296 39.1485H18.9094C19.3725 39.1485 19.5485 39.41 19.5485 39.7649C19.5485 40.0917 19.3725 40.3812 18.9094 40.3812Z" fill="white"/>
<path d="M24.1325 38.2147C24.1047 38.14 24.0214 38.084 23.938 38.084H23.0582C22.9748 38.084 22.8915 38.14 22.8637 38.2147L21.1225 42.5476C21.0947 42.6037 21.141 42.6784 21.2058 42.6784H22.1968C22.2802 42.6784 22.3543 42.6224 22.3821 42.5476L22.5488 42.1181C22.5766 42.0527 22.6321 42.006 22.7062 42.006H24.2715C24.3455 42.006 24.4104 42.0527 24.4289 42.1181L24.5956 42.5476C24.6234 42.6224 24.6975 42.6784 24.7808 42.6784H25.7718C25.8459 42.6784 25.883 42.6037 25.8552 42.5476L24.1325 38.2147ZM23.8732 40.9415H23.1045C23.0489 40.9415 23.0119 40.8854 23.0304 40.8294L23.4472 39.7088C23.4564 39.6715 23.5213 39.6715 23.5305 39.7088L23.9473 40.8294C23.9751 40.8761 23.938 40.9415 23.8732 40.9415Z" fill="white"/>
<path d="M27.5876 38.084H26.6985C26.6244 38.084 26.5596 38.1494 26.5596 38.2241V42.5383C26.5596 42.613 26.6244 42.6784 26.6985 42.6784H27.5876C27.6617 42.6784 27.7265 42.613 27.7265 42.5383V38.2241C27.7265 38.1494 27.6617 38.084 27.5876 38.084Z" fill="white"/>
<path d="M32.7656 38.084H31.8858C31.8117 38.084 31.7468 38.1494 31.7468 38.2241V40.5306C31.7468 40.5679 31.7005 40.5866 31.6727 40.5586L29.8019 38.196C29.7463 38.1213 29.663 38.084 29.5703 38.084H28.8665C28.7924 38.084 28.7275 38.1494 28.7275 38.2241V42.5383C28.7275 42.613 28.7924 42.6784 28.8665 42.6784H29.7463C29.8204 42.6784 29.8852 42.613 29.8852 42.5383V40.2318C29.8852 40.1944 29.9316 40.1757 29.9593 40.2038L31.8395 42.557C31.895 42.6317 31.9784 42.669 32.071 42.669H32.7749C32.849 42.669 32.9138 42.6037 32.9138 42.529V38.2241C32.9045 38.1494 32.8397 38.084 32.7656 38.084Z" fill="white"/>
<path d="M35.7571 39.7929C35.2107 39.6062 35.0069 39.5128 35.0069 39.326C35.0069 39.1766 35.1644 39.0179 35.5626 39.0179C35.9516 39.0179 36.1739 39.1953 36.2387 39.3914C36.2758 39.4941 36.3221 39.5501 36.4332 39.5501H37.2483C37.3316 39.5501 37.4057 39.4661 37.3965 39.3821C37.3409 38.5603 36.5999 38 35.5349 38C34.4975 38 33.7566 38.551 33.7566 39.4287C33.7566 40.3812 34.7106 40.7268 35.433 40.9695C35.9794 41.1563 36.211 41.2777 36.211 41.4738C36.211 41.6139 36.072 41.754 35.6367 41.754C35.2292 41.754 34.9884 41.6139 34.9051 41.4551C34.831 41.3337 34.8032 41.3057 34.6735 41.3057H33.8863C33.8029 41.3057 33.7288 41.3898 33.7381 41.4738C33.8214 42.3142 34.6365 42.7718 35.6645 42.7718C36.7018 42.7718 37.4798 42.3049 37.4798 41.4084C37.4613 40.3906 36.3869 40.0171 35.7571 39.7929Z" fill="white"/>
<path d="M41.8582 38.084H38.135C38.0609 38.084 37.9961 38.1494 37.9961 38.2241V39.0832C37.9961 39.1579 38.0609 39.2232 38.135 39.2232H39.302C39.3668 39.2232 39.4224 39.2793 39.4224 39.3446V42.5383C39.4224 42.613 39.4872 42.6784 39.5613 42.6784H40.4504C40.5245 42.6784 40.5894 42.613 40.5894 42.5383V39.3446C40.5894 39.2793 40.6449 39.2232 40.7098 39.2232H41.8767C41.9508 39.2232 42.0157 39.1579 42.0157 39.0832V38.2241C42.0064 38.1494 41.9416 38.084 41.8582 38.084Z" fill="white"/>
<path d="M46.0739 38.084H42.8508C42.7767 38.084 42.7119 38.1494 42.7119 38.2241V42.5383C42.7119 42.613 42.7767 42.6784 42.8508 42.6784H46.0739C46.148 42.6784 46.2128 42.613 46.2128 42.5383V41.6885C46.2128 41.6138 46.148 41.5485 46.0739 41.5485H43.9993C43.9345 41.5485 43.8789 41.4924 43.8789 41.4271V41.0722C43.8789 41.0068 43.9345 40.9508 43.9993 40.9508H45.6571C45.7312 40.9508 45.796 40.8854 45.796 40.8107V40.0263C45.796 39.9516 45.7312 39.8863 45.6571 39.8863H43.9993C43.9345 39.8863 43.8789 39.8302 43.8789 39.7649V39.3446C43.8789 39.2793 43.9345 39.2232 43.9993 39.2232H46.0739C46.148 39.2232 46.2128 39.1579 46.2128 39.0832V38.2241C46.2128 38.1494 46.148 38.084 46.0739 38.084Z" fill="white"/>
<path d="M51.0109 42.557L50.1774 41.3804C50.1403 41.3244 50.1496 41.259 50.2144 41.231C50.7609 40.9322 51.0109 40.3626 51.0109 39.7556C51.0109 38.8404 50.4738 38.0747 49.3068 38.0747H47.2229C47.1488 38.0747 47.084 38.1401 47.084 38.2148V42.529C47.084 42.6037 47.1488 42.6691 47.2229 42.6691H48.1028C48.1769 42.6691 48.2417 42.6037 48.2417 42.529V41.5579C48.2417 41.4925 48.2973 41.4365 48.3621 41.4365H48.7233C48.7974 41.4365 48.8715 41.4738 48.9085 41.5298L49.6217 42.5477C49.6773 42.6224 49.7606 42.6691 49.844 42.6691H50.9554C51.0109 42.6784 51.048 42.6131 51.0109 42.557ZM49.242 40.3812H48.3528C48.288 40.3812 48.2324 40.3252 48.2324 40.2598V39.27C48.2324 39.2046 48.288 39.1486 48.3528 39.1486H49.2327C49.6958 39.1486 49.8717 39.4101 49.8717 39.7649C49.881 40.0918 49.705 40.3812 49.242 40.3812Z" fill="white"/>
<path d="M55.7472 38.1027V42.6971H54.7932L52.5982 38.3268V42.6971H52.0518V38.1027H53.0057L55.2007 42.473V38.1027H55.7472Z" fill="white"/>
<path d="M57.2295 38.5696V40.0637H59.4523V40.5307H57.2295V42.2302H59.7116V42.6971H56.6553V38.1027H59.7116V38.5696H57.2295Z" fill="white"/>
<path d="M62.7312 40.3252L64.1205 42.6878H63.4814L62.2311 40.4746L60.9067 42.6878H60.3047L61.731 40.3252L60.3695 38.0934H61.0086L62.2404 40.1758L63.4537 38.0934H64.0742L62.7312 40.3252Z" fill="white"/>
<path d="M68.1217 38.5976H66.6954V42.6878H66.1211V38.5976H64.7041V38.1027H68.1217V38.5976Z" fill="white"/>
</svg>