import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";

//npm imports
import styled, { withTheme } from "styled-components/macro";
import { useParams, useNavigate, Link } from "react-router-dom";

//component imports
import { useTranslation } from "react-i18next";
import InputFieldLight from "../../Components/InputFieldLight/InputFieldLight";
import SelectInput from "../../Components/SelectInput/SelectInput";
import {
  Caption12,
  HeaderMedium28,
  SpecialHeader36,
  Text18,
} from "../../Components/Text/Text";
import ButtonApply, {
  StyledButtonApply,
} from "../../Components/ButtonApply/ButtonApply";
import Checkbox from "../../Components/Checkbox/Checkbox";

import BrainsterNextLogo from "../../Components/BrainsterNextLogo/BrainsterNextLogo";
import ArrowBack from "../../Components/ArrowBack/ArrowBack";

//hooks
import useArrayLength from "../../Hooks/useArrayLength";

//other imports
import { GlobalContext } from "../../Context/GlobalContext";
import { API } from "../../Consts/Api";

import application_form_background from "./Static/application-form-background.png";
import application_form_background_mobile from "./Static/application-form-background-mobile.png";
import ButtonBasic from "../../Components/ButtonBasic/ButtonBasic";

import { bgVector1 } from "./Static/bg-vector-1";
import { bgVector2 } from "./Static/bg-vector-2";
import { rightArrowVector } from "./Static/right-arrow-vector";
import { ultimathLogoVector } from "./Static/ultimath-logo-vector";
import ButtonWide from "../../Components/ButtonWide/ButtonWide";
import theme from "../../Consts/Theme";

const ApplicationFormWrapper = styled.section`
  display: flex;
  min-height: 100vh;
  position: relative;

  & > aside {
    width: 42.4%;
    position: relative;
    background-color: #005840;

    & > .bg-vector-1 {
      position: absolute;
      top: 0;
      left: 0;
      width: 242px;
    }

    & > .bg-vector-2 {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 283px;
    }

    & > .ultimath-logo-vector {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 280px;
    }

    & > .logo-and-arrow-container {
      & > .brainster-logo {
        margin-left: 15%;
        margin-top: 60px;
      }

      .arrow-back {
        display: none;
      }
    }

    .header-container {
      width: 60%;
      position: absolute;
      top: 50%;
      margin-left: 15%;
      transform: translateY(-50%);
      display: block;
      p {
        text-align: center;
        margin-bottom: 12px;
      }
      svg {
        width: 100%;
      }
    }
  }

  .start-popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(15px);
    display: ${(props) => (props.showPopup ? "flex" : "none")};
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .start-popup {
      max-width: 793px;
      padding: 84px;
      background-color: ${(props) => props.theme.white};
      border-radius: 8px;
      position: relative;
      overflow-y: auto;

      .exit-popup-button {
        border: 1px solid transparent;
        border-bottom-color: ${(props) => props.theme.gray2};
        color: ${(props) => props.theme.gray2};
        font-size: 14px;
        line-height: 22px;
        background: transparent;
        padding: 0;
        margin-right: 40px;

        &:hover {
          cursor: pointer;
        }
      }

      .start-popup-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h1 {
          text-align: center;
          color: #161616;
          font-family: Inter;
          font-size: 32px;
          font-weight: 700;
          margin-bottom: 16px;
        }

        p {
          color: #000;
          text-align: center;
          font-family: Inter;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          margin-bottom: 24px;
        }

        ol li {
          margin-bottom: 16px;
          color: #737373;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }

        header {
          display: flex;
          align-items: center;
          align-self: flex-start;
          margin-left: 30px;

          & > svg {
            margin-top: -40px;
            margin-left: -100px;
            z-index: 9;
          }
        }

        & > div.buttons-container {
          align-self: center;

          button {
            border-radius: 6px;
            background: #005840;
            color: #cdf545;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px; /* 114.286% */
            text-transform: uppercase;
            padding: 16px 40px;
            cursor: pointer;
            border: none;
            outline: none;
          }
        }

        & > p {
          margin: 16px 0px 40px 0px;
          color: ${(props) => props.theme.gray2};
        }
      }

      img {
        position: absolute;
        bottom: -2px;
        left: -20px;
      }
    }
  }

  & > main {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 57.6%;
    background-color: #f4f1ea;
  }

  @media (max-width: 825px) {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: ${(props) => props.theme.veryLightGray};
    .start-popup-container {
      .start-popup {
        padding: 24px;

        .start-popup-content {
          h1 {
            font-size: 26px;
          }
        }
      }
    }

    & > aside {
      width: 100%;
      height: 290px;
      background-color: #005840;

      .bg-vector-1 {
        width: 150px;
      }

      .bg-vector-2 {
        width: 150px;
      }

      .logo-and-arrow-container {
        width: 82%;
        margin: 32px auto;
      }

      .header-container {
        left: 0%;
        transform: translate(0%, -50%);
        margin-left: 0;
        z-index: 10;
      }

      & > .logo-and-arrow-container {
        display: flex;
        justify-content: space-between;
        .arrow-back {
          display: block;
          &:hover {
            cursor: pointer;
          }
        }
        & > .brainster-logo {
          margin: 0;
        }
      }
    }

    & > main {
      width: 100%;
      min-height: 75vh;
      form {
        ${HeaderMedium28} {
          font-size: 24px;
          margin-bottom: 40px;
        }
      }
    }
  }

  @media (max-width: 550px) {
    & > aside {
      .header-container-mobile,
      .logo-and-arrow-container {
        width: 92%;
      }
    }

    & > main {
      align-items: flex-start;
    }
  }
`;

const StyledApplicationForm = styled.form`
  padding-top: 35px;
  width: 64.4%;

  ${HeaderMedium28} {
    width: 82%;
    margin-bottom: 60px;
  }

  .birth-date-container {
    .birthDateInputsContainer {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 16px;
    }
  }

  .name-inputs-container,
  .email-and-phone-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }

  .email-and-phone-container {
    margin-bottom: 36px;
  }

  ${Caption12}.error {
    color: ${(props) => props.theme.textError};
    height: 14px;
    margin-top: 8px;
  }

  & > ${Caption12} {
    margin-bottom: 16px;
  }

  ${StyledButtonApply} {
    margin-top: 32px;
    margin-bottom: 70px;
  }

  .input-group {
    display: flex;
    gap: 16px;

    & > * {
      width: 50%;
    }

    & > .highschool-container {
      width: 75%;
    }

    /* & > .year-container {
      width: 25%;
    } */
  }

  @media (max-width: 1250px) {
    width: 78%;
  }

  @media (max-width: 1250px) {
    width: 82%;
  }

  @media (max-width: 1015px) {
    ${HeaderMedium28} {
      width: 100%;
    }
  }

  @media (max-width: 550px) {
    width: 92%;
    .birth-date-container {
      .birthDateInputsContainer {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 16px;
        .select-input__single-value {
          padding-left: 4px;
        }
        .select-input__value-container {
          padding: 2px 4px;
        }
        .select-input__indicators {
          margin-right: 8px;
          .select-input__indicator {
            padding: 0;
          }
          svg {
            transform: scale(0.99);
          }
        }
      }
    }
    .name-inputs-container,
    .email-and-phone-container {
      grid-template-columns: 1fr;
      gap: 0px;
    }
    .name-inputs-container,
    .birth-date-container {
      margin-bottom: 0px;
      .css-1hb7zxy-IndicatorsContainer {
        width: 25px;
      }
    }
    .email-and-phone-container {
      margin-bottom: 16px;
    }
    .error {
    }
    ${StyledButtonApply} {
      margin-top: 24px;
      margin-bottom: 40px;
    }
  }
`;
const SelectFieldWrapper = styled.div`
  width: 100%;
  margin-bottom: 32px;

  .label {
    margin-bottom: 6px;
  }

  .error {
    height: 14px;
    margin-top: 8px;
  }
`;
const SelectContainer = styled.aside`
  width: 100%;
  position: relative;
`;
const Select = styled.select`
  width: 100%;
  padding: 20px 16px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url("/arrow-down.svg") no-repeat right 8px center;
  background-size: 12px;
  font-family: "Inter", sans-serif;
  font-size: ${(props) => props.theme.text14}px;
  line-height: ${(props) => props.theme.text16LineHeight}px;
  font-weight: 400;
  color: ${(props) =>
    props.errors?.length > 0
      ? props.theme.textError
      : props.val !== ""
      ? props.theme.black
      : "#A49E92"};
  border-radius: 8px;
  border: 1px solid
    ${(props) =>
      props.errors?.length > 0
        ? props.theme.textError
        : props.val !== ""
        ? "#D5CFC3"
        : "#D5CFC3"};
  background-color: ${(props) =>
    props.inputBackground ? props.inputBackground : "#FBFAF6"};
  outline: none;

  &:focus,
  &:active,
  &:target {
    outline: none;
  }

  &:focus {
    color: ${(props) => props.theme.black};
  }

  ::placeholder {
    color: #a49e92;
  }

  @media (max-width: 550px) {
    //
  }
`;
const HIGHSCHOOL_YEARS = [
  {
    value: 1,
    label: "Прва година",
  },
  {
    value: 2,
    label: "Втора година",
  },
  {
    value: 3,
    label: "Трета година",
  },
  {
    value: 4,
    label: "Четврта година",
  },
];
const ApplicationFormNew = (props) => {
  const { t } = useTranslation();
  const {
    userInfo,
    academies,
    setUserInfo,
    termsAgreement,
    setTermsAgreement,
    showAllRulesPopup,
    setShowAllRulesPopup,
    checkIfClickedOutside,
  } = useContext(GlobalContext);

  //The birthDate is handled manually becuase it consists of 3 different fields and the
  //element #birthDateError displays the errors if there are any
  const resetErrors = {
    name: [],
    surname: [],
    phone: [],
    email: [],
    highschool: [],
    birthDate: [],
    academyId: [],
    no_term: [],
    limit_exceeded: [],
    highschool_year: [],
  };

  const [errors, setErrors] = useState(resetErrors);

  const popupRef = useRef();

  const [showPopup, setShowPopup] = useState(false);

  const nameInputRef = useRef();
  const surnameInputRef = useRef();
  const emailInputRef = useRef();
  const phoneInputRef = useRef();
  const highschoolInputRef = useRef();
  const highschoolYearInputRef = useRef();
  //needed for select inputs as options and for updating userInfo.birthDate
  const [days, setDays] = useState([
    { value: null, label: t("application_form.form.birth_date.day") },
  ]);

  const [selectedDay, setSelectedDay] = useState(days[0]);

  const [months, setMonths] = useState([
    { value: null, label: t("application_form.form.birth_date.month") },
  ]);

  const [selectedMonth, setSelectedMonth] = useState(months[0]);
  const [years, setYears] = useState([
    { value: null, label: t("application_form.form.birth_date.year") },
  ]);

  const formRef = useRef();

  let params = useParams();
  let navigate = useNavigate();

  const allMonths = useMemo(
    () => [
      t("application_form.form.birth_date.all_months.jan"),
      t("application_form.form.birth_date.all_months.feb"),
      t("application_form.form.birth_date.all_months.mar"),
      t("application_form.form.birth_date.all_months.apr"),
      t("application_form.form.birth_date.all_months.may"),
      t("application_form.form.birth_date.all_months.jun"),
      t("application_form.form.birth_date.all_months.jul"),
      t("application_form.form.birth_date.all_months.aug"),
      t("application_form.form.birth_date.all_months.sep"),
      t("application_form.form.birth_date.all_months.oct"),
      t("application_form.form.birth_date.all_months.nov"),
      t("application_form.form.birth_date.all_months.dec"),
    ],
    [t]
  );

  useEffect(() => {
    let days = [
      { value: null, label: t("application_form.form.birth_date.day") },
    ];
    for (let i = 0; i < 31; i++) {
      days.push({ value: i + 1, label: i + 1 });
    }
    let mnths = [
      { value: null, label: t("application_form.form.birth_date.month") },
    ];
    allMonths.forEach((month, index) => {
      mnths.push({ value: index + 1, label: month });
    });
    let year = new Date();
    let years = [
      { value: null, label: t("application_form.form.birth_date.year") },
    ];
    for (let i = year.getFullYear() - 17; i > 1999; i--) {
      years.push({ value: i, label: i });
    }
    setDays(days);
    setMonths(mnths);
    setYears(years);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setUserInfo({
      ...userInfo,
      birthDate: { ...userInfo.birthDate, day: selectedDay.value },
    });
    //eslint-disable-next-line
  }, [selectedDay.value, setUserInfo]);
  useEffect(() => {
    setUserInfo({
      ...userInfo,
      birthDate: { ...userInfo.birthDate, month: selectedMonth.value },
    });
    //eslint-disable-next-line
  }, [selectedMonth.value, setUserInfo]);

  const applyStudent = async (e, userInfo) => {
    e.preventDefault();

    const queryString = localStorage.getItem("queryString");

    if (termsAgreement) {
      let user = {
        name: userInfo.name,
        highschool: userInfo.highschool,
        highschool_year: userInfo.highschool_year,
        email: userInfo.email,
        phone: userInfo.phone,
        query_string: queryString || null,
        referred_by: params.referralGuid || userInfo.referred_by || null,
        academy_id: "1",
      };

      API.axios
        .post(API.createApiRoute("user"), user)
        .then((response) => {
          if (response.data.success) {
            if (response.data.data.guid) {
              setUserInfo({
                ...userInfo,
                guid: response.data.data.guid,
              });
              setShowPopup(true);
              formRef.current.reset();
              localStorage.setItem("guid", response.data.data.guid);
              localStorage.removeItem("queryString");

            }
          } else {
            setErrors({
              ...errors,
              no_term: [response.data.message],
            });
          }
        })
        .catch((error) => {
          if (error?.response?.status === 422) {
            setErrors({
              ...resetErrors,
              ...error.response.data.errors,
              limit_exceeded: error.response.data.errors?.additional || [],
              birthDate: [
                error.response.data.errors["birth_date.day"] ||
                  error.response.data.errors["birth_date.month"] ||
                  error.response.data.errors["birth_date.year"],
              ],
            });
          } else {
            setErrors({
              ...errors,
              ...error.response?.data.errors?.additional,
            });
          }
        });
    } else {
      setErrors({ ...errors, terms: t("errors.terms") });
    }
  };

  const onCloseGetReadyModal = () => {
     setShowPopup(false);
     localStorage.setItem("memoryMatrixStatus", "not-started");
     navigate(API.createRoute("memoryGame"));
    
  };

  useEffect(() => {
    errors.terms && termsAgreement && setErrors({ ...errors, terms: false });
    //eslint-disable-next-line
  }, [errors.terms, termsAgreement]);

  useEffect(() => {
    !userInfo.academyId &&
      params.academyId &&
      setUserInfo({ ...userInfo, academy_id: params.academyId });
    //eslint-disable-next-line
  }, [params, userInfo.academyId, setUserInfo]);

  //if there is data about the user in backend already
  //repopulate inputs with that data
  useEffect(() => {
    if (
      userInfo.name !== "" &&
      userInfo.name !== undefined &&
      nameInputRef.current
    ) {
      nameInputRef.current.value = userInfo.name;
    }
    if (
      userInfo.email !== "" &&
      userInfo.email !== undefined &&
      nameInputRef.current
    ) {
      emailInputRef.current.value = userInfo.email;
    }
    if (
      userInfo.highschool !== "" &&
      userInfo.highschool !== undefined &&
      nameInputRef.current
    ) {
      highschoolInputRef.current.value = userInfo.highschool;
    }
    if (userInfo.birthDate?.day) {
      let days = [
        {
          value: null,
          label: t("application_form.form.birth_date.day"),
        },
      ];
      for (let i = 0; i < 31; i++) {
        days.push({ value: i + 1, label: i + 1 });
      }
      days.forEach(
        (day) => day.value === userInfo.birthDate.day && setSelectedDay(day)
      );
    }
    if (userInfo.birthDate?.day) {
      let mnths = [
        {
          value: null,
          label: t("application_form.form.birth_date.month"),
        },
      ];
      allMonths.forEach((month, index) => {
        mnths.push({ value: index + 1, label: month });
      });

      mnths.forEach(
        (month) =>
          month.value === userInfo.birthDate.month && setSelectedMonth(month)
      );
    }
    // if (userInfo.birthDate?.year) {
    //   let year = new Date();
    //   let years = [
    //     {
    //       value: null,
    //       label: t("application_form.form.birth_date.year"),
    //     },
    //   ];
    //   for (let i = year.getFullYear() - 13; i > 1940; i--) {
    //     years.push({ value: i, label: i });
    //   }

    //   years.forEach(
    //     (year) =>
    //       year.value === userInfo.birthDate.year && SetSelectedYear(year)
    //   );
    // }

    if (
      userInfo.phone !== "" &&
      userInfo.phone !== undefined &&
      phoneInputRef.current
    ) {
      phoneInputRef.current.value = userInfo.phone;
    }
  }, [userInfo.name, userInfo.email, userInfo.phone, userInfo.highschool]);

  // CLEAR ERROR if change is made on input
  useEffect(() => {
    errors.name.length > 0 && setErrors({ ...errors, name: [] });
    //eslint-disable-next-line
  }, [userInfo.name]);

  useEffect(() => {
    errors.email.length > 0 && setErrors({ ...errors, email: [] });
    //eslint-disable-next-line
  }, [userInfo.email]);

  useEffect(() => {
    errors.highschool.length > 0 && setErrors({ ...errors, highschool: [] });
    //eslint-disable-next-line
  }, [userInfo.highschool]);

  useEffect(() => {
    errors.phone.length > 0 && setErrors({ ...errors, phone: [] });
    //eslint-disable-next-line
  }, [userInfo.phone]);

  useEffect(() => {
    errors.highschool_year.length > 0 &&
      setErrors({ ...errors, highschool_year: [] });
    //eslint-disable-next-line
  }, [userInfo.highschool_year]);

  const submitOnEnter = useCallback(
    (e) => {
      if (e.keyCode === 13) {
        applyStudent(e, userInfo);
      }
      //eslint-disable-next-line
    },
    [applyStudent]
  );

  useEffect(() => {
    window.addEventListener("keydown", submitOnEnter);

    return () => {
      window.removeEventListener("keydown", submitOnEnter);
    };
  }, [submitOnEnter]);

  return (
    <ApplicationFormWrapper showPopup={showPopup}>
      <aside>
        {bgVector1}
        {bgVector2}
        {ultimathLogoVector}

        <div className="logo-and-arrow-container">
          {/* <Link to='/'>
                        <ArrowBack color={props.theme.white} />
                    </Link> */}
        </div>
      </aside>
      <main>
        <StyledApplicationForm ref={formRef} onSubmit={(e) => applyStudent(e, userInfo)}>
          <HeaderMedium28 color={props.theme.black}>
            Вклучи се во натпреварот
          </HeaderMedium28>

          <div className="name-container">
            <InputFieldLight
              type="text"
              required
              name="name"
              ref={nameInputRef}
              onChange={() => {
                setUserInfo({
                  ...userInfo,
                  name: nameInputRef.current.value,
                });
              }}
              autocomplete="name"
              label="Име и презиме *"
              placeholder="Внеси име и презиме"
              value={nameInputRef.current?.value}
              className="name-input-container"
              errors={errors.name}
            />
          </div>

          <div className="input-group">
            <div className="highschool-container">
              <InputFieldLight
                type="text"
                required
                name="highschool"
                ref={highschoolInputRef}
                onChange={() =>
                  setUserInfo({
                    ...userInfo,
                    highschool: highschoolInputRef.current.value,
                  })
                }
                label="Средно училиште *"
                placeholder="Внеси средно училиште"
                value={highschoolInputRef.current?.value}
                className="highschool-input-container"
                errors={errors.highschool}
              />
            </div>

            <div className="year-container">
              <SelectFieldWrapper>
                <SelectContainer>
                  <Caption12 className="label" color={theme.black}>
                    Година *
                  </Caption12>
                  <Select
                    id="options"
                    className="year-input-container"
                    name="highschool_year"
                    defaultValue="-1"
                    value={highschoolYearInputRef.current?.value}
                    errors={errors.highschool_year}
                    onChange={() =>
                      setUserInfo({
                        ...userInfo,
                        highschool_year: highschoolYearInputRef?.current?.value,
                      })
                    }
                    ref={highschoolYearInputRef}
                  >
                    <option value="-1" disabled>
                      -- Одбери--{" "}
                    </option>
                    {HIGHSCHOOL_YEARS.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Select>


                </SelectContainer>

                <Caption12 className="error" color={theme.textError}>
                    {errors.highschool_year?.length > 0 &&
                        errors.highschool_year.map((error, index) => (
                            <span key={index}>
                                <span>{error}</span>
                                <br />
                            </span>
                        ))}
                </Caption12>

              </SelectFieldWrapper>
            </div>
          </div>

          <div className="input-group">
            <div className="email-container">
              <InputFieldLight
                type="email"
                required
                name="email"
                ref={emailInputRef}
                onChange={() =>
                  setUserInfo({
                    ...userInfo,
                    email: emailInputRef.current.value,
                  })
                }
                autocomplete="email"
                label="E-mail *"
                placeholder="example@example.com"
                value={emailInputRef.current?.value}
                className="email-input-container"
                errors={errors.email}
              />
            </div>

            <div>
              <InputFieldLight
                required
                type="tel"
                name="tel"
                ref={phoneInputRef}
                onChange={() =>
                  setUserInfo({
                    ...userInfo,
                    phone: phoneInputRef.current.value,
                  })
                }
                autocomplete="tel"
                label="Телефонски број *"
                placeholder="07XXXXXXX"
                value={phoneInputRef.current?.value}
                className="phone-input-container"
                errors={errors.phone}
              />
            </div>
          </div>

          <Caption12 color={props.theme.textError}>
            {useArrayLength(errors.no_term) &&
              errors.no_term.map((error, index) => (
                <span key={index}>
                  <span>{error}</span>
                  <br />
                </span>
              ))}
          </Caption12>
          <Caption12 color={props.theme.textError}>
            {useArrayLength(errors.limit_exceeded) &&
              errors.limit_exceeded.map((error, index) => (
                <span key={index}>
                  <span>{error}</span>
                  <br />
                </span>
              ))}
          </Caption12>
          <Checkbox
            text="Се согласувам со "
            checked={termsAgreement}
            error={errors.terms}
            onClick={() => setTermsAgreement(!termsAgreement)}
            link1={{
              text: "правилата на игра",
              onClick: () => {
                setShowAllRulesPopup({
                  ...showAllRulesPopup,
                  display: true,
                  terms: true,
                  privacy: false,
                });
                setTermsAgreement(!termsAgreement);
              },
            }}
            link2={{
              text: "политиката на приватност",
              onClick: () => {
                setShowAllRulesPopup({
                  ...showAllRulesPopup,
                  display: true,
                  privacy: true,
                  terms: false,
                });
                setTermsAgreement(!termsAgreement);
                setTermsAgreement(!termsAgreement);
              },
            }}
            // link={{
            //     text: t("general.terms_and_conditions"),
            //     onClick: () =>
            //         setShowAllRulesPopup({
            //             ...showAllRulesPopup,
            //             display: true,
            //             privacy: true,
            //         }),
            // }}
          />
          <ButtonApply
            type="submit"
            text="ПРОДОЛЖИ"
            disabled={!termsAgreement}
          />
        </StyledApplicationForm>
      </main>

      {/* ------------ Get Ready Modal ------------------- */}

      <div onClick={() => setShowPopup()} className="start-popup-container">
        <div ref={popupRef} className="start-popup">
          <div className="start-popup-content">
            <Text18>
              <h1>Подготви се!</h1>
              <p>
                Неколку нешта на кои треба да внимаваш пред да продолжиш
                понатаму:{" "}
              </p>
              <ol>
                <li>
                  {" "}
                  1. Во оваа рунда те очекуваат три групи на задачи, времето за
                  решавање на секоја од задачите е ограничено!{" "}
                </li>
                <li>
                  {" "}
                  2. Во третата игра постои само еден точен одговор, внимавај во
                  изборот, нема можност за враќање назад или менување на
                  одговорот.
                </li>
                <li>
                  3. Не го освежувај пребарувачот додека ги решаваш задачите!
                  Тоа ќе го избрише твојот прогрес целосно, без можност да
                  почнеш од почеток.
                </li>
              </ol>
            </Text18>
            <div className="buttons-container">
              <button
                type="button"
                onClick={() => onCloseGetReadyModal(userInfo)}
              >
                Започни го тестот
              </button>
            </div>
          </div>
        </div>
      </div>
    </ApplicationFormWrapper>
  );
};

export default withTheme(ApplicationFormNew);
