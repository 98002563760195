export const next = <svg xmlns="http://www.w3.org/2000/svg" width="133" height="29" viewBox="0 0 133 29" fill="none">
<path d="M7.89543 22.8422C7.78578 22.7759 7.78578 22.6653 7.8735 22.599C8.53144 22.1346 8.94813 21.4491 8.94813 20.4541C8.94813 18.7293 7.76384 17.4468 5.43913 17.4468H0.41686C0.24141 17.4468 0.0878906 17.6016 0.0878906 17.7785V27.9945C0.0878906 28.1714 0.24141 28.3261 0.41686 28.3261H5.92162C8.11475 28.3261 9.43062 27.0657 9.43062 25.2525C9.43062 24.0805 8.83848 23.2845 7.89543 22.8422ZM2.85123 20.2551C2.85123 20.1003 2.98282 19.9676 3.13634 19.9676H5.28561C5.85582 19.9676 6.16286 20.3214 6.16286 20.83C6.16286 21.3386 5.87775 21.6924 5.13209 21.6924H3.13634C2.98282 21.6924 2.85123 21.5597 2.85123 21.4049V20.2551ZM5.61458 25.8053H3.13634C2.98282 25.8053 2.85123 25.6726 2.85123 25.5179V24.169C2.85123 24.0142 2.98282 23.8815 3.13634 23.8815H5.63651C6.38217 23.8815 6.66728 24.2574 6.66728 24.8103C6.64535 25.3852 6.36024 25.8053 5.61458 25.8053Z" fill="#F2F1EE"/>
<path d="M18.6634 25.2746C18.5757 25.1419 18.5976 24.9872 18.7511 24.9208C20.0451 24.2132 20.6372 22.8643 20.6372 21.427C20.6372 19.26 19.3652 17.4468 16.6018 17.4468H11.6454C11.4699 17.4468 11.3164 17.6016 11.3164 17.7785V27.9945C11.3164 28.1714 11.4699 28.3261 11.6454 28.3261H13.7288C13.9043 28.3261 14.0578 28.1714 14.0578 27.9945V25.6948C14.0578 25.54 14.1894 25.4073 14.3429 25.4073H15.1982C15.3737 25.4073 15.5491 25.4957 15.6369 25.6284L17.3256 28.0387C17.4572 28.2156 17.6546 28.3261 17.8519 28.3261H20.4837C20.6153 28.3261 20.703 28.1714 20.6153 28.0387L18.6634 25.2746ZM16.4483 22.8865H14.3649C14.2113 22.8865 14.0798 22.7538 14.0798 22.599V20.2551C14.0798 20.1003 14.2113 19.9676 14.3649 19.9676H16.4483C17.5449 19.9676 17.9616 20.5868 17.9616 21.427C17.9616 22.201 17.5449 22.8865 16.4483 22.8865Z" fill="#F2F1EE"/>
<path d="M28.8181 17.7564C28.7523 17.5795 28.5549 17.4468 28.3575 17.4468H26.274C26.0766 17.4468 25.8793 17.5795 25.8135 17.7564L21.6904 28.0166C21.6246 28.1492 21.7342 28.3261 21.8878 28.3261H24.2344C24.4318 28.3261 24.6072 28.1935 24.673 28.0166L25.0678 26.9994C25.1336 26.8446 25.2652 26.734 25.4406 26.734H29.147C29.3225 26.734 29.476 26.8446 29.5199 26.9994L29.9146 28.0166C29.9804 28.1935 30.1559 28.3261 30.3532 28.3261H32.6999C32.8753 28.3261 32.9631 28.1492 32.8973 28.0166L28.8181 17.7564ZM28.204 24.2132H26.3837C26.2521 24.2132 26.1644 24.0805 26.2082 23.9479L27.1951 21.2944C27.2171 21.2059 27.3706 21.2059 27.3925 21.2944L28.3794 23.9479C28.4452 24.0584 28.3575 24.2132 28.204 24.2132Z" fill="#F2F1EE"/>
<path d="M36.9979 17.4468H34.8924C34.717 17.4468 34.5635 17.6016 34.5635 17.7785V27.9945C34.5635 28.1714 34.717 28.3261 34.8924 28.3261H36.9979C37.1733 28.3261 37.3268 28.1714 37.3268 27.9945V17.7785C37.3268 17.6016 37.1733 17.4468 36.9979 17.4468Z" fill="#F2F1EE"/>
<path d="M49.2583 17.4468H47.1749C46.9994 17.4468 46.8459 17.6016 46.8459 17.7785V23.2403C46.8459 23.3287 46.7362 23.3729 46.6704 23.3066L42.2403 17.7121C42.1087 17.5352 41.9113 17.4468 41.692 17.4468H40.0253C39.8498 17.4468 39.6963 17.6016 39.6963 17.7785V27.9945C39.6963 28.1714 39.8498 28.3261 40.0253 28.3261H42.1087C42.2842 28.3261 42.4377 28.1714 42.4377 27.9945V22.5327C42.4377 22.4442 42.5474 22.4 42.6131 22.4663L47.0652 28.0387C47.1968 28.2156 47.3942 28.304 47.6135 28.304H49.2803C49.4557 28.304 49.6092 28.1492 49.6092 27.9723V17.7785C49.5873 17.6016 49.4338 17.4468 49.2583 17.4468Z" fill="#F2F1EE"/>
<path d="M56.3415 21.4934C55.0475 21.0512 54.565 20.83 54.565 20.3878C54.565 20.034 54.9379 19.6581 55.8809 19.6581C56.802 19.6581 57.3284 20.0782 57.4819 20.5426C57.5696 20.7858 57.6793 20.9185 57.9425 20.9185H59.8724C60.0698 20.9185 60.2453 20.7195 60.2233 20.5205C60.0917 18.5746 58.3372 17.2478 55.8151 17.2478C53.3588 17.2478 51.6043 18.5524 51.6043 20.631C51.6043 22.8865 53.8632 23.7047 55.5739 24.2796C56.8678 24.7218 57.4161 25.0093 57.4161 25.4737C57.4161 25.8053 57.0871 26.137 56.0564 26.137C55.0914 26.137 54.5212 25.8054 54.3238 25.4294C54.1483 25.142 54.0826 25.0756 53.7755 25.0756H51.9114C51.714 25.0756 51.5385 25.2746 51.5605 25.4737C51.7578 27.4638 53.6878 28.5473 56.1222 28.5473C58.5785 28.5473 60.4207 27.4417 60.4207 25.3189C60.3768 22.9086 57.8328 22.0241 56.3415 21.4934Z" fill="#F2F1EE"/>
<path d="M70.7938 17.4468H61.9774C61.802 17.4468 61.6484 17.6016 61.6484 17.7785V19.8128C61.6484 19.9897 61.802 20.1445 61.9774 20.1445H64.7407C64.8943 20.1445 65.0259 20.2772 65.0259 20.432V27.9945C65.0259 28.1714 65.1794 28.3261 65.3548 28.3261H67.4602C67.6357 28.3261 67.7892 28.1714 67.7892 27.9945V20.432C67.7892 20.2772 67.9208 20.1445 68.0743 20.1445H70.8376C71.0131 20.1445 71.1666 19.9897 71.1666 19.8128V17.7785C71.1447 17.6016 70.9912 17.4468 70.7938 17.4468Z" fill="#F2F1EE"/>
<path d="M80.7736 17.4468H73.1415C72.966 17.4468 72.8125 17.6016 72.8125 17.7785V27.9945C72.8125 28.1714 72.966 28.3261 73.1415 28.3261H80.7736C80.949 28.3261 81.1025 28.1714 81.1025 27.9945V25.9822C81.1025 25.8053 80.949 25.6505 80.7736 25.6505H75.8609C75.7074 25.6505 75.5758 25.5179 75.5758 25.3631V24.5228C75.5758 24.368 75.7074 24.2353 75.8609 24.2353H79.7866C79.9621 24.2353 80.1156 24.0805 80.1156 23.9036V22.0462C80.1156 21.8693 79.9621 21.7145 79.7866 21.7145H75.8609C75.7074 21.7145 75.5758 21.5818 75.5758 21.427V20.432C75.5758 20.2772 75.7074 20.1445 75.8609 20.1445H80.7736C80.949 20.1445 81.1025 19.9897 81.1025 19.8128V17.7785C81.1025 17.6016 80.949 17.4468 80.7736 17.4468Z" fill="#F2F1EE"/>
<path d="M92.4629 28.0388L90.4891 25.2526C90.4014 25.12 90.4233 24.9652 90.5768 24.8988C91.8708 24.1912 92.4629 22.8424 92.4629 21.4051C92.4629 19.238 91.1909 17.4248 88.4276 17.4248H83.493C83.3176 17.4248 83.1641 17.5796 83.1641 17.7565V27.9725C83.1641 28.1494 83.3176 28.3042 83.493 28.3042H85.5765C85.752 28.3042 85.9055 28.1494 85.9055 27.9725V25.6728C85.9055 25.518 86.0371 25.3853 86.1906 25.3853H87.0459C87.2214 25.3853 87.3968 25.4738 87.4845 25.6064L89.1732 28.0167C89.3048 28.1936 89.5022 28.3042 89.6996 28.3042H92.3314C92.4629 28.3263 92.5507 28.1715 92.4629 28.0388ZM88.2741 22.8866H86.1687C86.0151 22.8866 85.8835 22.7539 85.8835 22.5991V20.2552C85.8835 20.1004 86.0151 19.9677 86.1687 19.9677H88.2521C89.3487 19.9677 89.7654 20.5869 89.7654 21.4272C89.7873 22.2011 89.3706 22.8866 88.2741 22.8866Z" fill="#F2F1EE"/>
<path d="M103.67 17.4912V28.3706H101.412L96.2139 18.0219V28.3706H94.9199V17.4912H97.1788L102.377 27.8399V17.4912H103.67Z" fill="#F2F1EE"/>
<path d="M107.179 18.5966V22.1346H112.443V23.2402H107.179V27.2647H113.057V28.3703H105.819V17.491H113.057V18.5966H107.179Z" fill="#F2F1EE"/>
<path d="M120.207 22.7539L123.497 28.3484H121.983L119.023 23.1077L115.886 28.3484H114.461L117.838 22.7539L114.614 17.469H116.128L119.045 22.4001L121.918 17.469H123.387L120.207 22.7539Z" fill="#F2F1EE"/>
<path d="M132.971 18.6629H129.593V28.3482H128.233V18.6629H124.878V17.491H132.971V18.6629Z" fill="#F2F1EE"/>
<path d="M71.1905 7.62882C70.4449 10.3265 69.0413 11.4985 66.4314 11.4985C62.9444 11.4985 61.4092 9.77374 61.4092 5.74926C61.4092 1.72478 62.9444 0 66.4314 0C69.3044 0 70.6861 1.17196 71.1905 3.86969H69.8308C69.3702 1.92379 68.4272 1.17196 66.4314 1.17196C63.9313 1.17196 62.8347 2.49872 62.8347 5.72714C62.8347 8.95557 63.9093 10.2823 66.4314 10.2823C68.2517 10.2823 69.1948 9.5305 69.8308 7.5846H71.1905V7.62882Z" fill="#F2F1EE"/>
<path d="M83.2742 5.74926C83.2742 9.77374 81.739 11.4985 78.1642 11.4985C74.5674 11.4985 73.0322 9.77374 73.0322 5.74926C73.0322 1.72478 74.5674 0 78.1642 0C81.739 0 83.2742 1.72478 83.2742 5.74926ZM74.4358 5.74926C74.4358 8.97768 75.5324 10.3044 78.1422 10.3044C80.7521 10.3044 81.8486 8.97768 81.8486 5.74926C81.8486 2.52083 80.7521 1.19408 78.1422 1.19408C75.5324 1.19408 74.4358 2.52083 74.4358 5.74926Z" fill="#F2F1EE"/>
<path d="M92.3537 11.1889H85.7305V0.30957H87.0902V9.99486H92.3537V11.1889Z" fill="#F2F1EE"/>
<path d="M101.302 11.1889H94.6787V0.30957H96.0385V9.99486H101.302V11.1889Z" fill="#F2F1EE"/>
<path d="M104.964 1.4152V4.9532H110.228V6.05883H104.964V10.0833H110.842V11.1889H103.604V0.30957H110.842V1.4152H104.964Z" fill="#F2F1EE"/>
<path d="M117.662 5.17433H122.465V5.74926C122.465 9.77374 121.04 11.4985 117.662 11.4985C114.065 11.4985 112.53 9.77374 112.53 5.74926C112.53 1.72478 114.065 0 117.662 0C120.404 0 121.807 1.12774 122.312 3.73702H121.018C120.601 1.9459 119.614 1.19408 117.662 1.19408C115.03 1.19408 113.956 2.52083 113.956 5.74926C113.956 8.97768 115.052 10.3044 117.662 10.3044C120.14 10.3044 121.171 9.11036 121.171 6.21362H117.662V5.17433Z" fill="#F2F1EE"/>
<path d="M126.282 1.4152V4.9532H131.545V6.05883H126.282V10.0833H132.159V11.1889H124.922V0.30957H132.159V1.4152H126.282Z" fill="#F2F1EE"/>
<path d="M1.1458 0.736816H1.66162L2.8032 3.75905H2.20281L1.92376 3.00562H0.875207L0.600383 3.75905H0L1.1458 0.736816ZM1.81806 2.59272L1.40371 1.40511L0.972452 2.59272H1.81806Z" fill="#F2F1EE"/>
<path d="M3.85123 1.97976H4.088L5.00126 0.736816H5.66083L4.58268 2.19685L5.75808 3.75905H5.10696L4.14296 2.50759H3.85123V3.75905H3.27198V0.736816H3.85123V1.97976Z" fill="#F2F1EE"/>
<path d="M6.1611 3.75905V0.736816H7.43374C7.57186 0.736816 7.6987 0.766613 7.81426 0.826206C7.93265 0.882962 8.03412 0.959582 8.11868 1.05607C8.20324 1.14971 8.26948 1.25755 8.3174 1.37957C8.36532 1.49876 8.38928 1.61936 8.38928 1.74139C8.38928 1.86909 8.36673 1.99395 8.32163 2.11597C8.27653 2.23516 8.21311 2.34158 8.13137 2.43522C8.04962 2.52887 7.95097 2.60407 7.8354 2.66083C7.71984 2.71758 7.593 2.74596 7.45488 2.74596H6.74457V3.75905H6.1611ZM6.74457 2.2309H7.42105C7.52816 2.2309 7.61695 2.18692 7.68742 2.09895C7.76071 2.01098 7.79735 1.89179 7.79735 1.74139C7.79735 1.66477 7.78608 1.59666 7.76353 1.53707C7.74098 1.47464 7.71138 1.42214 7.67474 1.37957C7.63809 1.33701 7.5944 1.30579 7.54367 1.28593C7.49575 1.26322 7.44642 1.25187 7.39569 1.25187H6.74457V2.2309Z" fill="#F2F1EE"/>
<path d="M11.019 3.24399V3.75905H8.9346V0.736816H10.981V1.25187H9.51807V1.97976H10.7823V2.45651H9.51807V3.24399H11.019Z" fill="#F2F1EE"/>
<path d="M11.3595 3.24399H11.4652C11.5469 3.24115 11.6188 3.21703 11.6808 3.17163C11.7428 3.12622 11.7978 3.04251 11.8457 2.92048C11.8936 2.79846 11.9359 2.63103 11.9725 2.4182C12.0092 2.20253 12.043 1.92584 12.074 1.58815L12.1501 0.736816H13.9682V3.24399H14.4121V4.51248H13.8667V3.75905H11.9091V4.51248H11.3595V3.24399ZM13.3847 3.24399V1.25187H12.6786L12.6363 1.71159C12.6025 2.10604 12.5546 2.42671 12.4926 2.6736C12.4334 2.92048 12.3559 3.11061 12.26 3.24399H13.3847Z" fill="#F2F1EE"/>
<path d="M15.5192 2.85663L17.0497 0.736816H17.4937V3.75905H16.9144V1.68605L15.405 3.75905H14.9399V0.736816H15.5192V2.85663Z" fill="#F2F1EE"/>
<path d="M20.5032 1.25187H19.5435V3.75905H18.9642V1.25187H18.0045V0.736816H20.5032V1.25187Z" fill="#F2F1EE"/>
<path d="M21.5947 2.85663L23.1252 0.736816H23.5692V3.75905H22.9899V1.68605L21.4805 3.75905H21.0154V0.736816H21.5947V2.85663Z" fill="#F2F1EE"/>
<path d="M24.3463 3.75905V0.736816H25.619C25.7571 0.736816 25.8839 0.766613 25.9995 0.826206C26.1179 0.882962 26.2194 0.959582 26.3039 1.05607C26.3885 1.14971 26.4547 1.25755 26.5026 1.37957C26.5506 1.49876 26.5745 1.61936 26.5745 1.74139C26.5745 1.86909 26.552 1.99395 26.5069 2.11597C26.4618 2.23516 26.3984 2.34158 26.3166 2.43522C26.2349 2.52887 26.1362 2.60407 26.0206 2.66083C25.9051 2.71758 25.7782 2.74596 25.6401 2.74596H24.9298V3.75905H24.3463ZM24.9298 2.2309H25.6063C25.7134 2.2309 25.8022 2.18692 25.8727 2.09895C25.9459 2.01098 25.9826 1.89179 25.9826 1.74139C25.9826 1.66477 25.9713 1.59666 25.9488 1.53707C25.9262 1.47464 25.8966 1.42214 25.86 1.37957C25.8233 1.33701 25.7796 1.30579 25.7289 1.28593C25.681 1.26322 25.6317 1.25187 25.5809 1.25187H24.9298V2.2309Z" fill="#F2F1EE"/>
<path d="M27.7713 0.736816H28.2871L29.4287 3.75905H28.8283L28.5493 3.00562H27.5007L27.2259 3.75905H26.6255L27.7713 0.736816ZM28.4436 2.59272L28.0292 1.40511L27.5979 2.59272H28.4436Z" fill="#F2F1EE"/>
<path d="M32.4216 0.736816V3.75905H31.8424V2.46928H30.4809V3.75905H29.8975V0.736816H30.4809V1.95848H31.8424V0.736816H32.4216Z" fill="#F2F1EE"/>
<path d="M34.0409 0.736816H34.5567L35.6983 3.75905H35.0979L34.8188 3.00562H33.7703L33.4954 3.75905H32.8951L34.0409 0.736816ZM34.7131 2.59272L34.2988 1.40511L33.8675 2.59272H34.7131Z" fill="#F2F1EE"/>
<path d="M2.67636 7.62371C2.67636 7.75141 2.65099 7.8635 2.60025 7.95998C2.54951 8.05647 2.47905 8.13734 2.38885 8.20261C2.30147 8.26788 2.19859 8.31754 2.0802 8.3516C1.96463 8.38565 1.84061 8.40268 1.70813 8.40268H0.257911V5.38045H1.86457C1.96886 5.38045 2.06329 5.40315 2.14785 5.44855C2.23241 5.49396 2.30429 5.55355 2.36348 5.62733C2.42267 5.69828 2.46777 5.78057 2.49878 5.87422C2.5326 5.96503 2.54951 6.05726 2.54951 6.1509C2.54951 6.29847 2.51287 6.43752 2.43959 6.56806C2.3663 6.69576 2.25919 6.79082 2.11825 6.85325C2.29019 6.90433 2.42549 6.99656 2.52415 7.12994C2.62562 7.26047 2.67636 7.42506 2.67636 7.62371ZM2.08866 7.51304C2.08866 7.45912 2.07879 7.40804 2.05906 7.3598C2.04215 7.30872 2.01819 7.26473 1.98718 7.22784C1.95618 7.19095 1.91954 7.16257 1.87725 7.14271C1.83497 7.12 1.78847 7.10865 1.73773 7.10865H0.841382V7.90465H1.70813C1.76169 7.90465 1.81102 7.89471 1.85611 7.87485C1.90403 7.85499 1.9449 7.82803 1.97873 7.79397C2.01255 7.75708 2.03933 7.71452 2.05906 7.66628C2.07879 7.61803 2.08866 7.56695 2.08866 7.51304ZM0.841382 5.88273V6.64468H1.61934C1.718 6.64468 1.80115 6.61062 1.8688 6.54252C1.93927 6.47441 1.9745 6.38076 1.9745 6.26158C1.9745 6.14523 1.94208 6.053 1.87725 5.98489C1.81524 5.91679 1.73773 5.88273 1.64471 5.88273H0.841382Z" fill="#F2F1EE"/>
<path d="M3.89252 7.50027L5.42307 5.38045H5.86701V8.40268H5.28777V6.32968L3.77836 8.40268H3.31327V5.38045H3.89252V7.50027Z" fill="#F2F1EE"/>
<path d="M6.44969 6.87028C6.44969 6.68582 6.48211 6.50421 6.54694 6.32543C6.61459 6.14381 6.71183 5.98206 6.83867 5.84017C6.96552 5.69828 7.12054 5.58335 7.30376 5.49538C7.48697 5.40741 7.69556 5.36342 7.92951 5.36342C8.20574 5.36342 8.44392 5.42443 8.64405 5.54646C8.847 5.66848 8.9978 5.8274 9.09645 6.0232L8.64828 6.33394C8.60882 6.24881 8.5609 6.17786 8.50453 6.12111C8.44815 6.06151 8.38755 6.01469 8.32272 5.98064C8.25789 5.94658 8.19024 5.92246 8.11977 5.90827C8.04931 5.89125 7.98025 5.88273 7.9126 5.88273C7.76603 5.88273 7.63777 5.91253 7.52785 5.97212C7.42074 6.03172 7.33054 6.10976 7.25725 6.20624C7.18678 6.29989 7.13323 6.40772 7.09658 6.52975C7.05994 6.64893 7.04162 6.76812 7.04162 6.88731C7.04162 7.02068 7.06276 7.1498 7.10504 7.27466C7.14732 7.39669 7.20651 7.50452 7.28262 7.59817C7.36154 7.69181 7.45456 7.76702 7.56167 7.82377C7.6716 7.88053 7.79139 7.9089 7.92105 7.9089C7.9887 7.9089 8.05917 7.90039 8.13246 7.88336C8.20574 7.86634 8.2748 7.83938 8.33963 7.80249C8.40728 7.7656 8.46929 7.71877 8.52567 7.66202C8.58204 7.60242 8.62714 7.53148 8.66096 7.44918L9.13873 7.72587C9.09082 7.83938 9.02176 7.94012 8.93156 8.02809C8.84418 8.11322 8.74411 8.18559 8.63137 8.24518C8.52144 8.30477 8.40305 8.35018 8.27621 8.38139C8.14937 8.41261 8.02535 8.42822 7.90414 8.42822C7.68992 8.42822 7.49402 8.38281 7.31644 8.292C7.13887 8.2012 6.98525 8.08343 6.85559 7.9387C6.72874 7.79114 6.62868 7.62513 6.55539 7.44067C6.48493 7.25338 6.44969 7.06325 6.44969 6.87028Z" fill="#F2F1EE"/>
<path d="M10.9092 8.42396C10.6893 8.42396 10.4892 8.38139 10.3088 8.29626C10.1312 8.20829 9.97758 8.09336 9.84792 7.95147C9.72108 7.80674 9.62243 7.64215 9.55196 7.4577C9.48149 7.27324 9.44626 7.08453 9.44626 6.89156C9.44626 6.69008 9.4829 6.49711 9.55619 6.31266C9.63229 6.1282 9.73517 5.96503 9.86483 5.82314C9.99731 5.68125 10.1538 5.56916 10.3341 5.48686C10.5145 5.40173 10.7104 5.35916 10.9218 5.35916C11.1389 5.35916 11.3362 5.40457 11.5138 5.49538C11.6942 5.58335 11.8478 5.6997 11.9746 5.84442C12.1015 5.98915 12.2001 6.15374 12.2706 6.3382C12.3411 6.52265 12.3763 6.70995 12.3763 6.90008C12.3763 7.10156 12.3397 7.29453 12.2664 7.47898C12.1931 7.6606 12.0916 7.82235 11.9619 7.96424C11.8323 8.10329 11.6773 8.21538 11.4969 8.30052C11.3165 8.38281 11.1206 8.42396 10.9092 8.42396ZM10.0382 6.89156C10.0382 7.0221 10.0579 7.14838 10.0974 7.2704C10.1368 7.39243 10.1932 7.50027 10.2665 7.59391C10.3426 7.68756 10.4342 7.76276 10.5413 7.81951C10.6513 7.87627 10.7753 7.90465 10.9134 7.90465C11.0543 7.90465 11.1783 7.87627 11.2855 7.81951C11.3954 7.75992 11.487 7.68188 11.5603 7.5854C11.6364 7.48891 11.6928 7.38108 11.7294 7.26189C11.7689 7.13987 11.7886 7.01642 11.7886 6.89156C11.7886 6.76102 11.7675 6.63474 11.7252 6.51272C11.6857 6.3907 11.6279 6.28286 11.5518 6.18921C11.4785 6.09557 11.3869 6.02178 11.277 5.96787C11.1699 5.91111 11.0487 5.88273 10.9134 5.88273C10.7725 5.88273 10.647 5.91253 10.5371 5.97212C10.4272 6.02888 10.3356 6.1055 10.2623 6.20198C10.189 6.29563 10.1326 6.40347 10.0931 6.52549C10.0565 6.64468 10.0382 6.7667 10.0382 6.89156Z" fill="#F2F1EE"/>
<path d="M13.5384 6.62339H13.7752L14.6884 5.38045H15.348L14.2699 6.84048L15.4453 8.40268H14.7941L13.8302 7.15122H13.5384V8.40268H12.9592V5.38045H13.5384V6.62339Z" fill="#F2F1EE"/>
<path d="M16.9971 8.42396C16.7772 8.42396 16.5771 8.38139 16.3967 8.29626C16.2191 8.20829 16.0655 8.09336 15.9358 7.95147C15.809 7.80674 15.7103 7.64215 15.6399 7.4577C15.5694 7.27324 15.5342 7.08453 15.5342 6.89156C15.5342 6.69008 15.5708 6.49711 15.6441 6.31266C15.7202 6.1282 15.8231 5.96503 15.9527 5.82314C16.0852 5.68125 16.2416 5.56916 16.422 5.48686C16.6024 5.40173 16.7983 5.35916 17.0097 5.35916C17.2268 5.35916 17.4241 5.40457 17.6017 5.49538C17.7821 5.58335 17.9357 5.6997 18.0625 5.84442C18.1894 5.98915 18.288 6.15374 18.3585 6.3382C18.429 6.52265 18.4642 6.70995 18.4642 6.90008C18.4642 7.10156 18.4276 7.29453 18.3543 7.47898C18.281 7.6606 18.1795 7.82235 18.0498 7.96424C17.9202 8.10329 17.7652 8.21538 17.5848 8.30052C17.4044 8.38281 17.2085 8.42396 16.9971 8.42396ZM16.1261 6.89156C16.1261 7.0221 16.1458 7.14838 16.1853 7.2704C16.2247 7.39243 16.2811 7.50027 16.3544 7.59391C16.4305 7.68756 16.5221 7.76276 16.6292 7.81951C16.7392 7.87627 16.8632 7.90465 17.0013 7.90465C17.1422 7.90465 17.2662 7.87627 17.3734 7.81951C17.4833 7.75992 17.5749 7.68188 17.6482 7.5854C17.7243 7.48891 17.7807 7.38108 17.8173 7.26189C17.8568 7.13987 17.8765 7.01642 17.8765 6.89156C17.8765 6.76102 17.8554 6.63474 17.8131 6.51272C17.7736 6.3907 17.7158 6.28286 17.6397 6.18921C17.5664 6.09557 17.4748 6.02178 17.3649 5.96787C17.2578 5.91111 17.1366 5.88273 17.0013 5.88273C16.8604 5.88273 16.7349 5.91253 16.625 5.97212C16.5151 6.02888 16.4235 6.1055 16.3502 6.20198C16.2769 6.29563 16.2205 6.40347 16.181 6.52549C16.1444 6.64468 16.1261 6.7667 16.1261 6.89156Z" fill="#F2F1EE"/>
<path d="M20.3197 8.42396C20.0999 8.42396 19.8997 8.38139 19.7193 8.29626C19.5418 8.20829 19.3881 8.09336 19.2585 7.95147C19.1316 7.80674 19.033 7.64215 18.9625 7.4577C18.892 7.27324 18.8568 7.08453 18.8568 6.89156C18.8568 6.69008 18.8935 6.49711 18.9667 6.31266C19.0428 6.1282 19.1457 5.96503 19.2754 5.82314C19.4079 5.68125 19.5643 5.56916 19.7447 5.48686C19.9251 5.40173 20.121 5.35916 20.3324 5.35916C20.5494 5.35916 20.7467 5.40457 20.9243 5.49538C21.1047 5.58335 21.2583 5.6997 21.3852 5.84442C21.512 5.98915 21.6107 6.15374 21.6811 6.3382C21.7516 6.52265 21.7868 6.70995 21.7868 6.90008C21.7868 7.10156 21.7502 7.29453 21.6769 7.47898C21.6036 7.6606 21.5022 7.82235 21.3725 7.96424C21.2428 8.10329 21.0878 8.21538 20.9074 8.30052C20.727 8.38281 20.5311 8.42396 20.3197 8.42396ZM19.4487 6.89156C19.4487 7.0221 19.4685 7.14838 19.5079 7.2704C19.5474 7.39243 19.6038 7.50027 19.677 7.59391C19.7532 7.68756 19.8448 7.76276 19.9519 7.81951C20.0618 7.87627 20.1858 7.90465 20.3239 7.90465C20.4649 7.90465 20.5889 7.87627 20.696 7.81951C20.8059 7.75992 20.8975 7.68188 20.9708 7.5854C21.0469 7.48891 21.1033 7.38108 21.14 7.26189C21.1794 7.13987 21.1991 7.01642 21.1991 6.89156C21.1991 6.76102 21.178 6.63474 21.1357 6.51272C21.0963 6.3907 21.0385 6.28286 20.9624 6.18921C20.8891 6.09557 20.7975 6.02178 20.6876 5.96787C20.5804 5.91111 20.4592 5.88273 20.3239 5.88273C20.183 5.88273 20.0576 5.91253 19.9476 5.97212C19.8377 6.02888 19.7461 6.1055 19.6728 6.20198C19.5995 6.29563 19.5432 6.40347 19.5037 6.52549C19.4671 6.64468 19.4487 6.7667 19.4487 6.89156Z" fill="#F2F1EE"/>
<path d="M22.3697 5.38045H24.5641V5.8955H22.949V6.58934H23.7777C23.9242 6.58934 24.0567 6.6092 24.1751 6.64893C24.2963 6.68866 24.3992 6.74684 24.4837 6.82346C24.5711 6.90008 24.6374 6.99372 24.6825 7.1044C24.7304 7.21507 24.7543 7.34277 24.7543 7.48749C24.7543 7.78262 24.667 8.00965 24.4922 8.16856C24.3203 8.32464 24.0891 8.40268 23.7988 8.40268H22.3697V5.38045ZM23.7438 7.90465C23.882 7.90465 23.9862 7.86776 24.0567 7.79397C24.1272 7.71735 24.1624 7.61378 24.1624 7.48324C24.1624 7.35838 24.1272 7.25764 24.0567 7.18102C23.9862 7.10439 23.882 7.06608 23.7438 7.06608H22.949V7.90465H23.7438Z" fill="#F2F1EE"/>
<path d="M25.3208 8.40268V5.38045H26.5934C26.7315 5.38045 26.8584 5.41024 26.9739 5.46984C27.0923 5.52659 27.1938 5.60321 27.2784 5.6997C27.3629 5.79334 27.4292 5.90118 27.4771 6.0232C27.525 6.14239 27.549 6.263 27.549 6.38502C27.549 6.51272 27.5264 6.63758 27.4813 6.75961C27.4362 6.87879 27.3728 6.98521 27.291 7.07886C27.2093 7.1725 27.1106 7.2477 26.9951 7.30446C26.8795 7.36121 26.7527 7.38959 26.6146 7.38959H25.9042V8.40268H25.3208ZM25.9042 6.87454H26.5807C26.6878 6.87454 26.7766 6.83055 26.8471 6.74258C26.9204 6.65461 26.957 6.53542 26.957 6.38502C26.957 6.3084 26.9458 6.24029 26.9232 6.1807C26.9007 6.11827 26.8711 6.06577 26.8344 6.0232C26.7978 5.98064 26.7541 5.94942 26.7033 5.92956C26.6554 5.90685 26.6061 5.8955 26.5554 5.8955H25.9042V6.87454Z" fill="#F2F1EE"/>
<path d="M28.7457 5.38045H29.2615L30.4031 8.40268H29.8027L29.5237 7.64925H28.4751L28.2003 8.40268H27.5999L28.7457 5.38045ZM29.418 7.23635L29.0036 6.04874L28.5724 7.23635H29.418Z" fill="#F2F1EE"/>
<path d="M30.6859 5.7678C30.8071 5.63159 30.9466 5.52943 31.1045 5.46132C31.2623 5.39038 31.4511 5.35491 31.671 5.35491C31.9585 5.35491 32.1854 5.41876 32.3517 5.54646C32.5208 5.67416 32.6054 5.86145 32.6054 6.10834C32.6054 6.26158 32.5674 6.40063 32.4912 6.52549C32.418 6.64751 32.301 6.73407 32.1403 6.78515C32.3264 6.82204 32.4701 6.90575 32.5716 7.03629C32.6731 7.16399 32.7238 7.3229 32.7238 7.51304C32.7238 7.65492 32.6956 7.78262 32.6392 7.89613C32.5857 8.00681 32.511 8.10187 32.4151 8.18133C32.3193 8.25795 32.2037 8.31754 32.0684 8.36011C31.936 8.40268 31.7922 8.42396 31.6372 8.42396C31.4201 8.42396 31.2285 8.38849 31.0622 8.31754C30.8959 8.24376 30.7479 8.13451 30.6182 7.98978L30.9565 7.58114C31.041 7.68046 31.1355 7.7585 31.2397 7.81526C31.3469 7.87201 31.4751 7.90039 31.6245 7.90039C31.7739 7.90039 31.8965 7.86066 31.9923 7.7812C32.0882 7.70175 32.1361 7.59107 32.1361 7.44918C32.1361 7.31865 32.0854 7.21081 31.9839 7.12568C31.8852 7.04055 31.7344 6.99798 31.5315 6.99798H31.2186V6.5638H31.4977C31.6781 6.5638 31.8162 6.53116 31.912 6.4659C32.0078 6.39779 32.0558 6.30272 32.0558 6.1807C32.0558 6.07286 32.0191 5.99057 31.9458 5.93381C31.8754 5.87706 31.7739 5.84868 31.6414 5.84868C31.5089 5.84868 31.3905 5.87422 31.2863 5.9253C31.1848 5.97354 31.0946 6.04307 31.0157 6.13388L30.6859 5.7678Z" fill="#F2F1EE"/>
<path d="M34.5919 8.42396C34.372 8.42396 34.1719 8.38139 33.9915 8.29626C33.8139 8.20829 33.6603 8.09336 33.5306 7.95147C33.4038 7.80674 33.3051 7.64215 33.2347 7.4577C33.1642 7.27324 33.129 7.08453 33.129 6.89156C33.129 6.69008 33.1656 6.49711 33.2389 6.31266C33.315 6.1282 33.4179 5.96503 33.5475 5.82314C33.68 5.68125 33.8364 5.56916 34.0168 5.48686C34.1972 5.40173 34.3931 5.35916 34.6045 5.35916C34.8216 5.35916 35.0189 5.40457 35.1965 5.49538C35.3769 5.58335 35.5305 5.6997 35.6573 5.84442C35.7842 5.98915 35.8828 6.15374 35.9533 6.3382C36.0238 6.52265 36.059 6.70995 36.059 6.90008C36.059 7.10156 36.0224 7.29453 35.9491 7.47898C35.8758 7.6606 35.7743 7.82235 35.6446 7.96424C35.515 8.10329 35.36 8.21538 35.1796 8.30052C34.9992 8.38281 34.8033 8.42396 34.5919 8.42396ZM33.7209 6.89156C33.7209 7.0221 33.7406 7.14838 33.7801 7.2704C33.8195 7.39243 33.8759 7.50027 33.9492 7.59391C34.0253 7.68756 34.1169 7.76276 34.224 7.81951C34.334 7.87627 34.458 7.90465 34.5961 7.90465C34.737 7.90465 34.861 7.87627 34.9682 7.81951C35.0781 7.75992 35.1697 7.68188 35.243 7.5854C35.3191 7.48891 35.3755 7.38108 35.4121 7.26189C35.4516 7.13987 35.4713 7.01642 35.4713 6.89156C35.4713 6.76102 35.4502 6.63474 35.4079 6.51272C35.3684 6.3907 35.3106 6.28286 35.2345 6.18921C35.1612 6.09557 35.0696 6.02178 34.9597 5.96787C34.8526 5.91111 34.7314 5.88273 34.5961 5.88273C34.4552 5.88273 34.3297 5.91253 34.2198 5.97212C34.1099 6.02888 34.0183 6.1055 33.945 6.20198C33.8717 6.29563 33.8153 6.40347 33.7758 6.52549C33.7392 6.64468 33.7209 6.7667 33.7209 6.89156Z" fill="#F2F1EE"/>
<path d="M39.0138 7.62371C39.0138 7.75141 38.9884 7.8635 38.9377 7.95998C38.887 8.05647 38.8165 8.13734 38.7263 8.20261C38.6389 8.26788 38.536 8.31754 38.4177 8.3516C38.3021 8.38565 38.1781 8.40268 38.0456 8.40268H36.5954V5.38045H38.202C38.3063 5.38045 38.4007 5.40315 38.4853 5.44855C38.5699 5.49396 38.6417 5.55355 38.7009 5.62733C38.7601 5.69828 38.8052 5.78057 38.8362 5.87422C38.8701 5.96503 38.887 6.05726 38.887 6.1509C38.887 6.29847 38.8503 6.43752 38.777 6.56806C38.7038 6.69576 38.5966 6.79082 38.4557 6.85325C38.6276 6.90433 38.7629 6.99656 38.8616 7.12994C38.9631 7.26047 39.0138 7.42506 39.0138 7.62371ZM38.4261 7.51304C38.4261 7.45912 38.4162 7.40804 38.3965 7.3598C38.3796 7.30872 38.3556 7.26473 38.3246 7.22784C38.2936 7.19095 38.257 7.16257 38.2147 7.14271C38.1724 7.12 38.1259 7.10865 38.0752 7.10865H37.1788V7.90465H38.0456C38.0991 7.90465 38.1485 7.89471 38.1936 7.87485C38.2415 7.85499 38.2824 7.82803 38.3162 7.79397C38.35 7.75708 38.3768 7.71452 38.3965 7.66628C38.4162 7.61803 38.4261 7.56695 38.4261 7.51304ZM37.1788 5.88273V6.64468H37.9568C38.0555 6.64468 38.1386 6.61062 38.2062 6.54252C38.2767 6.47441 38.312 6.38076 38.312 6.26158C38.312 6.14523 38.2795 6.053 38.2147 5.98489C38.1527 5.91679 38.0752 5.88273 37.9822 5.88273H37.1788Z" fill="#F2F1EE"/>
<path d="M42.1749 5.38045V8.40268H41.5956V7.11291H40.2342V8.40268H39.6507V5.38045H40.2342V6.60211H41.5956V5.38045H42.1749Z" fill="#F2F1EE"/>
<path d="M43.7941 5.38045H44.3099L45.4515 8.40268H44.8511L44.5721 7.64925H43.5235L43.2487 8.40268H42.6483L43.7941 5.38045ZM44.4664 7.23635L44.052 6.04874L43.6208 7.23635H44.4664Z" fill="#F2F1EE"/>
<path d="M0.883663 12.1439L2.41422 10.0241H2.85816V13.0463H2.27892V10.9733L0.769505 13.0463H0.30442V10.0241H0.883663V12.1439Z" fill="#F2F1EE"/>
<path d="M6.15948 10.0241V13.0463H5.58023V11.7565H4.2188V13.0463H3.63533V10.0241H4.2188V11.2457H5.58023V10.0241H6.15948Z" fill="#F2F1EE"/>
<path d="M6.74285 11.5139C6.74285 11.3295 6.77526 11.1478 6.84009 10.9691C6.90774 10.7874 7.00499 10.6257 7.13183 10.4838C7.25867 10.3419 7.4137 10.227 7.59691 10.139C7.78013 10.051 7.98871 10.0071 8.22267 10.0071C8.4989 10.0071 8.73708 10.0681 8.93721 10.1901C9.14015 10.3121 9.29095 10.471 9.38961 10.6668L8.94144 10.9776C8.90197 10.8924 8.85406 10.8215 8.79768 10.7647C8.74131 10.7051 8.68071 10.6583 8.61588 10.6243C8.55105 10.5902 8.4834 10.5661 8.41293 10.5519C8.34246 10.5349 8.2734 10.5264 8.20575 10.5264C8.05918 10.5264 7.93093 10.5562 7.821 10.6158C7.71389 10.6753 7.62369 10.7534 7.55041 10.8499C7.47994 10.9435 7.42638 11.0514 7.38974 11.1734C7.3531 11.2926 7.33478 11.4117 7.33478 11.5309C7.33478 11.6643 7.35592 11.7934 7.3982 11.9183C7.44048 12.0403 7.49967 12.1482 7.57577 12.2418C7.6547 12.3354 7.74771 12.4106 7.85483 12.4674C7.96476 12.5242 8.08455 12.5525 8.21421 12.5525C8.28186 12.5525 8.35233 12.544 8.42561 12.527C8.4989 12.51 8.56796 12.483 8.63279 12.4461C8.70044 12.4092 8.76245 12.3624 8.81882 12.3056C8.8752 12.2461 8.92029 12.1751 8.95412 12.0928L9.43189 12.3695C9.38397 12.483 9.31491 12.5838 9.22471 12.6717C9.13733 12.7569 9.03727 12.8292 8.92452 12.8888C8.81459 12.9484 8.69621 12.9938 8.56937 13.025C8.44252 13.0562 8.3185 13.0718 8.1973 13.0718C7.98308 13.0718 7.78718 13.0264 7.6096 12.9356C7.43202 12.8448 7.2784 12.7271 7.14874 12.5823C7.0219 12.4348 6.92184 12.2688 6.84855 12.0843C6.77808 11.897 6.74285 11.7069 6.74285 11.5139Z" fill="#F2F1EE"/>
<path d="M12.1662 10.5391H11.2064V13.0463H10.6272V10.5391H9.66744V10.0241H12.1662V10.5391Z" fill="#F2F1EE"/>
<path d="M13.2576 12.1439L14.7882 10.0241H15.2321V13.0463H14.6529V10.9733L13.1435 13.0463H12.6784V10.0241H13.2576V12.1439Z" fill="#F2F1EE"/>
<path d="M18.2417 10.5391H17.282V13.0463H16.7027V10.5391H15.7429V10.0241H18.2417V10.5391Z" fill="#F2F1EE"/>
<path d="M19.2401 13.0761C19.2091 13.0761 19.1753 13.0733 19.1387 13.0676C19.102 13.0648 19.064 13.0591 19.0245 13.0506C18.985 13.0421 18.9456 13.0307 18.9061 13.0165C18.8695 13.0023 18.8371 12.9853 18.8089 12.9654L18.885 12.4802C18.916 12.4972 18.9583 12.5171 19.0118 12.5398C19.0682 12.5596 19.126 12.5696 19.1852 12.5696C19.2669 12.5696 19.3374 12.5469 19.3966 12.5015C19.4586 12.4532 19.5121 12.3681 19.5572 12.2461L18.4537 10.0241H19.0837L19.8194 11.6544L20.5043 10.0241H21.1174L20.0604 12.3227C19.9984 12.4589 19.9378 12.5752 19.8786 12.6717C19.8194 12.7654 19.7574 12.8434 19.6925 12.9058C19.6305 12.9654 19.5629 13.0094 19.4896 13.0378C19.4163 13.0633 19.3332 13.0761 19.2401 13.0761Z" fill="#F2F1EE"/>
<path d="M23.8011 13.0463H21.5729V10.0241H22.1522V12.5313H23.3233V10.0241H23.9026V12.5313H24.3508V13.7997H23.8011V13.0463Z" fill="#F2F1EE"/>
<path d="M25.4582 12.1439L26.9888 10.0241H27.4327V13.0463H26.8535V10.9733L25.3441 13.0463H24.879V10.0241H25.4582V12.1439Z" fill="#F2F1EE"/>
<path d="M28.045 12.4291C28.0845 12.4575 28.1507 12.4887 28.2437 12.5227C28.3367 12.5568 28.4438 12.5738 28.565 12.5738C28.6834 12.5738 28.7779 12.554 28.8483 12.5142C28.9188 12.4717 28.9723 12.4078 29.009 12.3227C29.0456 12.2375 29.0696 12.1325 29.0809 12.0077C29.0921 11.88 29.0978 11.731 29.0978 11.5607V10.0241H29.6812V11.5607C29.6812 11.7906 29.6686 12.0006 29.6432 12.1907C29.6206 12.378 29.5699 12.5383 29.491 12.6717C29.4149 12.8051 29.3035 12.9087 29.157 12.9825C29.0104 13.0562 28.8131 13.0931 28.565 13.0931C28.3114 13.0931 28.0943 13.0307 27.9139 12.9058L28.045 12.4291Z" fill="#F2F1EE"/>
<path d="M31.2013 10.0241H31.7171L32.8587 13.0463H32.2583L31.9793 12.2929H30.9307L30.6559 13.0463H30.0555L31.2013 10.0241ZM31.8736 11.88L31.4592 10.6924L31.0279 11.88H31.8736Z" fill="#F2F1EE"/>
<path d="M53.0215 4.53296L53.2937 6.01133L54.377 4.97639L53.7341 6.33349L55.2148 6.13729L53.9024 6.85477L55.2148 7.57225L53.7341 7.37605L54.377 8.73316L53.2937 7.69822L53.0215 9.17659L52.7492 7.69822L51.6659 8.73316L52.3088 7.37605L50.8281 7.57225L52.1406 6.85477L50.8281 6.13729L52.3088 6.33349L51.6659 4.97639L52.7492 6.01133L53.0215 4.53296Z" fill="#F2F1EE"/>
</svg>