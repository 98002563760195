export const nu = <svg xmlns="http://www.w3.org/2000/svg" width="133" height="29" viewBox="0 0 133 29" fill="none">
<g clip-path="url(#clip0_76_4619)">
  <path d="M4.65299 26.069H0V0.693281H4.65299V8.15159H4.6181C4.88871 6.95364 5.32734 5.80012 5.92093 4.72543C6.4604 3.75897 7.16066 2.89191 7.99152 2.16164C8.7996 1.45745 9.7302 0.908348 10.7368 0.541788C11.7992 0.154125 12.9214 -0.0431132 14.052 -0.0408928C15.3905 -0.0642271 16.7169 0.216667 17.9315 0.780684C19.0722 1.31465 20.0692 2.1137 20.8396 3.11141C21.678 4.2283 22.294 5.49634 22.6543 6.84639C23.0941 8.46578 23.3074 10.1385 23.2882 11.8167V26.0049H18.6352V11.8167C18.6535 10.6887 18.5185 9.56353 18.2339 8.47206C18.0062 7.59401 17.5893 6.77659 17.0125 6.07725C16.4653 5.43514 15.7679 4.9387 14.9826 4.6322C14.0713 4.29357 13.1045 4.12952 12.1327 4.14858C11.4001 4.14201 10.6694 4.22616 9.95741 4.39913C9.3142 4.55034 8.69547 4.79162 8.11947 5.11583C7.56909 5.42428 7.07055 5.81754 6.64215 6.28119C6.20318 6.75563 5.83845 7.29391 5.56033 7.87773C5.25808 8.51609 5.03538 9.18929 4.89727 9.88215C4.73587 10.668 4.65789 11.4688 4.66463 12.2711L4.65299 26.069Z" fill="#F2F1EE"/>
  <path d="M45.3439 0.693359H49.9969V26.0691H45.3439V18.6108H45.3788C45.1063 19.809 44.6657 20.9625 44.0701 22.0369C43.5346 23.0044 42.8361 23.8718 42.0054 24.6007C41.1973 25.3049 40.2667 25.854 39.2601 26.2206C38.1977 26.6083 37.0755 26.8055 35.9448 26.8033C34.6068 26.8286 33.2804 26.5497 32.0654 25.9875C30.9228 25.4567 29.9251 24.6571 29.1573 23.6568C28.319 22.5377 27.703 21.2678 27.3426 19.916C26.8995 18.2992 26.6861 16.628 26.7086 14.9515V0.763283H31.3616V14.9515C31.3434 16.0795 31.4784 17.2047 31.763 18.2961C31.9906 19.1742 32.4075 19.9916 32.9844 20.691C33.5304 21.3343 34.2281 21.831 35.0142 22.136C35.926 22.4726 36.8927 22.6347 37.8642 22.6138C38.5964 22.6193 39.3267 22.5371 40.0395 22.3691C40.6824 22.2168 41.301 21.9756 41.8774 21.6524C42.4278 21.3439 42.9263 20.9507 43.3547 20.487C43.7922 20.0114 44.1568 19.4734 44.4366 18.8905C44.7388 18.2521 44.9615 17.5789 45.0996 16.886C45.2581 16.0997 45.3361 15.2992 45.3322 14.4971L45.3439 0.693359Z" fill="#F2F1EE"/>
  <path d="M63.0595 10.9193V10.6746C64.0541 10.5697 64.3565 10.3367 64.6008 9.54421L65.5896 5.93742H61.2449L60.2386 9.66657C60.2085 9.77671 60.1929 9.89033 60.1921 10.0045C60.1921 10.4474 60.3608 10.5522 61.2798 10.6746V10.9193H57.5225V10.6746C58.3716 10.5231 58.4821 10.4299 58.7555 9.54421L60.6283 2.79094C60.7284 2.48577 60.7852 2.16802 60.797 1.847C60.797 1.40417 60.5876 1.26432 59.6338 1.18857V0.943848H63.7982V1.18857C62.885 1.24684 62.4895 1.53818 62.2569 2.31897L61.4368 5.29647H65.7815L66.4853 2.79094C66.5755 2.48359 66.6302 2.16687 66.6481 1.847C66.6481 1.41582 66.4678 1.26432 65.5838 1.18857V0.943848H69.3527V1.18857C68.4803 1.34007 68.3581 1.4333 68.0964 2.31897L66.084 9.66657C66.0561 9.78691 66.0424 9.91012 66.0433 10.0337C66.0433 10.459 66.241 10.5697 67.2065 10.6746V10.9193H63.0595Z" fill="#F2F1EE"/>
  <path d="M70.1032 11.0884C69.6437 11.0884 69.3994 10.7796 69.3994 10.2144C69.4487 9.64985 69.5619 9.09273 69.7368 8.55377L70.4696 5.8443C70.5442 5.62804 70.5893 5.40262 70.6034 5.17422C70.6034 4.87123 70.4696 4.82461 69.6437 4.80713V4.56241C70.4649 4.48585 71.2807 4.35937 72.0865 4.18367H72.133L70.7895 9.10149C70.6716 9.42755 70.5897 9.76558 70.5452 10.1095C70.5496 10.1719 70.577 10.2304 70.6222 10.2735C70.6674 10.3167 70.7271 10.3413 70.7895 10.3426C71.0512 10.3426 71.3711 10.0163 72.04 9.17724L72.2377 9.34621C71.3711 10.6281 70.8186 11.0884 70.1032 11.0884ZM72.1156 2.71531C72.0124 2.71679 71.91 2.69623 71.8154 2.655C71.7207 2.61377 71.6359 2.55283 71.5665 2.4762C71.4972 2.39958 71.445 2.30905 71.4133 2.21063C71.3816 2.1122 71.3712 2.00815 71.3828 1.90538C71.374 1.8046 71.3858 1.70308 71.4175 1.60701C71.4491 1.51094 71.5 1.42234 71.5669 1.34657C71.6338 1.27081 71.7154 1.20947 71.8068 1.1663C71.8981 1.12313 71.9972 1.09903 72.0982 1.09546C72.3085 1.11038 72.505 1.20588 72.6468 1.36212C72.7887 1.51837 72.8651 1.72335 72.8601 1.93452C72.8617 2.13611 72.7849 2.33041 72.6459 2.47619C72.5069 2.62196 72.3167 2.70774 72.1156 2.71531Z" fill="#F2F1EE"/>
  <path d="M75.5933 14.0659C74.052 14.0659 73.0458 13.3783 73.0283 12.3179C73.0283 11.6186 73.2901 11.3098 74.7732 10.3193C74.4824 10.1037 74.3486 9.91721 74.3486 9.73658C74.3486 9.36949 74.622 9.06649 75.5118 8.45468C74.6452 8.05845 74.3079 7.59814 74.2904 6.80569C74.2904 5.3723 75.6514 4.19528 77.344 4.19528C77.8733 4.18545 78.3936 4.33351 78.8387 4.62064C78.9063 4.6847 78.9913 4.72728 79.083 4.74301H80.0078V5.32569H79.2459C79.3326 5.60316 79.3796 5.89154 79.3855 6.18223C79.3855 7.34759 77.9779 8.612 76.5937 8.612C76.4355 8.61178 76.2777 8.59617 76.1226 8.56539C76.0858 8.56187 76.0488 8.56187 76.012 8.56539C75.8143 8.56539 75.4653 8.96161 75.4653 9.18885C75.4653 9.4161 75.8608 9.66082 76.5297 9.8298C77.9954 10.2144 78.7282 10.8845 78.7457 11.8459C78.7806 13.1627 77.5126 14.0659 75.5933 14.0659ZM75.6689 10.6572L75.2269 10.5232L75.1454 10.4882C74.7796 10.681 74.4538 10.9421 74.1857 11.2574C74.075 11.3813 73.9897 11.5259 73.9348 11.6828C73.8799 11.8398 73.8565 12.0061 73.8659 12.1722C73.8659 13.1511 74.5987 13.7279 75.8201 13.7279C77.0415 13.7279 77.8616 13.1453 77.8616 12.2945C77.8791 11.5895 77.2974 11.1641 75.6689 10.6572ZM78.2164 5.60537C78.2164 4.88285 77.9081 4.51576 77.344 4.51576C76.3552 4.51576 75.5002 5.73939 75.5177 7.14365C75.5177 7.86617 75.8492 8.30901 76.4308 8.30901C76.7189 8.28705 76.9953 8.1856 77.2294 8.0159C77.4635 7.8462 77.646 7.61488 77.7569 7.34759C78.0399 6.80923 78.197 6.21349 78.2164 5.60537Z" fill="#F2F1EE"/>
  <path d="M85.5806 11.0534C85.4917 11.0684 85.4005 11.0625 85.3142 11.0362C85.228 11.0099 85.149 10.9638 85.0836 10.9016C85.0181 10.8395 84.968 10.7629 84.9372 10.678C84.9064 10.5931 84.8957 10.5022 84.9059 10.4124C84.9703 9.93669 85.0734 9.46703 85.2142 9.00816L86.0517 5.84421C86.0972 5.67956 86.1322 5.51219 86.1564 5.3431C86.1476 5.23699 86.0988 5.13821 86.02 5.0668C85.9411 4.99539 85.8381 4.95668 85.7318 4.95853C85.1968 4.95853 84.1615 5.9083 83.3646 7.11445C82.7539 8.04674 82.5562 8.56532 81.9455 10.9194H80.7822L82.8412 3.09978C82.8788 2.90245 82.9293 2.7078 82.9924 2.5171C83.1097 2.18046 83.1821 1.82975 83.2076 1.4741C83.2076 1.0604 82.626 1.10701 82.1839 1.10701V0.879767C82.9902 0.789701 83.7904 0.651555 84.5802 0.466064L84.6558 0.541816L82.8237 7.38831C84.2429 5.15664 85.3247 4.16608 86.3135 4.16608C86.4484 4.15926 86.5833 4.18106 86.7092 4.23005C86.8351 4.27904 86.9493 4.35412 87.0443 4.45038C87.1393 4.54663 87.2129 4.66189 87.2604 4.78862C87.3078 4.91535 87.328 5.05068 87.3197 5.18577C87.1746 6.19068 86.9291 7.17844 86.5868 8.13414C86.3708 8.75768 86.2017 9.39652 86.0808 10.0453C86.0776 10.1139 86.1014 10.1811 86.1471 10.2323C86.1928 10.2835 86.2567 10.3147 86.3251 10.3192C86.5694 10.3192 86.7555 10.1502 87.5756 9.11304L87.7733 9.2995C86.8718 10.6455 86.3542 11.0534 85.5806 11.0534Z" fill="#F2F1EE"/>
  <path d="M95.4389 11.1933C94.8942 11.1777 94.3575 11.0591 93.8569 10.8437C93.6025 10.7383 93.334 10.6716 93.06 10.6456C92.7576 10.6456 92.5889 10.7971 92.4784 11.1467H92.1992L92.7227 7.74382H93.0251C93.0023 7.84311 92.9925 7.94498 92.996 8.04682C92.996 9.54431 93.9383 10.6281 95.2237 10.6281C95.4877 10.6315 95.7497 10.5809 95.9936 10.4795C96.2375 10.378 96.4582 10.2278 96.6422 10.038C96.8262 9.84825 96.9696 9.62289 97.0637 9.3757C97.1578 9.12851 97.2007 8.86469 97.1896 8.60036C97.1896 7.94193 96.9918 7.54571 96.2706 6.79405L95.689 6.15311C95.4912 5.92586 95.2935 5.71027 95.1073 5.51216C94.1942 4.53326 93.9441 4.09042 93.9441 3.26884C93.9407 2.91823 94.01 2.57073 94.1476 2.24831C94.2851 1.9259 94.488 1.63559 94.7432 1.39573C94.9985 1.15586 95.3007 0.971667 95.6307 0.854789C95.9606 0.737911 96.3112 0.690889 96.6603 0.716698C97.1017 0.708402 97.5409 0.779447 97.9573 0.926464C98.1769 1.02722 98.4139 1.0846 98.6553 1.09544C98.7967 1.11032 98.9393 1.08001 99.0626 1.00885C99.1858 0.937691 99.2835 0.829318 99.3416 0.699219H99.6964L99.0856 3.75829L98.8123 3.71168C98.7774 2.68616 98.6552 2.16757 98.3063 1.78883C98.1394 1.59812 97.9322 1.44692 97.6999 1.34615C97.4675 1.24539 97.2158 1.19757 96.9627 1.20615C96.7465 1.18057 96.5274 1.20241 96.3204 1.27017C96.1135 1.33793 95.9238 1.44998 95.7644 1.59855C95.605 1.74712 95.4799 1.92865 95.3975 2.13055C95.3152 2.33244 95.2777 2.54987 95.2876 2.76774C95.2876 3.51357 95.4098 3.72916 96.7999 5.09846C98.19 6.46776 98.5447 7.08539 98.5447 8.08178C98.5602 8.49456 98.4905 8.9061 98.3399 9.29063C98.1893 9.67517 97.9611 10.0244 97.6695 10.3165C97.378 10.6086 97.0294 10.8372 96.6455 10.9881C96.2617 11.139 95.8509 11.2088 95.4389 11.1933Z" fill="#F2F1EE"/>
  <path d="M102.267 11.0884C100.853 11.0884 100.027 10.2493 100.027 8.79259C100.027 6.40943 102.162 4.18359 104.448 4.18359C105.378 4.18359 106.053 4.70218 106.053 5.41887C106.055 5.51444 106.037 5.60934 106 5.69762C105.964 5.7859 105.909 5.86565 105.84 5.93186C105.772 5.99806 105.69 6.0493 105.6 6.08235C105.511 6.11541 105.415 6.12955 105.32 6.12392C105.242 6.13005 105.164 6.11919 105.09 6.09208C105.017 6.06496 104.951 6.02225 104.895 5.96687C104.84 5.91148 104.797 5.84476 104.77 5.77128C104.743 5.6978 104.732 5.61932 104.739 5.54124C104.74 5.38388 104.787 5.23027 104.872 5.0984C104.929 5.01132 104.961 4.91074 104.965 4.80706C104.965 4.62643 104.75 4.50407 104.384 4.50407C104.071 4.50833 103.763 4.57553 103.477 4.70167C103.192 4.8278 102.934 5.01029 102.72 5.23824C101.835 6.21053 101.34 7.47654 101.33 8.79259C101.33 9.89386 101.854 10.5406 102.72 10.5406C103.471 10.5406 104.064 10.2085 104.901 9.30535L105.146 9.45685C104.221 10.628 103.412 11.0884 102.267 11.0884Z" fill="#F2F1EE"/>
  <path d="M110.945 11.0534C110.856 11.0674 110.766 11.0608 110.68 11.034C110.595 11.0072 110.517 10.961 110.452 10.8989C110.387 10.8369 110.338 10.7607 110.307 10.6763C110.277 10.5919 110.266 10.5016 110.276 10.4124C110.337 9.93661 110.438 9.46684 110.579 9.00816L111.416 5.84421C111.465 5.68017 111.502 5.51266 111.527 5.3431C111.516 5.23649 111.466 5.13766 111.387 5.06637C111.307 4.99508 111.203 4.95657 111.096 4.95853C110.561 4.95853 109.526 5.9083 108.735 7.11445C108.124 8.04674 107.926 8.56532 107.316 10.9194H106.152L108.211 3.09978C108.249 2.90245 108.299 2.7078 108.363 2.5171C108.48 2.18046 108.552 1.82975 108.578 1.4741C108.578 1.0604 107.996 1.10701 107.56 1.10701V0.879767C108.364 0.789691 109.162 0.651544 109.95 0.466064L110.026 0.541816L108.2 7.38831C109.613 5.15664 110.701 4.16608 111.689 4.16608C111.824 4.15926 111.959 4.18106 112.085 4.23005C112.211 4.27904 112.325 4.35412 112.42 4.45038C112.515 4.54663 112.589 4.66189 112.636 4.78862C112.684 4.91535 112.704 5.05068 112.696 5.18577C112.553 6.19124 112.308 7.17929 111.963 8.13414C111.751 8.75839 111.583 9.39709 111.463 10.0453C111.46 10.0795 111.465 10.1138 111.476 10.1461C111.487 10.1785 111.504 10.2084 111.527 10.2339C111.55 10.2594 111.577 10.2801 111.608 10.2948C111.639 10.3094 111.673 10.3177 111.707 10.3192C111.951 10.3192 112.131 10.1502 112.957 9.11304L113.155 9.2995C112.242 10.6455 111.724 11.0534 110.945 11.0534Z" fill="#F2F1EE"/>
  <path d="M119.024 9.7598C118.658 10.1615 118.216 10.4859 117.724 10.714C117.231 10.9422 116.698 11.0694 116.156 11.0883C114.772 11.0883 113.917 10.2784 113.917 8.94988C113.968 8.05808 114.247 7.19442 114.728 6.44222C115.209 5.69002 115.875 5.07466 116.662 4.65552C117.211 4.34711 117.83 4.18463 118.459 4.18355C118.747 4.16534 119.034 4.2078 119.304 4.30819C119.574 4.40858 119.82 4.56465 120.025 4.76629C120.231 4.96792 120.392 5.21061 120.498 5.47862C120.604 5.74662 120.653 6.03396 120.641 6.32199C120.566 7.63447 119.987 8.8669 119.024 9.7598ZM118.279 4.51568C117.604 4.51568 116.936 4.98765 116.325 5.90246C115.631 6.93973 115.24 8.15062 115.197 9.39854C115.174 9.56787 115.186 9.74009 115.234 9.90423C115.281 10.0684 115.362 10.2208 115.472 10.3519C115.581 10.4829 115.717 10.5896 115.87 10.6653C116.022 10.7409 116.189 10.7838 116.36 10.7911C117.029 10.7911 117.622 10.3774 118.204 9.53838C118.885 8.46478 119.274 7.23144 119.332 5.96073C119.361 5.06923 118.931 4.50403 118.279 4.50403V4.51568Z" fill="#F2F1EE"/>
  <path d="M126.649 9.75993C126.283 10.1616 125.841 10.486 125.349 10.7142C124.856 10.9423 124.323 11.0696 123.781 11.0884C122.391 11.0884 121.536 10.2785 121.536 8.95001C121.588 8.0585 121.868 7.19529 122.348 6.44327C122.829 5.69125 123.495 5.07568 124.281 4.65565C124.832 4.34651 125.453 4.18402 126.084 4.18368C126.371 4.16631 126.658 4.20941 126.927 4.31018C127.196 4.41095 127.441 4.56715 127.646 4.7687C127.851 4.97024 128.012 5.21263 128.118 5.48022C128.223 5.7478 128.272 6.03461 128.26 6.32212C128.188 7.63391 127.61 8.86644 126.649 9.75993ZM125.898 4.51581C125.229 4.51581 124.555 4.98778 123.95 5.90259C123.256 6.93986 122.865 8.15075 122.822 9.39867C122.822 10.2377 123.293 10.7913 123.985 10.7913C124.677 10.7913 125.253 10.3776 125.829 9.53851C126.512 8.46607 126.901 7.23204 126.957 5.96085C126.98 5.06935 126.556 4.50415 125.898 4.50415V4.51581Z" fill="#F2F1EE"/>
  <path d="M130.121 11.0884C129.632 11.0884 129.376 10.7971 129.376 10.2319C129.375 10.0976 129.394 9.96385 129.434 9.83566L131.522 1.76554C131.558 1.67819 131.579 1.58577 131.586 1.49168C131.586 1.28192 131.278 1.14207 130.807 1.14207H130.545V0.897345C131.345 0.805295 132.14 0.667166 132.924 0.483643L133.005 0.559394L130.679 9.40448C130.604 9.64975 130.551 9.90134 130.522 10.1561C130.522 10.3368 130.603 10.43 130.755 10.43C131.028 10.43 131.301 10.2028 131.807 9.5618C131.929 9.39283 132.063 9.20637 132.203 9.03739L132.4 9.17724C131.429 10.6456 130.912 11.0884 130.121 11.0884Z" fill="#F2F1EE"/>
  <path d="M62.6354 26.1333C62.1092 26.1522 61.5848 26.0619 61.0951 25.868C60.6055 25.674 60.1612 25.3806 59.7903 25.0062C59.4193 24.6319 59.1297 24.1847 58.9397 23.6927C58.7497 23.2007 58.6634 22.6747 58.6862 22.1477C58.6862 18.739 61.5943 15.6858 64.8747 15.6858C65.478 15.6996 66.0774 15.7856 66.6603 15.9422C66.8633 16.0008 67.0719 16.0379 67.2826 16.0529C67.4025 16.0638 67.5229 16.0372 67.6271 15.9766C67.7313 15.9161 67.8141 15.8246 67.8642 15.7149H68.1841L67.6025 18.7565L67.3291 18.7099C67.1779 17.059 66.4024 16.2335 65.0026 16.2335C64.4974 16.2338 63.9974 16.3372 63.5334 16.5376C63.0694 16.738 62.651 17.031 62.3039 17.3989C61.0693 18.7336 60.3819 20.4853 60.3787 22.305C60.3787 24.2862 61.3559 25.434 63.0484 25.434C64.2989 25.434 65.1829 24.9912 66.5381 23.6336L66.7999 23.8433C65.4272 25.4748 64.2058 26.1333 62.6354 26.1333Z" fill="#F2F1EE"/>
  <path d="M73.3371 24.6999C72.9716 25.1016 72.5297 25.426 72.0373 25.6541C71.5449 25.8823 71.0119 26.0095 70.4697 26.0284C69.0796 26.0284 68.2305 25.2185 68.2305 23.89C68.28 22.9979 68.5588 22.1338 69.0398 21.3814C69.5208 20.629 70.1876 20.0139 70.9757 19.5956C71.5246 19.2867 72.1434 19.1242 72.773 19.1236C73.0595 19.1031 73.3472 19.1431 73.6174 19.2409C73.8876 19.3388 74.1342 19.4924 74.3414 19.6917C74.5487 19.8911 74.7118 20.1318 74.8203 20.3983C74.9289 20.6648 74.9804 20.9511 74.9715 21.2388C74.8987 22.562 74.3121 23.8042 73.3371 24.6999ZM72.5868 19.4558C71.918 19.4558 71.2491 19.9277 70.6384 20.8425C69.9445 21.8798 69.5537 23.0907 69.51 24.3386C69.51 25.1777 69.9812 25.7312 70.6733 25.7312C71.3654 25.7312 71.9412 25.3175 72.517 24.4785C73.2126 23.4094 73.6139 22.1752 73.6803 20.9008C73.6745 20.0093 73.2266 19.4441 72.5868 19.4441V19.4558Z" fill="#F2F1EE"/>
  <path d="M76.809 26.0283C76.3205 26.0283 76.0645 25.737 76.0645 25.1718C76.063 25.0374 76.0826 24.9037 76.1227 24.7755L78.2107 16.7054C78.2463 16.6181 78.2679 16.5256 78.2747 16.4316C78.2747 16.216 77.9664 16.0819 77.4953 16.0819H77.2394V15.8372C78.0417 15.7406 78.8378 15.5986 79.6241 15.4119L79.6997 15.4876L77.3732 24.3327C77.2996 24.578 77.2489 24.8296 77.222 25.0844C77.222 25.265 77.2976 25.3582 77.4488 25.3582C77.7222 25.3582 77.9955 25.131 78.5015 24.4842C78.6237 24.321 78.7575 24.1346 78.897 23.9656L79.0948 24.1055C78.1235 25.5855 77.6058 26.0283 76.809 26.0283Z" fill="#F2F1EE"/>
  <path d="M81.0491 26.0284C80.5606 26.0284 80.2988 25.737 80.2988 25.1718C80.2995 25.0372 80.3211 24.9036 80.3628 24.7756L82.4508 16.7055C82.4864 16.6181 82.508 16.5257 82.5148 16.4316C82.5148 16.216 82.2066 16.082 81.7354 16.082H81.4737V15.8373C82.2739 15.7452 83.0682 15.6071 83.8526 15.4236L83.9282 15.4993L81.6017 24.3444C81.5239 24.589 81.4713 24.8408 81.4446 25.0961C81.4446 25.2767 81.5202 25.3699 81.6773 25.3699C81.9506 25.3699 82.224 25.1427 82.73 24.4959C82.8522 24.3328 82.9859 24.1463 83.1255 23.9773L83.3233 24.1172C82.3578 25.5855 81.8401 26.0284 81.0491 26.0284Z" fill="#F2F1EE"/>
  <path d="M86.3999 26.0283C86.1264 26.0363 85.8544 25.9868 85.6012 25.8831C85.348 25.7794 85.1192 25.6238 84.9297 25.4262C84.7401 25.2286 84.594 24.9935 84.5006 24.7359C84.4072 24.4783 84.3687 24.204 84.3874 23.9306C84.3874 21.5999 86.7837 19.1235 88.9765 19.1235C89.7384 19.1235 90.1979 19.5198 90.1979 20.1607C90.1979 21.4717 88.5809 22.6371 86.2952 22.9576L85.8648 23.0158C85.7738 23.3186 85.7229 23.6321 85.7135 23.9481C85.7074 24.1341 85.7403 24.3193 85.8101 24.4918C85.88 24.6642 85.9852 24.82 86.119 24.9491C86.2528 25.0782 86.4122 25.1777 86.5868 25.2412C86.7614 25.3046 86.9474 25.3306 87.1327 25.3174C87.8016 25.3174 88.2959 25.0727 89.372 24.1521L89.5581 24.3327C89.1807 24.8224 88.7043 25.2266 88.16 25.5189C87.6157 25.8111 87.0158 25.9847 86.3999 26.0283ZM88.6566 19.4906C87.8772 19.4906 86.7488 20.656 86.2312 22.0253L85.9753 22.6954C87.2839 22.3749 87.8656 22.1127 88.4297 21.565C88.868 21.1862 89.1413 20.651 89.1917 20.0733C89.199 19.9989 89.1906 19.9238 89.167 19.8529C89.1434 19.7819 89.105 19.7168 89.0545 19.6618C89.004 19.6068 88.9424 19.5631 88.8738 19.5336C88.8052 19.5041 88.7312 19.4894 88.6566 19.4906Z" fill="#F2F1EE"/>
  <path d="M93.3795 29.0057C91.844 29.0057 90.8319 28.3182 90.8203 27.2577C90.8203 26.5526 91.0762 26.2496 92.5652 25.2591C92.2744 25.0435 92.1406 24.857 92.1406 24.6764C92.1406 24.3093 92.414 24.0063 93.3038 23.3945C92.4314 22.9983 92.0999 22.538 92.0824 21.7455C92.0824 20.3063 93.4376 19.1351 95.1301 19.1351C95.6597 19.1239 96.1804 19.272 96.6249 19.5605C96.6925 19.6245 96.7775 19.6671 96.8692 19.6828H97.7998V20.2655H97.0728C97.1551 20.5438 97.2001 20.8319 97.2066 21.1221C97.2066 22.2874 95.8048 23.5518 94.4206 23.5518C94.2605 23.5516 94.1008 23.536 93.9436 23.5052C93.9089 23.4999 93.8736 23.4999 93.8389 23.5052C93.6412 23.5052 93.2922 23.9073 93.2922 24.1345C93.2922 24.3618 93.6877 24.6065 94.3566 24.7755C95.8223 25.16 96.5551 25.8301 96.5668 26.7915C96.5668 28.1026 95.3279 29.0057 93.3795 29.0057ZM93.4551 25.597L93.013 25.463L92.9374 25.4281C92.5706 25.6194 92.2446 25.8807 91.9777 26.1972C91.867 26.3211 91.7817 26.4657 91.7268 26.6227C91.6719 26.7797 91.6485 26.9459 91.6579 27.112C91.6579 28.0909 92.3907 28.6678 93.6121 28.6678C94.8335 28.6678 95.6536 28.0851 95.6536 27.2344C95.6362 26.5293 95.072 26.104 93.4551 25.597ZM96.0026 20.5394C96.0026 19.8227 95.7001 19.4556 95.136 19.4556C94.1414 19.4556 93.2922 20.6792 93.3038 22.0835C93.3038 22.8002 93.6412 23.2488 94.2228 23.2488C94.5109 23.2269 94.7873 23.1254 95.0214 22.9557C95.2554 22.786 95.438 22.5547 95.5489 22.2874C95.8309 21.7469 95.9861 21.149 96.0026 20.5394Z" fill="#F2F1EE"/>
  <path d="M100.103 26.0283C99.8296 26.0363 99.5575 25.9868 99.3043 25.8831C99.0511 25.7794 98.8224 25.6238 98.6328 25.4262C98.4433 25.2286 98.2971 24.9935 98.2037 24.7359C98.1103 24.4783 98.0718 24.204 98.0906 23.9306C98.0906 21.5999 100.487 19.1235 102.68 19.1235C103.442 19.1235 103.901 19.5198 103.901 20.1607C103.901 21.4717 102.284 22.6371 99.9983 22.9576L99.5679 23.0158C99.4799 23.3193 99.4291 23.6324 99.4167 23.9481C99.4105 24.1341 99.4434 24.3193 99.5132 24.4918C99.5831 24.6642 99.6883 24.82 99.8221 24.9491C99.9559 25.0782 100.115 25.1777 100.29 25.2412C100.465 25.3046 100.651 25.3306 100.836 25.3174C101.505 25.3174 101.999 25.0727 103.075 24.1521L103.261 24.3327C102.884 24.8224 102.407 25.2266 101.863 25.5189C101.319 25.8111 100.719 25.9847 100.103 26.0283ZM102.36 19.4906C101.586 19.4906 100.458 20.656 99.9343 22.0253L99.6784 22.6954C100.987 22.3749 101.569 22.1127 102.133 21.565C102.569 21.1852 102.84 20.6501 102.889 20.0733C102.896 19.9994 102.888 19.9248 102.865 19.8543C102.841 19.7837 102.804 19.7189 102.754 19.664C102.704 19.609 102.643 19.5652 102.575 19.5354C102.507 19.5055 102.434 19.4903 102.36 19.4906Z" fill="#F2F1EE"/>
</g>
<defs>
  <clipPath id="clip0_76_4619">
    <rect width="133" height="29" fill="white"/>
  </clipPath>
</defs>
</svg>