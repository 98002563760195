export const next = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        // width="113"
        height="24"
        viewBox="0 0 113 24"
        fill="none"
    >
        <path
            d="M7.3295 19.0487C7.23731 18.9929 7.23731 18.9 7.31106 18.8442C7.86419 18.4538 8.21451 17.8775 8.21451 17.041C8.21451 15.5909 7.21887 14.5127 5.26446 14.5127H1.04219C0.89469 14.5127 0.765625 14.6428 0.765625 14.7915V23.3802C0.765625 23.5289 0.89469 23.6591 1.04219 23.6591H5.67009C7.51388 23.6591 8.62014 22.5994 8.62014 21.075C8.62014 20.0898 8.12232 19.4205 7.3295 19.0487ZM3.08879 16.8737C3.08879 16.7435 3.19942 16.632 3.32848 16.632H5.13539C5.61478 16.632 5.87291 16.9294 5.87291 17.357C5.87291 17.7846 5.63321 18.082 5.00633 18.082H3.32848C3.19942 18.082 3.08879 17.9705 3.08879 17.8403V16.8737ZM5.41196 21.5398H3.32848C3.19942 21.5398 3.08879 21.4283 3.08879 21.2981V20.1641C3.08879 20.034 3.19942 19.9224 3.32848 19.9224H5.4304C6.05729 19.9224 6.29698 20.2385 6.29698 20.7032C6.27854 21.1866 6.03885 21.5398 5.41196 21.5398Z"
            fill="#F4F1EA"
        />
        <path
            d="M16.3818 21.0936C16.308 20.9821 16.3264 20.852 16.4555 20.7962C17.5433 20.2013 18.0412 19.0673 18.0412 17.8589C18.0412 16.0371 16.9718 14.5127 14.6486 14.5127H10.4816C10.3341 14.5127 10.2051 14.6428 10.2051 14.7915V23.3802C10.2051 23.5289 10.3341 23.6591 10.4816 23.6591H12.2332C12.3807 23.6591 12.5098 23.5289 12.5098 23.3802V21.4468C12.5098 21.3167 12.6204 21.2052 12.7495 21.2052H13.4686C13.6161 21.2052 13.7636 21.2795 13.8373 21.3911L15.257 23.4174C15.3677 23.5661 15.5336 23.6591 15.6996 23.6591H17.9121C18.0227 23.6591 18.0965 23.5289 18.0227 23.4174L16.3818 21.0936ZM14.5195 19.0859H12.7679C12.6389 19.0859 12.5282 18.9743 12.5282 18.8442V16.8737C12.5282 16.7435 12.6389 16.632 12.7679 16.632H14.5195C15.4414 16.632 15.7917 17.1525 15.7917 17.8589C15.7917 18.5096 15.4414 19.0859 14.5195 19.0859Z"
            fill="#F4F1EA"
        />
        <path
            d="M24.9188 14.773C24.8635 14.6242 24.6976 14.5127 24.5316 14.5127H22.78C22.6141 14.5127 22.4482 14.6242 22.3928 14.773L18.9265 23.3988C18.8712 23.5104 18.9634 23.6591 19.0925 23.6591H21.0653C21.2313 23.6591 21.3788 23.5475 21.4341 23.3988L21.766 22.5437C21.8213 22.4135 21.9319 22.3206 22.0794 22.3206H25.1954C25.3429 22.3206 25.472 22.4135 25.5088 22.5437L25.8407 23.3988C25.896 23.5475 26.0435 23.6591 26.2095 23.6591H28.1823C28.3298 23.6591 28.4036 23.5104 28.3483 23.3988L24.9188 14.773ZM24.4026 20.2013H22.8722C22.7616 20.2013 22.6879 20.0898 22.7247 19.9782L23.5544 17.7474C23.5729 17.673 23.7019 17.673 23.7204 17.7474L24.5501 19.9782C24.6054 20.0712 24.5316 20.2013 24.4026 20.2013Z"
            fill="#F4F1EA"
        />
        <path
            d="M31.7966 14.5127H30.0266C29.8791 14.5127 29.75 14.6428 29.75 14.7915V23.3802C29.75 23.5289 29.8791 23.6591 30.0266 23.6591H31.7966C31.9441 23.6591 32.0732 23.5289 32.0732 23.3802V14.7915C32.0732 14.6428 31.9441 14.5127 31.7966 14.5127Z"
            fill="#F4F1EA"
        />
        <path
            d="M42.1033 14.5127H40.3518C40.2043 14.5127 40.0752 14.6428 40.0752 14.7915V19.3833C40.0752 19.4577 39.983 19.4949 39.9277 19.4391L36.2032 14.7358C36.0926 14.5871 35.9267 14.5127 35.7423 14.5127H34.341C34.1935 14.5127 34.0645 14.6428 34.0645 14.7915V23.3802C34.0645 23.5289 34.1935 23.6591 34.341 23.6591H36.0926C36.2401 23.6591 36.3692 23.5289 36.3692 23.3802V18.7884C36.3692 18.7141 36.4614 18.6769 36.5167 18.7327L40.2596 23.4174C40.3702 23.5661 40.5361 23.6405 40.7205 23.6405H42.1218C42.2693 23.6405 42.3984 23.5104 42.3984 23.3616V14.7915C42.3799 14.6428 42.2509 14.5127 42.1033 14.5127Z"
            fill="#F4F1EA"
        />
        <path
            d="M48.0581 17.9148C46.9703 17.543 46.5647 17.3571 46.5647 16.9853C46.5647 16.6878 46.8781 16.3718 47.6709 16.3718C48.4453 16.3718 48.8878 16.725 49.0169 17.1154C49.0906 17.3199 49.1828 17.4314 49.4041 17.4314H51.0266C51.1926 17.4314 51.3401 17.2641 51.3216 17.0968C51.211 15.4609 49.736 14.3455 47.6156 14.3455C45.5506 14.3455 44.0755 15.4423 44.0755 17.1898C44.0755 19.086 45.9746 19.7738 47.4128 20.2571C48.5006 20.6289 48.9616 20.8706 48.9616 21.261C48.9616 21.5399 48.685 21.8187 47.8184 21.8187C47.0072 21.8187 46.5278 21.5399 46.3618 21.2238C46.2143 20.9822 46.159 20.9264 45.9009 20.9264H44.3337C44.1677 20.9264 44.0202 21.0937 44.0387 21.261C44.2046 22.9341 45.8271 23.8451 47.8737 23.8451C49.9388 23.8451 51.4876 22.9155 51.4876 21.1309C51.4507 19.1046 49.3119 18.3609 48.0581 17.9148Z"
            fill="#F4F1EA"
        />
        <path
            d="M60.2081 14.5127H52.7961C52.6486 14.5127 52.5195 14.6428 52.5195 14.7915V16.5018C52.5195 16.6506 52.6486 16.7807 52.7961 16.7807H55.1193C55.2483 16.7807 55.359 16.8922 55.359 17.0224V23.3802C55.359 23.5289 55.488 23.6591 55.6355 23.6591H57.4056C57.5531 23.6591 57.6821 23.5289 57.6821 23.3802V17.0224C57.6821 16.8922 57.7928 16.7807 57.9218 16.7807H60.245C60.3925 16.7807 60.5216 16.6506 60.5216 16.5018V14.7915C60.5031 14.6428 60.374 14.5127 60.2081 14.5127Z"
            fill="#F4F1EA"
        />
        <path
            d="M68.5992 14.5127H62.1828C62.0353 14.5127 61.9062 14.6428 61.9062 14.7915V23.3802C61.9062 23.5289 62.0353 23.6591 62.1828 23.6591H68.5992C68.7467 23.6591 68.8757 23.5289 68.8757 23.3802V21.6885C68.8757 21.5398 68.7467 21.4097 68.5992 21.4097H64.4691C64.34 21.4097 64.2294 21.2981 64.2294 21.168V20.4616C64.2294 20.3314 64.34 20.2199 64.4691 20.2199H67.7695C67.917 20.2199 68.046 20.0898 68.046 19.941V18.3795C68.046 18.2307 67.917 18.1006 67.7695 18.1006H64.4691C64.34 18.1006 64.2294 17.9891 64.2294 17.8589V17.0224C64.2294 16.8922 64.34 16.7807 64.4691 16.7807H68.5992C68.7467 16.7807 68.8757 16.6506 68.8757 16.5018V14.7915C68.8757 14.6428 68.7467 14.5127 68.5992 14.5127Z"
            fill="#F4F1EA"
        />
        <path
            d="M78.426 23.4174L76.7666 21.0751C76.6929 20.9635 76.7113 20.8334 76.8404 20.7776C77.9282 20.1827 78.426 19.0487 78.426 17.8404C78.426 16.0185 77.3567 14.4941 75.0335 14.4941H70.885C70.7375 14.4941 70.6084 14.6243 70.6084 14.773V23.3617C70.6084 23.5104 70.7375 23.6405 70.885 23.6405H72.6366C72.7841 23.6405 72.9131 23.5104 72.9131 23.3617V21.4283C72.9131 21.2982 73.0238 21.1866 73.1528 21.1866H73.8719C74.0194 21.1866 74.1669 21.261 74.2407 21.3725L75.6604 23.3988C75.771 23.5476 75.9369 23.6405 76.1029 23.6405H78.3154C78.426 23.6591 78.4998 23.529 78.426 23.4174ZM74.9044 19.0859H73.1344C73.0053 19.0859 72.8947 18.9744 72.8947 18.8442V16.8737C72.8947 16.7436 73.0053 16.632 73.1344 16.632H74.886C75.8079 16.632 76.1582 17.1525 76.1582 17.859C76.1766 18.5096 75.8263 19.0859 74.9044 19.0859Z"
            fill="#F4F1EA"
        />
        <path
            d="M87.8479 14.5498V23.6962H85.9488L81.579 14.996V23.6962H80.4912V14.5498H82.3903L86.7601 23.25V14.5498H87.8479Z"
            fill="#F4F1EA"
        />
        <path
            d="M90.7974 15.4792V18.4536H95.2225V19.3831H90.7974V22.7665H95.7388V23.6961H89.6543V14.5497H95.7388V15.4792H90.7974Z"
            fill="#F4F1EA"
        />
        <path
            d="M101.75 18.9743L104.515 23.6776H103.243L100.754 19.2718L98.1174 23.6776H96.9189L99.7584 18.9743L97.048 14.5312H98.3202L100.772 18.6769L103.188 14.5312H104.423L101.75 18.9743Z"
            fill="#F4F1EA"
        />
        <path
            d="M112.481 15.535H109.642V23.6775H108.499V15.535H105.678V14.5497H112.481V15.535Z"
            fill="#F4F1EA"
        />
        <path
            d="M1.65567 0.4646H2.08933L3.04906 3.00542H2.54431L2.30971 2.372H1.42818L1.19713 3.00542H0.692383L1.65567 0.4646ZM2.22084 2.02488L1.8725 1.02644L1.50993 2.02488H2.22084Z"
            fill="#F4F1EA"
        />
        <path
            d="M3.93014 1.50956H4.1292L4.89698 0.4646H5.4515L4.54508 1.69206L5.53325 3.00542H4.98585L4.17541 1.9533H3.93014V3.00542H3.44317V0.4646H3.93014V1.50956Z"
            fill="#F4F1EA"
        />
        <path
            d="M5.87207 3.00542V0.4646H6.94199C7.05811 0.4646 7.16475 0.48965 7.26191 0.539751C7.36143 0.587465 7.44674 0.65188 7.51783 0.732996C7.58892 0.811725 7.64461 0.902383 7.6849 1.00497C7.72518 1.10517 7.74533 1.20657 7.74533 1.30915C7.74533 1.41651 7.72637 1.52148 7.68845 1.62407C7.65054 1.72427 7.59722 1.81374 7.5285 1.89247C7.45977 1.9712 7.37684 2.03442 7.27968 2.08213C7.18252 2.12985 7.07588 2.15371 6.95977 2.15371H6.3626V3.00542H5.87207ZM6.3626 1.72069H6.93133C7.02138 1.72069 7.09603 1.68371 7.15527 1.60976C7.21688 1.5358 7.24769 1.4356 7.24769 1.30915C7.24769 1.24474 7.23821 1.18748 7.21925 1.13738C7.20029 1.08489 7.17541 1.04076 7.1446 1.00497C7.1138 0.969184 7.07707 0.942941 7.03441 0.926241C6.99413 0.907155 6.95266 0.897612 6.91 0.897612H6.3626V1.72069Z"
            fill="#F4F1EA"
        />
        <path
            d="M9.95618 2.5724V3.00542H8.20378V0.4646H9.92419V0.897612H8.69431V1.50956H9.75712V1.91036H8.69431V2.5724H9.95618Z"
            fill="#F4F1EA"
        />
        <path
            d="M10.2424 2.5724H10.3312C10.4 2.57002 10.4604 2.54974 10.5125 2.51157C10.5647 2.4734 10.6109 2.40302 10.6512 2.30043C10.6914 2.19784 10.727 2.05708 10.7578 1.87815C10.7886 1.69684 10.817 1.46423 10.8431 1.18032L10.9071 0.4646H12.4355V2.5724H12.8088V3.63883H12.3502V3.00542H10.7045V3.63883H10.2424V2.5724ZM11.945 2.5724V0.897612H11.3514L11.3159 1.2841C11.2874 1.61572 11.2471 1.88531 11.195 2.09287C11.1452 2.30043 11.0801 2.46027 10.9995 2.5724H11.945Z"
            fill="#F4F1EA"
        />
        <path
            d="M13.7395 2.24675L15.0262 0.4646H15.3995V3.00542H14.9125V1.26263L13.6435 3.00542H13.2525V0.4646H13.7395V2.24675Z"
            fill="#F4F1EA"
        />
        <path
            d="M17.9296 0.897612H17.1228V3.00542H16.6358V0.897612H15.8289V0.4646H17.9296V0.897612Z"
            fill="#F4F1EA"
        />
        <path
            d="M18.8472 2.24675L20.134 0.4646H20.5072V3.00542H20.0202V1.26263L18.7512 3.00542H18.3602V0.4646H18.8472V2.24675Z"
            fill="#F4F1EA"
        />
        <path
            d="M21.1606 3.00542V0.4646H22.2305C22.3466 0.4646 22.4532 0.48965 22.5504 0.539751C22.6499 0.587465 22.7352 0.65188 22.8063 0.732996C22.8774 0.811725 22.9331 0.902383 22.9734 1.00497C23.0137 1.10517 23.0338 1.20657 23.0338 1.30915C23.0338 1.41651 23.0149 1.52148 22.977 1.62407C22.939 1.72427 22.8857 1.81374 22.817 1.89247C22.7483 1.9712 22.6653 2.03442 22.5682 2.08213C22.471 2.12985 22.3644 2.15371 22.2483 2.15371H21.6511V3.00542H21.1606ZM21.6511 1.72069H22.2198C22.3099 1.72069 22.3845 1.68371 22.4438 1.60976C22.5054 1.5358 22.5362 1.4356 22.5362 1.30915C22.5362 1.24474 22.5267 1.18748 22.5077 1.13738C22.4888 1.08489 22.4639 1.04076 22.4331 1.00497C22.4023 0.969184 22.3656 0.942941 22.3229 0.926241C22.2826 0.907155 22.2412 0.897612 22.1985 0.897612H21.6511V1.72069Z"
            fill="#F4F1EA"
        />
        <path
            d="M24.04 0.4646H24.4736L25.4333 3.00542H24.9286L24.694 2.372H23.8125L23.5814 3.00542H23.0767L24.04 0.4646ZM24.6051 2.02488L24.2568 1.02644L23.8942 2.02488H24.6051Z"
            fill="#F4F1EA"
        />
        <path
            d="M27.9495 0.4646V3.00542H27.4626V1.9211H26.318V3.00542H25.8275V0.4646H26.318V1.49166H27.4626V0.4646H27.9495Z"
            fill="#F4F1EA"
        />
        <path
            d="M29.3108 0.4646H29.7445L30.7042 3.00542H30.1995L29.9649 2.372H29.0834L28.8523 3.00542H28.3476L29.3108 0.4646ZM29.876 2.02488L29.5277 1.02644L29.1651 2.02488H29.876Z"
            fill="#F4F1EA"
        />
        <path
            d="M2.94242 6.25447C2.94242 6.36183 2.92109 6.45607 2.87844 6.53718C2.83578 6.6183 2.77654 6.68629 2.70071 6.74116C2.62725 6.79604 2.54075 6.83779 2.44123 6.86642C2.34407 6.89504 2.2398 6.90936 2.12843 6.90936H0.909211V4.36854H2.25994C2.34762 4.36854 2.42701 4.38763 2.4981 4.4258C2.56919 4.46397 2.62962 4.51407 2.67938 4.5761C2.72915 4.63575 2.76706 4.70493 2.79313 4.78366C2.82156 4.86001 2.83578 4.93754 2.83578 5.01627C2.83578 5.14033 2.80498 5.25723 2.74336 5.36698C2.68175 5.47433 2.5917 5.55426 2.47322 5.60674C2.61777 5.64969 2.73152 5.72722 2.81446 5.83935C2.89977 5.9491 2.94242 6.08747 2.94242 6.25447ZM2.44834 6.16143C2.44834 6.1161 2.44004 6.07316 2.42345 6.0326C2.40924 5.98966 2.38909 5.95268 2.36303 5.92166C2.33696 5.89065 2.30615 5.86679 2.27061 5.85009C2.23506 5.831 2.19596 5.82146 2.15331 5.82146H1.39974V6.49066H2.12843C2.17345 6.49066 2.21492 6.48231 2.25284 6.46561C2.29312 6.44891 2.32748 6.42625 2.35592 6.39762C2.38435 6.3666 2.40687 6.33082 2.42345 6.29026C2.44004 6.2497 2.44834 6.20676 2.44834 6.16143ZM1.39974 4.79082V5.43139H2.05378C2.13672 5.43139 2.20663 5.40276 2.2635 5.3455C2.32274 5.28825 2.35236 5.20952 2.35236 5.10932C2.35236 5.0115 2.32511 4.93396 2.27061 4.87671C2.21847 4.81945 2.15331 4.79082 2.07511 4.79082H1.39974Z"
            fill="#F4F1EA"
        />
        <path
            d="M3.96486 6.15069L5.25161 4.36854H5.62484V6.90936H5.13786V5.16657L3.86888 6.90936H3.47788V4.36854H3.96486V6.15069Z"
            fill="#F4F1EA"
        />
        <path
            d="M6.1147 5.62106C6.1147 5.46598 6.14195 5.3133 6.19645 5.163C6.25333 5.01031 6.33508 4.87432 6.44172 4.75503C6.54836 4.63575 6.67869 4.53912 6.83272 4.46517C6.98675 4.39121 7.16211 4.35423 7.35879 4.35423C7.59103 4.35423 7.79127 4.40552 7.95952 4.50811C8.13013 4.6107 8.25691 4.7443 8.33985 4.90891L7.96307 5.17015C7.92989 5.09858 7.88961 5.03894 7.84221 4.99122C7.79482 4.94112 7.74387 4.90176 7.68937 4.87313C7.63487 4.8445 7.57799 4.82422 7.51875 4.81229C7.45951 4.79798 7.40145 4.79082 7.34458 4.79082C7.22135 4.79082 7.11353 4.81587 7.02111 4.86597C6.93106 4.91607 6.85523 4.98168 6.79362 5.06279C6.73438 5.14152 6.68935 5.23218 6.65855 5.33477C6.62774 5.43497 6.61234 5.53517 6.61234 5.63537C6.61234 5.7475 6.63011 5.85605 6.66566 5.96103C6.7012 6.06361 6.75096 6.15427 6.81495 6.233C6.8813 6.31173 6.9595 6.37495 7.04955 6.42267C7.14197 6.47038 7.24268 6.49424 7.35169 6.49424C7.40856 6.49424 7.4678 6.48708 7.52941 6.47277C7.59103 6.45845 7.64908 6.43579 7.70359 6.40477C7.76046 6.37376 7.81259 6.33439 7.85999 6.28668C7.90738 6.23658 7.9453 6.17694 7.97373 6.10775L8.3754 6.34036C8.33511 6.43579 8.27706 6.52048 8.20123 6.59444C8.12776 6.66601 8.04364 6.72685 7.94885 6.77695C7.85643 6.82705 7.75691 6.86522 7.65027 6.89147C7.54363 6.91771 7.43936 6.93083 7.33747 6.93083C7.15737 6.93083 6.99267 6.89266 6.84338 6.81632C6.69409 6.73997 6.56494 6.64096 6.45594 6.51929C6.3493 6.39523 6.26517 6.25567 6.20356 6.10059C6.14432 5.94313 6.1147 5.78329 6.1147 5.62106Z"
            fill="#F4F1EA"
        />
        <path
            d="M9.86382 6.92725C9.67898 6.92725 9.51073 6.89147 9.35907 6.81989C9.20978 6.74594 9.08063 6.64931 8.97162 6.53003C8.86498 6.40835 8.78204 6.26998 8.7228 6.11491C8.66356 5.95983 8.63394 5.80118 8.63394 5.63895C8.63394 5.46956 8.66474 5.30733 8.72636 5.15226C8.79034 4.99719 8.87683 4.86001 8.98584 4.74072C9.09722 4.62143 9.22873 4.52719 9.3804 4.45801C9.53206 4.38644 9.69675 4.35065 9.87448 4.35065C10.0569 4.35065 10.2228 4.38882 10.3721 4.46517C10.5238 4.53912 10.6529 4.63694 10.7596 4.75861C10.8662 4.88028 10.9491 5.01866 11.0084 5.17373C11.0676 5.3288 11.0972 5.48626 11.0972 5.64611C11.0972 5.8155 11.0664 5.97773 11.0048 6.1328C10.9432 6.28549 10.8579 6.42147 10.7489 6.54076C10.6399 6.65766 10.5096 6.7519 10.3579 6.82347C10.2062 6.89266 10.0415 6.92725 9.86382 6.92725ZM9.13158 5.63895C9.13158 5.74869 9.14816 5.85486 9.18134 5.95745C9.21452 6.06003 9.26191 6.15069 9.32352 6.22942C9.3875 6.30815 9.46452 6.37137 9.55457 6.41909C9.64699 6.4668 9.75126 6.49066 9.86737 6.49066C9.98586 6.49066 10.0901 6.4668 10.1802 6.41909C10.2726 6.36899 10.3496 6.30338 10.4112 6.22227C10.4752 6.14115 10.5226 6.05049 10.5534 5.95029C10.5866 5.8477 10.6032 5.74392 10.6032 5.63895C10.6032 5.52921 10.5854 5.42304 10.5498 5.32045C10.5167 5.21787 10.4681 5.12721 10.4041 5.04848C10.3425 4.96975 10.2655 4.90772 10.1731 4.86239C10.083 4.81468 9.98112 4.79082 9.86737 4.79082C9.74889 4.79082 9.64343 4.81587 9.55101 4.86597C9.4586 4.91369 9.38158 4.9781 9.31997 5.05922C9.25836 5.13794 9.21096 5.2286 9.17778 5.33119C9.14698 5.43139 9.13158 5.53398 9.13158 5.63895Z"
            fill="#F4F1EA"
        />
        <path
            d="M12.0743 5.4135H12.2733L13.0411 4.36854H13.5956L12.6892 5.59601L13.6774 6.90936H13.13L12.3195 5.85725H12.0743V6.90936H11.5873V4.36854H12.0743V5.4135Z"
            fill="#F4F1EA"
        />
        <path
            d="M14.982 6.92725C14.7971 6.92725 14.6289 6.89147 14.4772 6.81989C14.3279 6.74594 14.1988 6.64931 14.0898 6.53003C13.9831 6.40835 13.9002 6.26998 13.841 6.11491C13.7817 5.95983 13.7521 5.80118 13.7521 5.63895C13.7521 5.46956 13.7829 5.30733 13.8445 5.15226C13.9085 4.99719 13.995 4.86001 14.104 4.74072C14.2154 4.62143 14.3469 4.52719 14.4985 4.45801C14.6502 4.38644 14.8149 4.35065 14.9926 4.35065C15.1751 4.35065 15.341 4.38882 15.4903 4.46517C15.6419 4.53912 15.7711 4.63694 15.8777 4.75861C15.9844 4.88028 16.0673 5.01866 16.1265 5.17373C16.1858 5.3288 16.2154 5.48626 16.2154 5.64611C16.2154 5.8155 16.1846 5.97773 16.123 6.1328C16.0614 6.28549 15.9761 6.42147 15.8671 6.54076C15.758 6.65766 15.6277 6.7519 15.4761 6.82347C15.3244 6.89266 15.1597 6.92725 14.982 6.92725ZM14.2497 5.63895C14.2497 5.74869 14.2663 5.85486 14.2995 5.95745C14.3327 6.06003 14.3801 6.15069 14.4417 6.22942C14.5057 6.30815 14.5827 6.37137 14.6727 6.41909C14.7651 6.4668 14.8694 6.49066 14.9855 6.49066C15.104 6.49066 15.2083 6.4668 15.2983 6.41909C15.3907 6.36899 15.4678 6.30338 15.5294 6.22227C15.5934 6.14115 15.6407 6.05049 15.6716 5.95029C15.7047 5.8477 15.7213 5.74392 15.7213 5.63895C15.7213 5.52921 15.7035 5.42304 15.668 5.32045C15.6348 5.21787 15.5862 5.12721 15.5223 5.04848C15.4606 4.96975 15.3836 4.90772 15.2912 4.86239C15.2012 4.81468 15.0993 4.79082 14.9855 4.79082C14.867 4.79082 14.7616 4.81587 14.6692 4.86597C14.5767 4.91369 14.4997 4.9781 14.4381 5.05922C14.3765 5.13794 14.3291 5.2286 14.2959 5.33119C14.2651 5.43139 14.2497 5.53398 14.2497 5.63895Z"
            fill="#F4F1EA"
        />
        <path
            d="M17.7754 6.92725C17.5905 6.92725 17.4223 6.89147 17.2706 6.81989C17.1213 6.74594 16.9922 6.64931 16.8832 6.53003C16.7765 6.40835 16.6936 6.26998 16.6343 6.11491C16.5751 5.95983 16.5455 5.80118 16.5455 5.63895C16.5455 5.46956 16.5763 5.30733 16.6379 5.15226C16.7019 4.99719 16.7884 4.86001 16.8974 4.74072C17.0088 4.62143 17.1403 4.52719 17.2919 4.45801C17.4436 4.38644 17.6083 4.35065 17.786 4.35065C17.9685 4.35065 18.1344 4.38882 18.2837 4.46517C18.4353 4.53912 18.5645 4.63694 18.6711 4.75861C18.7777 4.88028 18.8607 5.01866 18.9199 5.17373C18.9792 5.3288 19.0088 5.48626 19.0088 5.64611C19.0088 5.8155 18.978 5.97773 18.9164 6.1328C18.8548 6.28549 18.7694 6.42147 18.6604 6.54076C18.5514 6.65766 18.4211 6.7519 18.2694 6.82347C18.1178 6.89266 17.9531 6.92725 17.7754 6.92725ZM17.0431 5.63895C17.0431 5.74869 17.0597 5.85486 17.0929 5.95745C17.1261 6.06003 17.1734 6.15069 17.2351 6.22942C17.299 6.30815 17.3761 6.37137 17.4661 6.41909C17.5585 6.4668 17.6628 6.49066 17.7789 6.49066C17.8974 6.49066 18.0017 6.4668 18.0917 6.41909C18.1841 6.36899 18.2611 6.30338 18.3228 6.22227C18.3867 6.14115 18.4341 6.05049 18.4649 5.95029C18.4981 5.8477 18.5147 5.74392 18.5147 5.63895C18.5147 5.52921 18.4969 5.42304 18.4614 5.32045C18.4282 5.21787 18.3796 5.12721 18.3156 5.04848C18.254 4.96975 18.177 4.90772 18.0846 4.86239C17.9945 4.81468 17.8927 4.79082 17.7789 4.79082C17.6604 4.79082 17.555 4.81587 17.4625 4.86597C17.3701 4.91369 17.2931 4.9781 17.2315 5.05922C17.1699 5.13794 17.1225 5.2286 17.0893 5.33119C17.0585 5.43139 17.0431 5.53398 17.0431 5.63895Z"
            fill="#F4F1EA"
        />
        <path
            d="M19.4988 4.36854H21.3436V4.80156H19.9858V5.38487H20.6825C20.8057 5.38487 20.9171 5.40157 21.0166 5.43497C21.1185 5.46837 21.205 5.51728 21.2761 5.58169C21.3496 5.64611 21.4052 5.72484 21.4432 5.81788C21.4834 5.91093 21.5036 6.01828 21.5036 6.13996C21.5036 6.38807 21.4301 6.57893 21.2832 6.71254C21.1387 6.84375 20.9443 6.90936 20.7003 6.90936H19.4988V4.36854ZM20.654 6.49066C20.7702 6.49066 20.8578 6.45965 20.9171 6.39762C20.9763 6.3332 21.0059 6.24612 21.0059 6.13638C21.0059 6.03141 20.9763 5.94671 20.9171 5.8823C20.8578 5.81788 20.7702 5.78567 20.654 5.78567H19.9858V6.49066H20.654Z"
            fill="#F4F1EA"
        />
        <path
            d="M21.9798 6.90936V4.36854H23.0497C23.1658 4.36854 23.2725 4.39359 23.3696 4.44369C23.4691 4.49141 23.5545 4.55582 23.6255 4.63694C23.6966 4.71567 23.7523 4.80633 23.7926 4.90891C23.8329 5.00911 23.853 5.11051 23.853 5.2131C23.853 5.32045 23.8341 5.42543 23.7962 5.52801C23.7583 5.62821 23.7049 5.71768 23.6362 5.79641C23.5675 5.87514 23.4846 5.93836 23.3874 5.98608C23.2902 6.03379 23.1836 6.05765 23.0675 6.05765H22.4703V6.90936H21.9798ZM22.4703 5.62464H23.039C23.1291 5.62464 23.2037 5.58766 23.263 5.5137C23.3246 5.43974 23.3554 5.33954 23.3554 5.2131C23.3554 5.14868 23.3459 5.09142 23.327 5.04132C23.308 4.98884 23.2831 4.9447 23.2523 4.90891C23.2215 4.87313 23.1848 4.84688 23.1421 4.83018C23.1018 4.8111 23.0604 4.80156 23.0177 4.80156H22.4703V5.62464Z"
            fill="#F4F1EA"
        />
        <path
            d="M24.8592 4.36854H25.2928L26.2526 6.90936H25.7478L25.5132 6.27594H24.6317L24.4006 6.90936H23.8959L24.8592 4.36854ZM25.4244 5.92882L25.076 4.93039L24.7134 5.92882H25.4244Z"
            fill="#F4F1EA"
        />
        <path
            d="M26.4903 4.6942C26.5922 4.57968 26.7095 4.49379 26.8422 4.43654C26.9749 4.37689 27.1336 4.34707 27.3185 4.34707C27.5602 4.34707 27.751 4.40075 27.8908 4.50811C28.033 4.61547 28.104 4.77293 28.104 4.98049C28.104 5.10932 28.0721 5.22622 28.0081 5.33119C27.9465 5.43378 27.8481 5.50654 27.713 5.54949C27.8694 5.5805 27.9903 5.65088 28.0756 5.76062C28.1609 5.86798 28.2036 6.00158 28.2036 6.16143C28.2036 6.28072 28.1799 6.38807 28.1325 6.4835C28.0875 6.57655 28.0247 6.65647 27.9441 6.72327C27.8635 6.78769 27.7664 6.83779 27.6526 6.87357C27.5412 6.90936 27.4204 6.92725 27.29 6.92725C27.1076 6.92725 26.9464 6.89743 26.8066 6.83779C26.6668 6.77576 26.5424 6.68391 26.4334 6.56223L26.7178 6.21869C26.7889 6.30219 26.8682 6.3678 26.9559 6.41551C27.046 6.46322 27.1538 6.48708 27.2794 6.48708C27.405 6.48708 27.5081 6.45368 27.5886 6.38688C27.6692 6.32008 27.7095 6.22704 27.7095 6.10775C27.7095 5.99801 27.6668 5.90735 27.5815 5.83578C27.4986 5.7642 27.3718 5.72842 27.2012 5.72842H26.9381V5.3634H27.1727C27.3244 5.3634 27.4405 5.33596 27.5211 5.28109C27.6017 5.22383 27.642 5.14391 27.642 5.04132C27.642 4.95066 27.6111 4.88148 27.5495 4.83376C27.4903 4.78605 27.405 4.76219 27.2936 4.76219C27.1822 4.76219 27.0827 4.78366 26.995 4.82661C26.9097 4.86716 26.8339 4.92561 26.7675 5.00196L26.4903 4.6942Z"
            fill="#F4F1EA"
        />
        <path
            d="M29.7741 6.92725C29.5892 6.92725 29.421 6.89147 29.2693 6.81989C29.12 6.74594 28.9909 6.64931 28.8819 6.53003C28.7752 6.40835 28.6923 6.26998 28.6331 6.11491C28.5738 5.95983 28.5442 5.80118 28.5442 5.63895C28.5442 5.46956 28.575 5.30733 28.6366 5.15226C28.7006 4.99719 28.7871 4.86001 28.8961 4.74072C29.0075 4.62143 29.139 4.52719 29.2907 4.45801C29.4423 4.38644 29.607 4.35065 29.7847 4.35065C29.9672 4.35065 30.1331 4.38882 30.2824 4.46517C30.434 4.53912 30.5632 4.63694 30.6698 4.75861C30.7765 4.88028 30.8594 5.01866 30.9186 5.17373C30.9779 5.3288 31.0075 5.48626 31.0075 5.64611C31.0075 5.8155 30.9767 5.97773 30.9151 6.1328C30.8535 6.28549 30.7682 6.42147 30.6592 6.54076C30.5502 6.65766 30.4198 6.7519 30.2682 6.82347C30.1165 6.89266 29.9518 6.92725 29.7741 6.92725ZM29.0418 5.63895C29.0418 5.74869 29.0584 5.85486 29.0916 5.95745C29.1248 6.06003 29.1722 6.15069 29.2338 6.22942C29.2978 6.30815 29.3748 6.37137 29.4648 6.41909C29.5572 6.4668 29.6615 6.49066 29.7776 6.49066C29.8961 6.49066 30.0004 6.4668 30.0904 6.41909C30.1828 6.36899 30.2599 6.30338 30.3215 6.22227C30.3855 6.14115 30.4329 6.05049 30.4637 5.95029C30.4968 5.8477 30.5134 5.74392 30.5134 5.63895C30.5134 5.52921 30.4956 5.42304 30.4601 5.32045C30.4269 5.21787 30.3784 5.12721 30.3144 5.04848C30.2528 4.96975 30.1757 4.90772 30.0833 4.86239C29.9933 4.81468 29.8914 4.79082 29.7776 4.79082C29.6591 4.79082 29.5537 4.81587 29.4613 4.86597C29.3689 4.91369 29.2918 4.9781 29.2302 5.05922C29.1686 5.13794 29.1212 5.2286 29.088 5.33119C29.0572 5.43139 29.0418 5.53398 29.0418 5.63895Z"
            fill="#F4F1EA"
        />
        <path
            d="M33.4916 6.25447C33.4916 6.36183 33.4703 6.45607 33.4277 6.53718C33.385 6.6183 33.3258 6.68629 33.2499 6.74116C33.1765 6.79604 33.09 6.83779 32.9905 6.86642C32.8933 6.89504 32.789 6.90936 32.6777 6.90936H31.4584V4.36854H32.8092C32.8969 4.36854 32.9762 4.38763 33.0473 4.4258C33.1184 4.46397 33.1788 4.51407 33.2286 4.5761C33.2784 4.63575 33.3163 4.70493 33.3424 4.78366C33.3708 4.86001 33.385 4.93754 33.385 5.01627C33.385 5.14033 33.3542 5.25723 33.2926 5.36698C33.231 5.47433 33.1409 5.55426 33.0224 5.60674C33.167 5.64969 33.2807 5.72722 33.3637 5.83935C33.449 5.9491 33.4916 6.08747 33.4916 6.25447ZM32.9976 6.16143C32.9976 6.1161 32.9893 6.07316 32.9727 6.0326C32.9585 5.98966 32.9383 5.95268 32.9123 5.92166C32.8862 5.89065 32.8554 5.86679 32.8198 5.85009C32.7843 5.831 32.7452 5.82146 32.7025 5.82146H31.949V6.49066H32.6777C32.7227 6.49066 32.7641 6.48231 32.8021 6.46561C32.8423 6.44891 32.8767 6.42625 32.9051 6.39762C32.9336 6.3666 32.9561 6.33082 32.9727 6.29026C32.9893 6.2497 32.9976 6.20676 32.9976 6.16143ZM31.949 4.79082V5.43139H32.603C32.6859 5.43139 32.7559 5.40276 32.8127 5.3455C32.872 5.28825 32.9016 5.20952 32.9016 5.10932C32.9016 5.0115 32.8743 4.93396 32.8198 4.87671C32.7677 4.81945 32.7025 4.79082 32.6243 4.79082H31.949Z"
            fill="#F4F1EA"
        />
        <path
            d="M36.1492 4.36854V6.90936H35.6622V5.82504H34.5176V6.90936H34.0271V4.36854H34.5176V5.39561H35.6622V4.36854H36.1492Z"
            fill="#F4F1EA"
        />
        <path
            d="M37.5105 4.36854H37.9441L38.9039 6.90936H38.3991L38.1645 6.27594H37.283L37.052 6.90936H36.5472L37.5105 4.36854ZM38.0757 5.92882L37.7273 4.93039L37.3648 5.92882H38.0757Z"
            fill="#F4F1EA"
        />
        <path
            d="M1.43529 10.0546L2.72204 8.27249H3.09527V10.8133H2.60829V9.07052L1.33931 10.8133H0.948311V8.27249H1.43529V10.0546Z"
            fill="#F4F1EA"
        />
        <path
            d="M5.87071 8.27249V10.8133H5.38374V9.72898H4.23917V10.8133H3.74864V8.27249H4.23917V9.29955H5.38374V8.27249H5.87071Z"
            fill="#F4F1EA"
        />
        <path
            d="M6.36116 9.525C6.36116 9.36993 6.38841 9.21724 6.44291 9.06694C6.49978 8.91425 6.58154 8.77826 6.68818 8.65898C6.79481 8.53969 6.92515 8.44307 7.07918 8.36911C7.23321 8.29515 7.40857 8.25817 7.60525 8.25817C7.83748 8.25817 8.03772 8.30946 8.20597 8.41205C8.37659 8.51464 8.50337 8.64824 8.58631 8.81286L8.20953 9.0741C8.17635 9.00252 8.13607 8.94288 8.08867 8.89517C8.04128 8.84506 7.99033 8.8057 7.93583 8.77707C7.88132 8.74844 7.82445 8.72816 7.76521 8.71623C7.70597 8.70192 7.64791 8.69476 7.59103 8.69476C7.46781 8.69476 7.35999 8.71981 7.26757 8.76991C7.17752 8.82001 7.10169 8.88562 7.04008 8.96674C6.98084 9.04547 6.93581 9.13613 6.905 9.23871C6.8742 9.33891 6.8588 9.43911 6.8588 9.53932C6.8588 9.65145 6.87657 9.76 6.91211 9.86497C6.94766 9.96756 6.99742 10.0582 7.06141 10.1369C7.12776 10.2157 7.20596 10.2789 7.29601 10.3266C7.38842 10.3743 7.48914 10.3982 7.59814 10.3982C7.65502 10.3982 7.71426 10.391 7.77587 10.3767C7.83748 10.3624 7.89554 10.3397 7.95005 10.3087C8.00692 10.2777 8.05905 10.2383 8.10645 10.1906C8.15384 10.1405 8.19176 10.0809 8.22019 10.0117L8.62186 10.2443C8.58157 10.3397 8.52351 10.4244 8.44768 10.4984C8.37422 10.57 8.2901 10.6308 8.19531 10.6809C8.10289 10.731 8.00336 10.7692 7.89673 10.7954C7.79009 10.8217 7.68582 10.8348 7.58393 10.8348C7.40383 10.8348 7.23913 10.7966 7.08984 10.7203C6.94055 10.6439 6.8114 10.5449 6.70239 10.4232C6.59576 10.2992 6.51163 10.1596 6.45002 10.0045C6.39078 9.84708 6.36116 9.68723 6.36116 9.525Z"
            fill="#F4F1EA"
        />
        <path
            d="M10.9206 8.7055H10.1137V10.8133H9.62677V8.7055H8.81989V8.27249H10.9206V8.7055Z"
            fill="#F4F1EA"
        />
        <path
            d="M11.8382 10.0546L13.125 8.27249H13.4982V10.8133H13.0112V9.07052L11.7422 10.8133H11.3512V8.27249H11.8382V10.0546Z"
            fill="#F4F1EA"
        />
        <path
            d="M16.0284 8.7055H15.2215V10.8133H14.7345V8.7055H13.9276V8.27249H16.0284V8.7055Z"
            fill="#F4F1EA"
        />
        <path
            d="M16.8677 10.8384C16.8417 10.8384 16.8132 10.836 16.7824 10.8312C16.7516 10.8288 16.7196 10.824 16.6865 10.8169C16.6533 10.8097 16.6201 10.8002 16.5869 10.7883C16.5561 10.7763 16.5289 10.762 16.5052 10.7453L16.5692 10.3373C16.5952 10.3517 16.6308 10.3684 16.6758 10.3874C16.7232 10.4041 16.7718 10.4125 16.8215 10.4125C16.8903 10.4125 16.9495 10.3934 16.9993 10.3552C17.0514 10.3147 17.0964 10.2431 17.1343 10.1405L16.2066 8.27249H16.7362L17.3547 9.6431L17.9306 8.27249H18.446L17.5573 10.2049C17.5052 10.3195 17.4542 10.4173 17.4045 10.4984C17.3547 10.5771 17.3026 10.6427 17.2481 10.6952C17.1959 10.7453 17.1391 10.7823 17.0775 10.8061C17.0159 10.8276 16.9459 10.8384 16.8677 10.8384Z"
            fill="#F4F1EA"
        />
        <path
            d="M20.7022 10.8133H18.8289V8.27249H19.3159V10.3803H20.3005V8.27249H20.7875V10.3803H21.1643V11.4467H20.7022V10.8133Z"
            fill="#F4F1EA"
        />
        <path
            d="M22.0953 10.0546L23.3821 8.27249H23.7553V10.8133H23.2683V9.07052L21.9994 10.8133H21.6084V8.27249H22.0953V10.0546Z"
            fill="#F4F1EA"
        />
        <path
            d="M24.2701 10.2944C24.3032 10.3183 24.3589 10.3445 24.4371 10.3731C24.5153 10.4018 24.6054 10.4161 24.7073 10.4161C24.8068 10.4161 24.8862 10.3994 24.9454 10.366C25.0047 10.3302 25.0497 10.2765 25.0805 10.2049C25.1113 10.1334 25.1314 10.0451 25.1409 9.94012C25.1504 9.83276 25.1551 9.70751 25.1551 9.56437V8.27249H25.6457V9.56437C25.6457 9.75761 25.635 9.93416 25.6137 10.094C25.5947 10.2515 25.5521 10.3863 25.4857 10.4984C25.4217 10.6105 25.3281 10.6976 25.2049 10.7596C25.0817 10.8217 24.9158 10.8527 24.7073 10.8527C24.494 10.8527 24.3115 10.8002 24.1599 10.6952L24.2701 10.2944Z"
            fill="#F4F1EA"
        />
        <path
            d="M26.9236 8.27249H27.3573L28.317 10.8133H27.8122L27.5776 10.1799H26.6961L26.4651 10.8133H25.9603L26.9236 8.27249ZM27.4888 9.83276L27.1404 8.83433L26.7779 9.83276H27.4888Z"
            fill="#F4F1EA"
        />
        <path
            d="M109.474 9.31666H108.295V1.44445H109.474V9.31666ZM112.348 1.56726H105.434V0.535645H112.348V1.56726Z"
            fill="#F4F1EA"
        />
        <path
            d="M104.449 9.31666H98.5176V0.535645H104.449V1.55498H99.6966V8.29733H104.449V9.31666ZM104.216 5.34985H99.5738V4.33052H104.216V5.34985Z"
            fill="#F4F1EA"
        />
        <path
            d="M94.0981 9.31666H92.9191V1.44445H94.0981V9.31666ZM96.9719 1.56726H90.0576V0.535645H96.9719V1.56726Z"
            fill="#F4F1EA"
        />
        <path
            d="M80.8311 9.31666V8.28505H81.4574C81.6785 8.28505 81.8422 8.23592 81.9486 8.13767C82.0633 8.03124 82.1369 7.86339 82.1697 7.63415L83.0417 0.535645H88.4945V9.31666H87.3155V0.768986L88.0401 1.56726H83.3733L84.1715 0.768986L83.2873 7.78152C83.2463 8.13358 83.1563 8.42423 83.0171 8.65348C82.8861 8.87454 82.7019 9.04238 82.4644 9.15701C82.2352 9.26344 81.9486 9.31666 81.6048 9.31666H80.8311Z"
            fill="#F4F1EA"
        />
        <path
            d="M74.3711 9.31666V8.30961H75.415C75.6852 8.30961 75.9144 8.26867 76.1027 8.1868C76.291 8.10492 76.4589 7.96983 76.6062 7.78152C76.7536 7.58502 76.9092 7.31893 77.0729 6.98324L80.1923 0.535645H81.4204L77.9572 7.49905C77.7361 7.94936 77.511 8.30961 77.2817 8.57979C77.0525 8.84179 76.7946 9.0301 76.508 9.14473C76.2214 9.25935 75.8653 9.31666 75.4395 9.31666H74.3711ZM77.1343 7.3394L73.6465 0.535645H74.8746L78.0431 6.81131L77.1343 7.3394Z"
            fill="#F4F1EA"
        />
        <path
            d="M66.9554 9.31666H65.7764V0.535645H66.9554V9.31666ZM73.231 9.31666H71.5239L66.6606 4.76036H66.8448L71.1924 0.535645H72.7152L68.0975 4.92001V4.6007L73.231 9.31666Z"
            fill="#F4F1EA"
        />
        <path
            d="M62.5191 7.03237H57.6066V6.01303H62.5191V7.03237ZM64.2384 9.31666H62.9858L59.8541 1.29708H60.2962L57.1645 9.31666H55.9609L59.4488 0.535645H60.7383L64.2384 9.31666Z"
            fill="#F4F1EA"
        />
        <path
            d="M50.716 8.42012C49.1767 8.42012 48.0059 8.12537 47.2036 7.53587C46.4012 6.93819 46 6.06623 46 4.91999C46 3.77375 46.4012 2.90588 47.2036 2.31639C48.0059 1.72689 49.1767 1.43215 50.716 1.43215C52.2634 1.43215 53.4342 1.72689 54.2284 2.31639C55.0225 2.90588 55.4196 3.77375 55.4196 4.91999C55.4196 6.06623 55.0225 6.93819 54.2284 7.53587C53.4342 8.12537 52.2634 8.42012 50.716 8.42012ZM50.716 7.3885C51.9031 7.3885 52.7833 7.18791 53.3564 6.78672C53.9377 6.38554 54.2284 5.7633 54.2284 4.91999C54.2284 4.08487 53.9377 3.46672 53.3564 3.06554C52.7751 2.66435 51.8949 2.46376 50.716 2.46376C49.537 2.46376 48.6568 2.66435 48.0755 3.06554C47.4942 3.46672 47.2036 4.08487 47.2036 4.91999C47.2036 5.74692 47.4942 6.36507 48.0755 6.77444C48.665 7.18381 49.5452 7.3885 50.716 7.3885ZM50.1265 9.69735V8.13765L50.1633 7.59728V2.23042L50.1265 1.75146V0.154907H51.3055V1.75146L51.2563 2.23042V7.59728L51.3055 8.13765V9.69735H50.1265Z"
            fill="#F4F1EA"
        />
    </svg>
);
