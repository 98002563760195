import styled from "styled-components";
import ContentWrapper from "../../../../Components/ContentWrapper/ContentWrapper";
import { ellipse } from "./Static/ellipse";
import { rightArrow } from "./Static/rightArrow";
import { accordionArrow } from "./Static/accordionArrow";
import React, { useRef, useEffect, useState, useContext } from "react";
import Round1Accordion from "./Round1Accordion";
import Round2Accordion from "./Round2Accordion";
import Round3Accordion from "./Round3Accordion";
import Round4Accordion from "./Round4Accordion";
import { Link } from "react-router-dom";
import { API } from "../../../../Consts/Api";
import { roundText } from "./Static/roundText";
import { GlobalContext } from "../../../../Context/GlobalContext";

const StyledContainer = styled.section`
  padding-bottom: 120px;
  font-family: Inter;

  .heading-2 {
    color: #005840;
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 40px;
    text-transform: uppercase;

    & > br {
      display: none;
    }
  }

  .round-0-wrapper {
    padding: 24px;
    background-color: #ffb3d3;

    .round-heading-wrapper {
      display: inline-flex;
      gap: 8px;
      color: #81003b;
      font-family: Ligo;
      font-weight: 400;
      text-transform: uppercase;

      & .round-heading {
        display: inline-flex;
        align-items: center;

        span {
          font-size: 36px;
          line-height: 20px;
        }

        svg {
          width: 87px;
          margin-right: 10px;
        }
      }

      & span {
        font-size: 16px;
        line-height: 16px;
        align-self: end;
        margin-bottom: 2px;
        text-transform: lowercase;
      }
    }

    .badge {
      display: inline-flex;
      height: 40px;
      padding: 10px 24px 10px 10px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      border-radius: 100px;
      background: rgba(128, 3, 58, 0.1);
      font-family: Ligo, Inter;
      color: #81003b;
      margin-left: 10px;

      & span {
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        margin-bottom: 3px;
      }
    }

    .heading-badge-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .hr {
    height: 1px;
  }

  .hr-red {
    background: rgba(129, 0, 59, 0.1);
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .list {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .list-item {
    display: flex;
    flex-direction: column;
    gap: 4px;

    & > p {
      color: #81003b;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 114.286% */
    }

    & > ul {
      padding-left: 3px;
      margin-top: 8px;
      display: flex;
      flex-direction: column;
      gap: 8px;

      & > li {
        color: #80033a;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        display: flex;
        align-items: center;
        gap: 6px;

        & > span {
          background-color: #81003b;
          display: inline-block;
          height: 4px;
          width: 4px;
          border-radius: 100%;
        }
      }
    }
  }

  .list-heading {
    color: rgba(129, 0, 59, 0.5);
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }

  .grouped-lists {
    display: flex;
    gap: 80px;
  }

  .list-item-last {
    align-self: end;
  }

  .grouped-list-items {
    display: flex;
    justify-content: space-between;
  }

  .reg-button {
    display: inline-flex;
    padding: 16px 32px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 12px;
    background: #80003b;

    & > span {
      color: #ffb3d3;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      text-transform: uppercase;
    }
  }

  .hr-dashed-wrapper {
    overflow-x: hidden;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .round-normal {
    background: #005840;
    color: #cdf545;

    & .accordion-badge {
      background: rgba(205, 245, 69, 0.2);
    }
  }

  .round-final {
    background: #cdf545;
    color: #005840;

    & .accordion-badge {
      background: rgba(0, 88, 64, 0.1);
    }

    .accordion-content {
      position: relative;
    }

    .accordion-content .trophy-svg {
      position: absolute;
      right: 24px;
      bottom: 24px;
    }

    & .accordion-content hr {
      border-color: rgba(0, 88, 64, 0.1);
    }

    & .accordion-content .accordion-list-heading {
      color: rgba(0, 88, 64, 0.5);
    }

    & .accordion-heading-wrapper svg {
      width: 208px;
    }

    & .accordion-list-item svg {
      width: 160px;
      margin-bottom: 16px;
    }

    & .accordion-list-item h3 {
      font-size: 22px;
      margin-bottom: 16px;
      font-family: Ligo;
    }
  }

  .accordion {
    overflow: hidden;

    & .accordion-heading {
      padding: 24px;
      cursor: pointer;
    }

    & .accordion-heading-wrapper {
      display: flex;
      align-items: center;
      gap: 10px;
      font-family: Inter;

      h3 {
        display: inline-flex;
        gap: 10px;

        svg.round-text-svg {
          width: 87px;
        }

        span {
          font-size: 36px;
          font-family: Ligo;
        }
      }

      span.in-brackets {
        align-self: end;
        margin-bottom: 4px;
        font-family: Inter;
        font-size: 14px;
      }
    }

    & .accordion-badges-button-wrapper {
      display: flex;
      align-items: center;
      gap: 24px;
    }

    & .badges-wrapper {
      display: flex;
      gap: 8px;
      font-family: Ligo;
    }

    & .accordion-badge {
      display: inline-flex;
      padding: 10px 24px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 100px;

      & span {
        font-size: 15px;
      }
    }

    .accordion-heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .accordion-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .arrow-active .accordion-arrow {
    rotate: 0deg;
  }

  .accordion-arrow {
    cursor: pointer;
    transition: 0.5s ease-in-out;
    rotate: 90deg;
  }

  .accordion-container {
    transition: height 0.5s ease-in-out;
  }

  .accordion-content {
    padding: 0px 24px 24px 24px;

    & > hr {
      border-color: rgba(205, 245, 69, 0.1);
      margin-top: -6px;
      margin-bottom: 16px;
    }
  }

  .visibility-hidden {
    visibility: hidden;
  }

  .accordion-list-columns-wrapper {
    display: flex;

    & .mobile-hr {
      display: none;
      border-color: rgba(205, 245, 69, 0.1);
      margin-top: 16px;
      margin-bottom: 16px;
    }

    & .accordion-list-column {
      width: 400px;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    & .accordion-list-column.final-round-last-item {
      /* flex-direction: row; */
      flex-wrap: wrap;
      width: 100%;
      gap: 10%;
    }

    & .accordion-list-column-mobile {
      display: none;
    }

    & .accordion-list-heading {
      color: rgba(205, 245, 69, 0.5);
      margin-bottom: 4px;
      font-size: 10px;
      text-transform: uppercase;
    }

    & .accordion-list-paragraph {
      font-size: 15px;
      font-weight: 500;
      line-height: 16px;
    }

    & .accordion-list-column.final-round-last-item .accordion-list-paragraph {
      font-size: 13px;
      line-height: 20px;
      font-weight: 400;
    }
  }

  .mobile-hidden {
    display: block !important;
  }

  .desktop-hidden {
    display: none !important;
  }

  @media (max-width: 1100px) {
    .grouped-lists {
      gap: 40px;
    }

    .accordion {
      & .accordion-heading-wrapper {
        h3 {
          span + span {
            display: none;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    padding-top: 64px;
    padding-bottom: 57px;

    .round-0-wrapper {
      padding: 16px;

      .badge {
        padding: 10px 16px 10px 10px;
      }

      .round-heading-wrapper {
        & .round-heading {
          span {
            font-size: 28px;
            margin-bottom: 1px;
          }

          svg {
            width: 67px;
            margin-right: 10px;
          }
        }

        & span {
          margin-bottom: 0px;
        }
      }
    }

    .heading-2 {
      font-size: 30px;

      & > br {
        display: block;
      }
    }

    .badge span span {
      display: none;
    }

    .grouped-list-items {
      flex-direction: column;
    }

    .list-item-last {
      align-self: stretch;
      margin-top: 40px;
    }

    .reg-button {
      display: flex;
    }

    .grouped-lists {
      flex-direction: column;
      gap: 16px;
    }

    .accordion {
      & .accordion-heading-wrapper {
        gap: 6px;

        h3 {
          display: inline-flex;
          gap: 10px;

          svg.round-text-svg {
            width: 67px;
          }

          span {
            font-size: 28px;
          }

          span + span {
            display: none;
          }
        }

        span.in-brackets {
          font-size: 13px;
        }

        .round-text-wrapper {
          width: 60px;
        }
      }

      & .location-badge {
        display: none;
      }

      & .accordion-badge {
        gap: 4px;
        padding: 12px 16px;
      }

      & .accordion-heading {
        padding: 16px;
      }

      & .accordion-badges-button-wrapper {
        gap: 16px;
      }
    }

    .accordion-content {
      padding: 0px 16px 16px 16px;
    }

    .accordion-list-columns-wrapper {
      display: block;

      & .accordion-list-column {
        display: flex;
      }

      & .mobile-hr {
        display: block;
      }
    }

    .mobile-hidden {
      display: none !important;
    }

    .desktop-hidden {
      display: block !important;
    }

    .final-round-last-item {
      gap: 16px !important;
    }

    .trophy-svg {
      display: none !important;
    }
  }
`;

const StructureCompetitionSection = (props) => {
  const scrollToSectionRef = useRef(null);
  const offset = 20;
  const { userInfo } = useContext(GlobalContext);

  useEffect(() => {
    if (props.scrollToStructureSection) {
      const topPosition =
        scrollToSectionRef?.current?.getBoundingClientRect()?.top +
        window.scrollY -
        offset;
      window.scrollTo({ top: topPosition, behavior: "smooth" });
      props.resetButton();
    }
  }, [props.scrollToStructureSection]);

  const [activeIndex, setActiveIndex] = useState(null);

  const handleItemClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <StyledContainer>
      <ContentWrapper>
        <div>
          <div ref={scrollToSectionRef}>
            <h2 className="heading-2">
              СТРУКТУРА <br /> НА НАТПРЕВАРОТ
            </h2>

            <div className="round-0-wrapper">
              <div className="heading-badge-wrapper">
                <div className="round-heading-wrapper">
                  <div className="round-heading">
                    {roundText}
                    <span>0</span>
                  </div>
                  <span>(online)</span>
                </div>

                <div className="badge">
                  {ellipse}
                  <span>
                    <span>рундата е</span> отворена до 26.11
                  </span>
                </div>
              </div>

              <div className="hr hr-red" />

              <div className="grouped-list-items">
                <div className="grouped-lists">
                  <div className="list">
                    <div className="list-item">
                      <h4 className="list-heading">фаза</h4>
                      <p>
                        Квалификациска рунда за пласман на натпреварот во живо
                      </p>
                    </div>

                    <div className="list-item">
                      <h4 className="list-heading">ВРЕМЕТРАЕЊЕ</h4>
                      <p>25 Mинути</p>
                    </div>

                    <div className="list-item">
                      <h4 className="list-heading">КРАЈ НА РУНДА</h4>
                      <p>
                        Најдобрите 350 продолжуваат во следната рунда
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="list-item">
                      <h4 className="list-heading">формат</h4>
                      <p>3 типа на задачи</p>
                      <ul>
                        <li>
                          <span></span>Мемори матрикс
                        </li>
                        <li>
                          <span></span>Ментална математика
                        </li>
                        <li>
                          <span></span>Аритмофобија
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="list-item-last">
                  <Link
                    to={
                      userInfo.referred_by
                        ? API.createRoute("applyWithReferral", {
                            REFERRAL_GUID: userInfo.referred_by,
                            ACADEMY_ID: 1,
                          })
                        : API.createRoute("apply", { ACADEMY_ID: 1 })
                    }
                    className="reg-button"
                  >
                    {rightArrow}
                    <span>ЗАПОЧНИ со НАТПРЕВАРОТ</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="hr-dashed-wrapper">
            <svg
              height="1"
              viewBox="0 0 3000 1"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="-4.37114e-08"
                y1="0.5"
                x2="3000"
                y2="0.499885"
                stroke="#005840"
                stroke-dasharray="10 10"
              />
            </svg>
          </div>

          <div className="accordion-wrapper">
            <Round1Accordion
              isOpen={activeIndex === 1}
              onClick={() => handleItemClick(1)}
            />

            <Round2Accordion
              isOpen={activeIndex === 2}
              onClick={() => handleItemClick(2)}
            />
          </div>

          <div className="hr-dashed-wrapper">
            <svg
              height="1"
              viewBox="0 0 3000 1"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="-4.37114e-08"
                y1="0.5"
                x2="3000"
                y2="0.499885"
                stroke="#005840"
                stroke-dasharray="10 10"
              />
            </svg>
          </div>

          <div className="accordion-wrapper">
            <Round3Accordion
              isOpen={activeIndex === 3}
              onClick={() => handleItemClick(3)}
            />

            <Round4Accordion
              isOpen={activeIndex === 4}
              onClick={() => handleItemClick(4)}
            />
          </div>
        </div>
      </ContentWrapper>
    </StyledContainer>
  );
};

export default StructureCompetitionSection;
