export const theBeetlesLogo = <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 200 188" fill="none">
<path d="M99.9971 129.985C99.6523 129.984 99.3078 129.964 98.9653 129.924L51.0332 124.318C49.0026 124.071 47.1172 123.138 45.6893 121.672C44.2613 120.206 43.3767 118.297 43.1819 116.259L38.4375 69.7206C38.1873 67.3466 38.8861 64.97 40.3814 63.1099C41.8767 61.2498 44.0468 60.0575 46.4178 59.7934L99.0942 54.2546C99.7149 54.1873 100.341 54.1873 100.962 54.2546L153.638 59.7934C156.009 60.0575 158.179 61.2498 159.675 63.1099C161.17 64.97 161.869 67.3466 161.618 69.7206L156.874 116.259C156.678 118.301 155.789 120.214 154.356 121.68C152.922 123.146 151.03 124.077 148.995 124.318L101.063 129.929C100.718 129.97 100.372 129.99 100.025 129.991M100.025 56.5554C99.8265 56.5555 99.6281 56.5667 99.4307 56.5891L46.7543 62.1223C43.2885 62.4871 40.7817 65.8036 41.1462 69.5186L45.8906 116.063C46.2047 119.166 48.5264 121.674 51.4146 122L99.3466 127.612C99.7879 127.668 100.234 127.668 100.676 127.612L148.608 122C151.496 121.663 153.818 119.194 154.132 116.063L158.876 69.5186C159.257 65.8036 156.745 62.4871 153.268 62.1223L100.592 56.5891C100.392 56.5665 100.192 56.5553 99.9915 56.5554" fill="#010101"/>
<path d="M100.003 132.314C99.5589 132.315 99.1152 132.291 98.6739 132.241L51.0054 126.725C48.3432 126.411 45.87 125.19 44.0012 123.267C42.1324 121.344 40.9818 118.835 40.7427 116.164L36.0263 70.3884C35.7003 67.2844 36.6022 64.1761 38.5387 61.7294C39.4957 60.5107 40.688 59.4972 42.0445 58.749C43.401 58.0009 44.8941 57.5335 46.4349 57.3747L98.8141 51.9313C99.6026 51.8471 100.398 51.8471 101.186 51.9313L153.566 57.3747C155.106 57.5335 156.599 58.0009 157.956 58.749C159.312 59.4972 160.505 60.5107 161.462 61.7294C163.403 64.1804 164.305 67.2956 163.974 70.4052L159.269 116.164C159.024 118.833 157.87 121.337 156 123.256C154.129 125.175 151.656 126.392 148.995 126.703L101.327 132.219C100.889 132.268 100.449 132.293 100.009 132.292M100.009 52.919C99.6665 52.919 99.3132 52.919 98.9655 52.9695L46.5919 58.4129C45.2185 58.5615 43.8892 58.986 42.6837 59.661C41.4782 60.336 40.4212 61.2476 39.5762 62.3411C37.7913 64.5954 36.9635 67.4615 37.2713 70.3211L41.9877 116.096C42.1916 118.507 43.2172 120.774 44.8926 122.518C46.5681 124.262 48.7915 125.377 51.1905 125.676L98.859 131.192C99.6265 131.282 100.402 131.282 101.169 131.192L148.838 125.676C151.237 125.377 153.46 124.262 155.136 122.518C156.811 120.774 157.837 118.507 158.041 116.096L162.757 70.3211C163.065 67.4615 162.237 64.5954 160.452 62.3411C159.607 61.2476 158.55 60.336 157.345 59.661C156.139 58.986 154.81 58.5615 153.437 58.4129L101.057 52.9695C100.715 52.9358 100.368 52.919 100.025 52.919" fill="#010101"/>
<path d="M95.9032 88.4696H98.4324V86.8646L96.5593 85.8713V84.6367L103.743 82.7063V85.8713L101.87 86.8646V88.4696H104.394H105.801H108.325V86.8646L106.452 85.8713V78.5423L108.325 77.5491V75.9441H106.452H105.801H104.394H103.743H101.87V77.5491L103.743 78.5423V79.8443L96.5593 81.7747V78.5423L98.4324 77.5491V75.9441H96.5593H95.9032H94.5012H93.8506H91.9775V77.5491L93.8506 78.5423V85.8713L91.9775 86.8646V88.4696H94.5012H95.9032Z" fill="#010101"/>
<path d="M78.3387 78.4245L81.6643 78.4133V86.0733L79.8417 86.8646V88.4696H81.6643H82.9317H83.2906H84.429H86.375V86.8646L84.429 86.0228V78.4133L87.7602 78.4245L88.3098 80.181H90.1829V75.9441H88.8145H83.0495H77.2788H75.916V80.181H77.7835L78.3387 78.4245Z" fill="#010101"/>
<path d="M123.226 84.5133H121.381L121.168 86.1856L114.988 86.0565V83.795L119.457 82.1844L118.521 79.5861L114.988 80.8544V78.3572L121.168 78.2281L121.381 79.9004H123.226L124.084 75.9441H122.721H120.882H112.907H112.223H110.378V77.5491L112.223 78.5255V81.8477L111.326 82.1732L112.223 84.6816V85.8882L110.378 86.8646V88.4696H112.223H112.907H120.882H122.721H124.084L123.226 84.5133Z" fill="#010101"/>
<path d="M75.204 93.5146L75.4115 95.1869H77.2677L78.1201 91.2306H76.7574H74.9179H66.9433H66.2647H64.4141V92.8355L66.2647 93.812V97.1342L65.3618 97.4597L66.2647 99.9681V101.175L64.4141 102.151V103.756H66.2647H66.9433H74.9179H76.7574H78.1201L77.2677 99.7998H75.4115L75.204 101.472L69.0239 101.343V99.0815L73.4935 97.4709L72.557 94.8726L69.0239 96.1409V93.6436L75.204 93.5146Z" fill="#010101"/>
<path d="M107.932 91.2306H102.162H96.3967H95.0283V95.4675H96.9014L97.451 93.711L100.782 93.6998V101.36L98.9596 102.151V103.756H100.782H102.044H102.403H103.541H105.487V102.151L103.541 101.309V93.6998L106.873 93.711L107.428 95.4675H109.295V91.2306H107.932Z" fill="#010101"/>
<path d="M119.267 101.416L115.016 101.326V93.5146L116.44 92.8355V91.2306H113.636H110.832V92.8355L112.251 93.5146V101.567L111.152 102.151V103.756H113.002H113.681H120.478H121.728V99.5192H119.861L119.267 101.416Z" fill="#010101"/>
<path d="M150.638 97.3586L141.115 94.8053V94.3507L141.586 93.7054L147.615 93.5707L148.103 94.9063H149.97V91.2306H148.557H146.768H139.562L138.35 92.8804V96.9265L147.879 99.4799V100.636L147.407 101.276L140.381 101.315L139.932 100.08H138.064V103.756H139.595H149.426L150.638 102.106V97.3586Z" fill="#010101"/>
<path d="M62.8099 95.0185V92.8917L61.5986 91.2081H49.6366V92.8131L51.2069 93.7447V101.152L49.3506 102.134V103.739H62.1594L63.3707 102.134V96.9041L62.1369 95.9613L62.8099 95.0185ZM60.6172 100.63L60.0059 101.466L53.966 101.343V99.4743L60.6116 97.6897L60.6172 100.63ZM53.9716 93.638L59.4507 93.5202L60.062 94.3507V94.8389L53.9716 96.7021V93.638Z" fill="#010101"/>
<path d="M90.4803 93.5146L90.6934 95.1869H92.5385L93.3965 91.2306H92.0338H90.1943H82.2197H81.5411H79.6904V92.8355L81.5411 93.812V97.1342L80.6382 97.4597L81.5411 99.9681V101.175L79.6904 102.151V103.756H81.5411H82.2197H90.1943H92.0338H93.3965L92.5385 99.7998H90.6934L90.4803 101.472L84.3003 101.343V99.0815L88.7699 97.4709L87.8333 94.8726L84.3003 96.1409V93.6436L90.4803 93.5146Z" fill="#010101"/>
<path d="M134.077 93.5146L134.285 95.1869H136.135L136.993 91.2306H135.625H133.791H125.811H125.132H123.287V92.8355L125.132 93.812V97.1342L124.229 97.4597L125.132 99.9681V101.175L123.287 102.151V103.756H125.132H125.811H133.791H135.625H136.993L136.135 99.7998H134.285L134.077 101.472L127.897 101.343V99.0815L132.361 97.4709L131.424 94.8726L127.897 96.1409V93.6436L134.077 93.5146Z" fill="#010101"/>
</svg>;