import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { API } from "../../Consts/Api";
import { GlobalContext } from "../../Context/GlobalContext";
import UltimathLogo from "../../Components/UltimathLogo/UltimathLogo";
import Stepper from "./Blocks/Stepper";
import CustomButton from "../../Components/CustomButton/CustomButton";
import GameRules from "./Blocks/GameRules";
import Alert from "../../Components/Alert/Alert";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import memoryMatrixVideo from "./Static/memory-matrix-guide.mp4";

const Container = styled.section`
  background: ${(props) => props.theme.forestGreen};
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .triangle-left {
    position: absolute;
    top: 0;
    left: 0;
  }
  .divide-svg {
    position: absolute;
    left: 5%;
    top: 50%;
    transform: translateY(-50%);
  }
  .plus-svg-left {
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .plus-svg-right {
    position: absolute;
    top: 0;
    right: 0;
  }
  .triangle-right {
    position: absolute;
    right: 5%;
    top: 50%;
    transform: translateY(-50%);
  }
  .divider-right {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  div.spinner-container {
    margin-top: 50px;
  }

  @media (max-width: 768px) {
    svg {
      display: none;
    }
  }
`;
const InnerContainer = styled.section`
  min-height: 100vh;
  width: 80%;
  max-width: 1235px;
  background: ${(props) => props.theme.warmBeige};
  padding: 55px 110px 0;

  .text-center {
    text-align: center;
  }

  .button-container {
    width: 250px;
    margin: 40px auto;
  }

  .button-container button {
    margin-bottom: 8px;
  }

  @media (max-width: 768px) {
    padding: 40px 16px 0;
    width: 100%;

    .button-container {
      margin: 40px auto;
    }
  }
`;

const GameTitle = styled.div`
  margin-top: 80px;
  text-align: center;

  h1 {
    color: #020202;
    text-align: center;
    font-family: Inter;
    font-size: 29px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
  }

  p {
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    margin-top: 16px;
  }

  @media (max-width: 768px) {
    margin-top: 48px;

    h1 {
      font-size: 27px;
      font-style: normal;
      font-weight: 700;
    }
    p {
      color: rgba(0, 0, 0, 0.5);
      text-align: center;
      font-size: 16px;
      line-height: 18px;
      margin-top: 10px;
    }
  }
`;

const GameDuration = styled.div`
  color: #005840;
  font-family: Inter;
  margin-top: 32px;
  p {
    font-size: 40px;
    font-weight: 700;
    line-height: 40px;
    text-transform: uppercase;
    display: inline-block;
  }

  span {
    font-size: 14px;
    font-weight: 700;
    line-height: 40px;
    text-transform: uppercase;
  }
`;

const MatrixContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
`;

const MatrixInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Matrix = styled.div`
  display: grid;
  gap: 10px;
  margin-bottom: 20px;
  grid-template-columns: repeat(${(props) => props.size}, 1fr);
`;
const Cell = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 6px;
  border: 2px solid #d5cfc3;
  background: #fbfaf6;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s, opacity 0.3s;

  &.active {
    background-color: #4caf50;
  }

  &.wrong {
    background-color: #ff5722;
  }

  &.distraction {
    background-color: #ff5722 !important;
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  &.shake {
    animation: shake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  }
  &.glow {
    animation: glowing 1s infinite alternate;
  }
  @keyframes shake {
    0%,
    100% {
      transform: translateX(0);
    }
    10%,
    30%,
    50%,
    70%,
    90% {
      transform: translateX(-5px);
    }
    20%,
    40%,
    60%,
    80% {
      transform: translateX(5px);
    }
  }
  @keyframes glowing {
    0% {
      box-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ff00de,
        0 0 35px #ff00de, 0 0 40px #ff00de, 0 0 50px #ff00de, 0 0 75px #ff00de;
    }
    100% {
      box-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #ff00de,
        0 0 70px #ff00de, 0 0 80px #ff00de, 0 0 100px #ff00de, 0 0 150px #ff00de;
    }
  }
`;
const Message = styled.div`
  color: #020202;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 16px;
`;

const ScoreContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
const ScoreDisplay = styled.div`
  width: 121px;
  height: 84px;
  border-radius: 6px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &.points {
    background: #cdf545;
    color: #005840;
  }
  &.level {
    background: #ffb3d3;
    color: #81003b;
  }
`;

const FinalResultContainer = styled.div`
  width: 250px;
  margin: 0 auto;
`;
const FinalResult = styled.div`
  width: 100%;
  border-radius: 6px;
  background: #cdf545;
  padding: 16px;
  color: #005840;
  font-family: Inter;
  margin-top: 8px;
  text-transform: uppercase;

  div.divider {
    height: 1px;
    background: rgba(0, 88, 64, 0.1);
    margin-top: 4px;
    margin-bottom: 16px;
  }

  p.header {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-align: left !important;
  }

  p.score {
    font-size: 14px;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: 16px;
  }
  p.final-score {
    font-weight: 600;
  }
`;

const PATTERN_LENGTH = 4;
const COLORS = [
  "#ff00de",
  "#00ff00",
  "#ff0000",
  "#0000ff",
  "#ffff00",
  "#00ffff",
];

const MemoryMatrixGame = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  // Context
  const { userInfo, setUserInfo, popupConfig, setPopupConfig } =
    useContext(GlobalContext);

  // Local Storage
  const guid = localStorage.getItem("guid");
  const memoryMatrixStatus = localStorage.getItem("memoryMatrixStatus");
  const speedArithmeticsStatus = localStorage.getItem("speedArithmeticsStatus");
  const assessment = localStorage.getItem("assessment");

  // States
  const [showRules, setShowRules] = useState(false);
  const [duration, setDuration] = useState(null);
  const [currentLevel, setCurrentLevel] = useState(1);
  const [currentSubLevel, setCurrentSubLevel] = useState("a");
  const [score, setScore] = useState(0);
  const [message, setMessage] = useState("");
  const [pattern, setPattern] = useState([]);
  const [userPattern, setUserPattern] = useState([]);
  const [matrixCells, setMatrixCells] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [distractionIndex, setDistractionIndex] = useState(-1);
  const [canClick, setCanClick] = useState(false);
  const [isShaking, setIsShaking] = useState(false);
  const [isGlowing, setIsGlowing] = useState(false);
  const [wrongIndex, setWrongIndex] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [endTimestamp, setEndTimestamp] = useState(null);

  window.history.pushState(null, "", window.location.href);
  // Prevent going back
  window.onpopstate = function () {
    window.history.pushState(null, "", window.location.href);
  };

  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    document.addEventListener("contextmenu", handleContextMenu);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  useEffect(() => {
    //if user has no active assessment session (not registered), redirect user to homepage
    if (guid === null) {
      navigate(API.createRoute("home"));
      return;
    }

    if (memoryMatrixStatus === "not-started") {
      setIsLoading(true);
      API.axios
        .get(API.createApiRoute("user"))
        .then((response) => {
          if (response.data.success) {
            setUserInfo({
              ...userInfo,
              memoryMatrixDuration: response.data.data.memory_matrix_duration,
              memoryMatrixResult: response.data.data.memory_matrix_result,
              speedArithmeticResult: response.data.data.speed_arithmetic_result,
            });
          }
        })
        .catch((error) => {
          setPopupConfig({
            ...popupConfig,
            show: true,
            subTitle: "You must register first before accessing this page.",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

    if (memoryMatrixStatus !== "not-started") {
      fetchStartGameData();
    }

    setUserPattern([]);
    createMatrix(getMatrixSize(currentLevel));
  }, []);

  useEffect(() => {
    if (
      guid &&
      speedArithmeticsStatus &&
      userInfo.memoryMatrixResult !== null &&
      userInfo.speedArithmeticResult === null
    ) {
      navigate(API.createRoute("speedArithmeticGame"));
      return;
    }

    if (
      guid &&
      assessment &&
      userInfo.memoryMatrixResult !== null &&
      userInfo.speedArithmeticResult !== null
    ) {
      navigate(API.createRoute("assessment"));
      return;
    }
  }, [userInfo]);

  useEffect(() => {
    if (matrixCells.length > 0 && memoryMatrixStatus === "playing") {
      generatePattern();
    }
  }, [matrixCells]);

  useEffect(() => {
    if (pattern.length > 0) {
      showPattern();
      setUserPattern([]);
    }
  }, [pattern]);

  useEffect(() => {
    let timer;

    if (duration > 0) {
      timer = setInterval(() => {
        setDuration((prevDuration) => {
          if (prevDuration <= 1) {
            clearInterval(timer); // Stop the timer at 0
            endGame(); // Trigger end game when time is up
            return 0;
          }
          return prevDuration - 1; // Decrease by 1 every second
        });
      }, 1000);
    }

    // Cleanup the interval on component unmount or if duration changes
    return () => clearInterval(timer);
  }, [duration]);

  useEffect(() => {
    createMatrix(getMatrixSize(currentLevel));
  }, [currentLevel, currentSubLevel]);

  function startGame() {
    fetchStartGameData();
    createMatrix(getMatrixSize(currentLevel));
    setUserPattern([]);
    localStorage.setItem("memoryMatrixStatus", "playing");
  }
  function fetchStartGameData() {
    API.axios
      .post(API.createApiRoute("startMemoryMatrix"), guid)
      .then((response) => {
        if (response.data.success) {
          if (response.data.data.finished) {
            return;
          }

          if (response.data.data.duration) {
            setDuration(response.data.data.duration);
            setEndTimestamp(response.data.data.duration + 5 + Math.floor(Date.now() / 1000));
          }
        } else {
          setPopupConfig({
            ...popupConfig,
            show: true,
            subTitle: "An error occurred while starting the game.",
          });
        }
      })
      .catch((error) => {
        setPopupConfig({
          ...popupConfig,
          show: true,
          subTitle: "An error occurred while starting the game.",
        });
      });
  }
  function getMatrixSize(level) {
    return level + 2;
  }
  const createMatrix = (size) => {
    const cells = Array(size * size)
      .fill(null)
      .map((_, index) => ({ index }));

    setMatrixCells(cells);
  };

  function generatePattern() {
    const newPattern = [];
    let distractionIdx = -1;

    const totalCells = matrixCells.length;

    for (let i = 0; i < PATTERN_LENGTH; i++) {
      let randomIndex;
      do {
        randomIndex = Math.floor(Math.random() * totalCells);
      } while (newPattern.includes(randomIndex)); // Avoid duplicates
      newPattern.push(randomIndex);
    }

    // If it's sublevel 'c', add distraction logic
    if (currentSubLevel === "c") {
      distractionIdx = Math.floor(Math.random() * 3) + 1;
      let distractionCellIndex;
      do {
        distractionCellIndex = Math.floor(Math.random() * totalCells);
      } while (newPattern.includes(distractionCellIndex));
      newPattern.splice(distractionIdx, 0, distractionCellIndex);
    }

    setPattern(newPattern); // Set the final pattern
    setDistractionIndex(distractionIdx);
  }
  function showPattern() {
    setCanClick(false);
    setMessage("Запомни ја шемата...");
    pattern.forEach((cellIndex, index) => {
      setTimeout(() => {
        setActiveIndex(cellIndex);

        setTimeout(() => {
          setActiveIndex(null);
        }, 500);

        if (index === pattern.length - 1) {
          setTimeout(() => {
            if (currentSubLevel === "b") {
              flashAllCells();
            } else {
              allowUserInput();
            }
          }, 500);
        }
      }, index * 1000);
    });
  }

  function flashAllCells() {
    setIsGlowing(true);

    setTimeout(() => {
      setIsGlowing(false);
      allowUserInput();
    }, 1000);
  }
  function allowUserInput() {
    setMessage(`Повтори го движењето`);
    setCanClick(true);
  }

  function handleCellClick(cellIndex) {
    if (!canClick) return;

    const clickedIndex = parseInt(cellIndex);

    let correctPattern = pattern;
    if (distractionIndex !== -1) {
      correctPattern.splice(distractionIndex, 1);
      setDistractionIndex(-1);
    }
    const userClickedPattern = userPattern;
    userClickedPattern.push(clickedIndex);
    setUserPattern(userClickedPattern);

    if (
      userClickedPattern[userClickedPattern.length - 1] !==
      correctPattern[userPattern.length - 1]
    ) {
      wrongGuess();
      setWrongIndex(clickedIndex);
      setTimeout(() => setWrongIndex(null), 300);
      return;
    } else {
      setActiveIndex(clickedIndex);
      setTimeout(() => setActiveIndex(null), 300); // Clear the active cell
    }

    if (userPattern.length === PATTERN_LENGTH) {
      //after each answer check if the time has passed and end the game if so. 
      //This is due to browsers terminating the tab when the laptop is on battery
      //If that happens when user answers the question after coming back the game will end
      //add 5 seconds just in case
      if (endTimestamp < (Math.floor(Date.now() / 1000))) {
        endGame();
        console.log('end game', endTimestamp, (Math.floor(Date.now() / 1000)));
      } else {
        levelUp();
      }
    }
  }

  function levelUp() {
    const newScore = score + PATTERN_LENGTH;
    //const newDuration = duration + 2;

    setCanClick(false);
    setUserPattern([]);
    //setDuration(newDuration);
    setScore(newScore);

    setMessage(`Браво!`);

    setTimeout(function () {
      if (currentSubLevel === "a") {
        setCurrentSubLevel("b");
      } else if (currentSubLevel === "b") {
        setCurrentSubLevel("c");
      } else {
        setCurrentSubLevel("a");
        setCurrentLevel((prevLevel) => prevLevel + 1);
      }
    }, 1000);
  }

  function wrongGuess() {
    let newLevel = currentLevel;
    let newScore = score + (userPattern.length - 1);

    setCanClick(false);
    setIsShaking(true);
    setTimeout(() => setIsShaking(false), 500);
    setScore((prevScore) => prevScore + userPattern.length - 1);
    setMessage("Уупс..! Одиш назад едно ниво.");
    setUserPattern([]);

    setTimeout(function () {
      if (currentLevel === 1 && currentSubLevel === "a") {
        setMessage("Wrong pattern! Try again.");
        generatePattern();
      } else if (currentSubLevel === "c") {
        setCurrentSubLevel("b");
      } else if (currentSubLevel === "b") {
        setCurrentSubLevel("a");
      } else {
        newLevel = currentLevel - 1;
        setCurrentLevel(Math.max(1, newLevel));
        setCurrentSubLevel("c");
      }
      setScore(newScore);
    }, 1000);
  }

  function endGame() {
    sendResultData();
  }
  function sendResultData() {
    const data = {
      guid: guid,
      result: score,
      level: currentLevel,
      sublevel: currentSubLevel,
    };
    API.axios
      .post(API.createApiRoute("resultMemoryMatrix"), data)
      .then((response) => {
        if (response.data.success) {
          setUserInfo({
            ...userInfo,
            memoryMatrixLevel: currentLevel,
            memoryMatrixSublevel: currentSubLevel,
            memoryMatrixResult: Math.floor(
              score - score / currentLevel + currentLevel
            ),
          });

          localStorage.setItem("memoryMatrixStatus", "end");
        } else {
          setPopupConfig({
            ...popupConfig,
            show: true,
            subTitle: "An error occurred while ending the game.",
          });
        }
      })
      .catch((error) => {
        setPopupConfig({
          ...popupConfig,
          show: true,
          subTitle: "An error occurred while ending the game.",
        });
      });
  }

  function handleShowGameRules() {
    setShowRules(true);
  }
  function handleHideGameRules() {
    setShowRules(false);
  }
  function handleNextGame() {
    localStorage.setItem("speedArithmeticsStatus", "not-started");
    navigate(API.createRoute("speedArithmeticGame"));
  }

  return (
    <Container>
      <Alert />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="196"
        height="258"
        viewBox="0 0 196 258"
        fill="none"
        className="triangle-left"
      >
        <path
          d="M50.2849 -14.7254L6.20731 -5.3114C4.52742 -4.94628 2.99543 -4.09472 1.81671 -2.87084C0.63798 -1.64697 -0.131161 -0.109287 -0.387596 1.53609L-39.7821 247.788C-39.9928 249.092 -39.8755 250.421 -39.44 251.662C-39.0045 252.903 -38.2639 254.019 -37.2811 254.915C-36.2983 255.812 -35.1025 256.462 -33.7956 256.811C-32.4886 257.159 -31.1092 257.196 -29.775 256.917L188.995 210.193C190.32 209.896 191.556 209.297 192.601 208.446C193.645 207.595 194.467 206.517 194.997 205.302C195.527 204.088 195.75 202.772 195.647 201.466C195.544 200.16 195.118 198.902 194.405 197.797L59.1841 -11.1612C58.2685 -12.5694 56.9248 -13.6659 55.3337 -14.3031C53.7426 -14.9404 51.9806 -15.0877 50.2849 -14.7254ZM2.33331 216.234L7.20085 185.203L12.6468 150.214L33.5653 17.0246L106.299 130.213L125.512 159.934L142.452 186.308L2.33331 216.234Z"
          fill="#CDF545"
          fill-opacity="0.05"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="350"
        height="348"
        viewBox="0 0 350 348"
        fill="none"
        className="divide-svg"
      >
        <path
          d="M181.789 203.945L265.498 264.319L289.558 230.493L205.849 170.118L168.713 143.126L85.0038 82.7516L60.2507 116.62L143.959 176.994L181.789 203.945Z"
          fill="#CDF545"
          fill-opacity="0.05"
        />
        <path
          d="M209.16 121.191C220.941 131.628 238.953 130.539 249.39 118.758C259.827 106.977 258.737 88.9654 246.956 78.5283C235.175 68.0912 217.164 69.1806 206.727 80.9616C196.29 92.7426 197.379 110.754 209.16 121.191Z"
          fill="#CDF545"
          fill-opacity="0.05"
        />
        <path
          d="M103.18 268.219C114.961 278.656 132.972 277.567 143.409 265.786C153.846 254.005 152.757 235.993 140.976 225.556C129.195 215.119 111.184 216.209 100.746 227.99C90.3094 239.771 91.3988 257.782 103.18 268.219Z"
          fill="#CDF545"
          fill-opacity="0.05"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="205"
        height="283"
        viewBox="0 0 205 283"
        fill="none"
        className="plus-svg-left"
      >
        <path
          d="M6.86005 14.7681L28.5066 123.633C28.6591 124.4 28.6645 125.189 28.5223 125.954C28.3801 126.719 28.0931 127.446 27.6778 128.093C27.2626 128.739 26.7271 129.293 26.1019 129.723C25.4767 130.152 24.7741 130.449 24.0343 130.596L-83.3969 151.957C-84.8911 152.254 -86.2018 153.155 -87.0405 154.461C-87.8792 155.766 -88.1773 157.37 -87.8692 158.92L-79.5137 200.942C-79.2049 202.495 -78.3162 203.867 -77.0416 204.758C-75.767 205.65 -74.2101 205.989 -72.7107 205.7L34.7204 184.339C36.1966 184.045 37.7334 184.364 39.0002 185.227C40.267 186.089 41.1626 187.427 41.4945 188.952L63.141 297.817C63.4491 299.367 64.3382 300.735 65.6126 301.62C66.8871 302.506 68.4425 302.837 69.9367 302.54L105.56 295.456C107.055 295.159 108.365 294.259 109.204 292.953C110.043 291.647 110.341 290.043 110.033 288.494L88.3863 179.628C88.0782 178.079 88.3763 176.475 89.215 175.169C90.0537 173.863 91.3644 172.963 92.8586 172.666L200.29 151.304C201.785 150.997 203.094 150.088 203.931 148.777C204.767 147.466 205.064 145.858 204.755 144.305L196.399 102.283C196.091 100.734 195.202 99.3659 193.928 98.4803C192.653 97.5947 191.098 97.2641 189.604 97.5612L82.1724 118.923C80.6963 119.216 79.1595 118.898 77.8927 118.035C76.6259 117.172 75.7302 115.834 75.3984 114.309L53.7518 5.44423C53.4437 3.89473 52.5547 2.52674 51.2802 1.64119C50.0058 0.755599 48.4504 0.424998 46.9561 0.722108L11.3324 7.80546C9.83814 8.10257 8.52751 9.00306 7.68879 10.3088C6.85006 11.6146 6.55195 13.2186 6.86005 14.7681Z"
          fill="#CDF545"
          fill-opacity="0.05"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="199"
        height="231"
        viewBox="0 0 199 231"
        fill="none"
        className="plus-svg-right"
      >
        <path
          d="M260.986 115.054L166.584 89.759C165.919 89.5807 165.294 89.2778 164.745 88.8676C164.196 88.4574 163.734 87.9479 163.385 87.3682C163.036 86.7885 162.807 86.1499 162.712 85.4889C162.616 84.8279 162.655 84.1576 162.827 83.516L187.789 -9.64259C188.136 -10.9383 187.935 -12.324 187.231 -13.4948C186.526 -14.6656 185.375 -15.5256 184.032 -15.8856L147.592 -25.6494C146.246 -26.0103 144.816 -25.8427 143.616 -25.1833C142.416 -24.5238 141.542 -23.4261 141.186 -22.1299L116.224 71.0287C115.881 72.3089 115.031 73.3984 113.856 74.0629C112.682 74.7274 111.277 74.9138 109.943 74.582L15.541 49.2871C14.1973 48.927 12.7708 49.0965 11.5752 49.7581C10.3796 50.4198 9.5129 51.5194 9.16572 52.8151L0.888492 83.7061C0.541305 85.0019 0.7421 86.3875 1.44671 87.5583C2.15131 88.7291 3.30197 89.5891 4.64562 89.9491L99.0476 115.244C100.391 115.604 101.542 116.464 102.247 117.635C102.951 118.806 103.152 120.191 102.805 121.487L77.843 214.646C77.5034 215.946 77.7112 217.334 78.4211 218.505C79.1309 219.676 80.2851 220.536 81.6317 220.897L118.071 230.661C119.414 231.021 120.841 230.852 122.037 230.19C123.232 229.528 124.099 228.429 124.446 227.133L149.408 133.974C149.751 132.694 150.601 131.605 151.775 130.94C152.95 130.276 154.355 130.089 155.689 130.421L250.091 155.716C251.434 156.076 252.861 155.907 254.057 155.245C255.252 154.583 256.119 153.484 256.466 152.188L264.743 121.297C265.09 120.001 264.89 118.616 264.185 117.445C263.48 116.274 262.33 115.414 260.986 115.054Z"
          fill="#CDF545"
          fill-opacity="0.05"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="272"
        height="317"
        viewBox="0 0 272 317"
        fill="none"
        className="triangle-right"
      >
        <path
          d="M98.724 0.499593L47.9103 12.3397C45.9738 12.7983 44.2189 13.8167 42.8809 15.2585C41.5428 16.7002 40.6856 18.4964 40.424 20.4063L0.104569 306.263C-0.111391 307.776 0.0529391 309.312 0.583556 310.739C1.11417 312.166 1.99529 313.442 3.15199 314.458C4.30869 315.475 5.70661 316.202 7.22673 316.577C8.74683 316.953 10.3441 316.965 11.8825 316.614L264.086 257.849C265.613 257.477 267.031 256.757 268.222 255.75C269.413 254.742 270.341 253.477 270.928 252.06C271.516 250.643 271.745 249.115 271.598 247.606C271.451 246.096 270.931 244.649 270.082 243.385L109.1 4.43414C108.011 2.82395 106.432 1.5836 104.577 0.880142C102.721 0.176677 100.679 0.0438691 98.724 0.499593ZM48.173 268.84L53.1419 232.82L58.6954 192.208L80.053 37.6052L166.66 167.051L189.534 201.039L209.705 231.202L48.173 268.84Z"
          fill="#CDF545"
          fill-opacity="0.05"
        />
      </svg>
      <svg
        width="250"
        height="223"
        viewBox="0 0 250 223"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="divider-right"
      >
        <path
          d="M187.825 164.957L295.851 135.883L283.872 92.5146L175.846 121.588L127.747 134.344L19.7217 163.417L31.2617 207.398L139.287 178.325L187.825 164.957Z"
          fill="#CDF545"
          fill-opacity="0.05"
        />
        <path
          d="M136.967 85.335C153.801 82.5705 165.207 66.6825 162.443 49.8481C159.678 33.0138 143.79 21.6078 126.956 24.3723C110.122 27.1368 98.7157 43.0248 101.48 59.8592C104.245 76.6935 120.133 88.0995 136.967 85.335Z"
          fill="#CDF545"
          fill-opacity="0.05"
        />
        <path
          d="M188.084 275.015C204.919 272.251 216.324 256.363 213.56 239.528C210.795 222.694 194.908 211.288 178.073 214.052C161.239 216.817 149.833 232.705 152.597 249.539C155.362 266.374 171.25 277.78 188.084 275.015Z"
          fill="#CDF545"
          fill-opacity="0.05"
        />
      </svg>

      <InnerContainer>
        <UltimathLogo />
        <Stepper currentChallenge="memory-matrix" activeStep={0} />
        <GameTitle>
          <h1>Мемори матрикс</h1>
          {memoryMatrixStatus !== "playing" &&
            userInfo.memoryMatrixResult === null ? (
            <p>Кликни старт за да го започнеш предизвикот.</p>
          ) : (
            ""
          )}
        </GameTitle>

        {isLoading ? (
          <div className="spinner-container">
            <LoadingSpinner />
          </div>
        ) : (
          <div>
            {memoryMatrixStatus !== "playing" &&
              userInfo.memoryMatrixResult === null && (
                <GameDuration className="text-center">
                  <p>{userInfo.memoryMatrixDuration}</p>
                  <span>секунди</span>
                </GameDuration>
              )}

            {!showRules &&
              memoryMatrixStatus !== "playing" &&
              userInfo.memoryMatrixResult === null && (
                <div className="button-container">
                  <CustomButton
                    bgColor="#005840"
                    color="#CDF545"
                    onClick={startGame}
                  >
                    СТАРТ
                  </CustomButton>

                  <CustomButton
                    bgColor="rgba(0, 88, 64, 0.20)"
                    color="#005840"
                    onClick={handleShowGameRules}
                  >
                    КАКО ФУНКЦИОНИРА
                  </CustomButton>
                </div>
              )}

            {showRules && userInfo.memoryMatrixResult === null && (
              <GameRules onHide={handleHideGameRules}>
                <video width="100%" controls muted>
                  <source src={memoryMatrixVideo} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </GameRules>
            )}

            {memoryMatrixStatus === "playing" &&
              userInfo.memoryMatrixResult === null && (
                <>
                  <GameDuration className="text-center">
                    <p>{duration}</p>
                    <span>секунди</span>
                  </GameDuration>

                  <MatrixContainer>
                    <Message>{message}</Message>
                    <MatrixInnerContainer>
                      <Matrix id="matrix" size={getMatrixSize(currentLevel)}>
                        {matrixCells.map((cell, idx) => (
                          <Cell
                            key={idx}
                            data-index={idx}
                            onClick={() => handleCellClick(idx)}
                            style={{
                              backgroundColor:
                                isGlowing && COLORS[idx % COLORS.length],
                            }}
                            className={`${idx === activeIndex ? "active" : ""
                              } ${!canClick ? "disabled" : ""} ${idx === pattern[distractionIndex] &&
                                idx === activeIndex
                                ? "distraction"
                                : ""
                              } ${isShaking ? "shake" : ""} ${isGlowing ? "glow" : ""
                              } ${idx === wrongIndex ? "wrong" : ""}`}
                          ></Cell>
                        ))}
                      </Matrix>
                    </MatrixInnerContainer>

                    <ScoreContainer>
                      <ScoreDisplay className="points">
                        <p>Поени</p>
                        <p>{score}</p>
                      </ScoreDisplay>
                      <ScoreDisplay className="level">
                        <p>Ниво</p>
                        <p>
                          {currentLevel}
                          {currentSubLevel}
                        </p>
                      </ScoreDisplay>
                    </ScoreContainer>
                  </MatrixContainer>
                </>
              )}

            {userInfo.memoryMatrixResult !== null && (
              <>
                <GameDuration className="text-center">
                  <p>0</p>
                  <span>секунди</span>
                </GameDuration>

                <MatrixContainer>
                  <Message>Времето истече!</Message>

                  <FinalResultContainer>
                    <FinalResult>
                      <p className="header">ФИНАЛЕН РЕЗУЛТАТ</p>
                      <div className="divider"></div>
                      <p className="score">
                        Ниво: {userInfo.memoryMatrixLevel}
                        {userInfo.memoryMatrixSublevel}
                      </p>
                      <p className="final-score">
                        Финален резултат: {userInfo.memoryMatrixResult}
                      </p>
                    </FinalResult>
                    <div className="button-container">
                      <CustomButton
                        bgColor="#005840"
                        color="#CDF545"
                        onClick={handleNextGame}
                      >
                        следно ниво
                      </CustomButton>
                    </div>
                  </FinalResultContainer>
                </MatrixContainer>
              </>
            )}
          </div>
        )}
      </InnerContainer>
    </Container>
  );
};

export default MemoryMatrixGame;
