export const hourglass = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="26"
    viewBox="0 0 16 26"
    fill="none"
  >
    <g clip-path="url(#clip0_113_5341)">
      <path
        d="M7.27095 13.0001H5.81641V11.5557H7.27095V13.0001Z"
        fill="#005840"
      />
      <path d="M7.27095 14.4444H5.81641V13H7.27095V14.4444Z" fill="#005840" />
      <path
        d="M5.81783 15.8888H4.36328V14.4443H5.81783V15.8888Z"
        fill="#005840"
      />
      <path
        d="M4.3647 17.3334H2.91016V15.8889H4.3647V17.3334Z"
        fill="#005840"
      />
      <path
        d="M5.81783 11.5555H4.36328V10.1111H5.81783V11.5555Z"
        fill="#005840"
      />
      <path
        d="M4.3647 10.1112H2.91016V8.66675H4.3647V10.1112Z"
        fill="#005840"
      />
      <path
        d="M2.90767 8.66669H1.45312V2.88892H2.90767V8.66669Z"
        fill="#005840"
      />
      <path d="M0 0V1.44444V2.88889H16V1.44444V0H0Z" fill="#005840" />
      <path
        d="M8.72656 13.0001H10.1811V11.5557H8.72656V13.0001Z"
        fill="#005840"
      />
      <path d="M8.72656 14.4444H10.1811V13H8.72656V14.4444Z" fill="#005840" />
      <path
        d="M10.1836 15.8888H11.6381V14.4443H10.1836V15.8888Z"
        fill="#005840"
      />
      <path
        d="M11.6367 17.3334H13.0913V15.8889H11.6367V17.3334Z"
        fill="#005840"
      />
      <path
        d="M10.1836 11.5555H11.6381V10.1111H10.1836V11.5555Z"
        fill="#005840"
      />
      <path
        d="M7.27344 10.1112H8.72798V8.66675H7.27344V10.1112Z"
        fill="#005840"
      />
      <path
        d="M5.81641 8.66661H7.27095V7.22217H5.81641V8.66661Z"
        fill="#005840"
      />
      <path
        d="M8.72656 8.66661H10.1811V7.22217H8.72656V8.66661Z"
        fill="#005840"
      />
      <path
        d="M11.6367 10.1112H13.0913V8.66675H11.6367V10.1112Z"
        fill="#005840"
      />
      <path
        d="M13.0898 8.66669H14.5444V2.88892H13.0898V8.66669Z"
        fill="#005840"
      />
      <path
        d="M2.90767 17.3333H1.45312V23.111H2.90767V17.3333Z"
        fill="#005840"
      />
      <path d="M0 23.1111V24.5555V26H16V24.5555V23.1111H0Z" fill="#005840" />
      <path
        d="M10.1815 20.2221V18.7777H8.72692V17.3333H7.27237V18.7777H5.81783V20.2221H4.36328V21.6666H11.636V20.2221H10.1815Z"
        fill="#005840"
      />
      <path
        d="M13.0898 17.3333H14.5444V23.111H13.0898V17.3333Z"
        fill="#005840"
      />
    </g>
    <defs>
      <clipPath id="clip0_113_5341">
        <rect width="16" height="26" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const hourglass_red = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="26"
    viewBox="0 0 16 26"
    fill="none"
  >
    <g clip-path="url(#clip0_113_5367)">
      <path
        d="M7.27095 13.0001H5.81641V11.5557H7.27095V13.0001Z"
        fill="#81003B"
      />
      <path d="M7.27095 14.4444H5.81641V13H7.27095V14.4444Z" fill="#81003B" />
      <path
        d="M5.81783 15.8888H4.36328V14.4443H5.81783V15.8888Z"
        fill="#81003B"
      />
      <path
        d="M4.3647 17.3334H2.91016V15.8889H4.3647V17.3334Z"
        fill="#81003B"
      />
      <path
        d="M5.81783 11.5555H4.36328V10.1111H5.81783V11.5555Z"
        fill="#81003B"
      />
      <path
        d="M4.3647 10.1112H2.91016V8.66675H4.3647V10.1112Z"
        fill="#81003B"
      />
      <path
        d="M2.90767 8.66669H1.45312V2.88892H2.90767V8.66669Z"
        fill="#81003B"
      />
      <path d="M0 0V1.44444V2.88889H16V1.44444V0H0Z" fill="#81003B" />
      <path
        d="M8.72656 13.0001H10.1811V11.5557H8.72656V13.0001Z"
        fill="#81003B"
      />
      <path d="M8.72656 14.4444H10.1811V13H8.72656V14.4444Z" fill="#81003B" />
      <path
        d="M10.1836 15.8888H11.6381V14.4443H10.1836V15.8888Z"
        fill="#81003B"
      />
      <path
        d="M11.6367 17.3334H13.0913V15.8889H11.6367V17.3334Z"
        fill="#81003B"
      />
      <path
        d="M10.1836 11.5555H11.6381V10.1111H10.1836V11.5555Z"
        fill="#81003B"
      />
      <path
        d="M7.27344 10.1112H8.72798V8.66675H7.27344V10.1112Z"
        fill="#81003B"
      />
      <path
        d="M5.81641 8.66661H7.27095V7.22217H5.81641V8.66661Z"
        fill="#81003B"
      />
      <path
        d="M8.72656 8.66661H10.1811V7.22217H8.72656V8.66661Z"
        fill="#81003B"
      />
      <path
        d="M11.6367 10.1112H13.0913V8.66675H11.6367V10.1112Z"
        fill="#81003B"
      />
      <path
        d="M13.0898 8.66669H14.5444V2.88892H13.0898V8.66669Z"
        fill="#81003B"
      />
      <path
        d="M2.90767 17.3333H1.45312V23.111H2.90767V17.3333Z"
        fill="#81003B"
      />
      <path d="M0 23.1111V24.5555V26H16V24.5555V23.1111H0Z" fill="#81003B" />
      <path
        d="M10.1815 20.2221V18.7777H8.72692V17.3333H7.27237V18.7777H5.81783V20.2221H4.36328V21.6666H11.636V20.2221H10.1815Z"
        fill="#81003B"
      />
      <path
        d="M13.0898 17.3333H14.5444V23.111H13.0898V17.3333Z"
        fill="#81003B"
      />
    </g>
    <defs>
      <clipPath id="clip0_113_5367">
        <rect width="16" height="26" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
