import React, { Suspense, useEffect, useRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import Wrapper from "../../../../Components/Wrapper/Wrapper";
import theme from "../../../../Consts/Theme";
import heroBackground from "./Static/heroBackground.png";
import heroBackgroundMobile from "./Static/bg-mobile.png";

import { ultimathLogoVector } from "./Static/ultimath-logo-vector";
import { arrowLeftDown } from "./Static/arrow-left-down";
import { ellipse1 } from "./Static/ellipse1";
import { arrowRight } from "./Static/arrow-right";
import { homepage10k } from "./Static/homepage-10k";
import { next } from "./Static/next";
import { nu } from "./Static/nu";
import { ultimathLogo } from "./Static/ultimath-logo";
import { arrowRightRed } from "./Static/arrow-right-red";
import { Link } from "react-router-dom";
import { API } from "../../../../Consts/Api";
import { GlobalContext } from "../../../../Context/GlobalContext";

// styled-components
const StyledContainer = styled.section`
  background: ${theme.black2};
  background-image: url(${window.innerWidth > 768
    ? heroBackground
    : heroBackgroundMobile});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  position: relative;
  z-index: 0;
  overflow: hidden;
  padding-top: 200px;
  height: 100vh;
  min-height: 950px;

  .navbar-parent {
    position: absolute;
    top: 32px;
    left: 50%;
    transform: translateX(-50%);
  }

  .navbar {
    padding: 10px 10px 10px 26px;
    position: relative;
    background-color: #f2f1ee;
    border-radius: 16px;
    display: flex;
    align-items: center;
    font-family: Inter;

    .text-label {
      position: absolute;
      right: -12px;
      bottom: -12px;
      display: inline-flex;
      transform: rotate(-2deg);
      padding: 8px 16px;
      justify-content: center;
      align-items: center;
      gap: 12px;
      border-radius: 8px;
      background: linear-gradient(350deg, #ffb3d3 -6.34%, #fff 164.35%);
      box-shadow: 0px 0px 10px 0px #ffb3d3;
      color: #81003b;
      font-family: Inter;
      font-size: 11px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px; /* 145.455% */
      text-transform: uppercase;
    }

    .logo {
      margin-right: 29px;
      width: 98px;
    }

    .navbar-items {
      display: flex;
      gap: 24px;
      margin-right: 40px;
    }

    .item {
      font-size: 14px;
      color: #000;
      cursor: pointer;
    }

    .reg-button {
      display: inline-flex;
      padding: 16px 32px;
      justify-content: center;
      align-items: center;
      gap: 12px;
      border-radius: 10px;
      background: #cdf545;
      color: #005840;
    }

    .reg-button span {
      white-space: nowrap;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      text-transform: uppercase;
    }
  }

  .ml-40 {
    margin-left: 40px;
  }

  .mb-8 {
    margin-bottom: 8px;
  }

  .flex-align-center {
    display: flex;
    align-items: center;
  }

  .logos-wrapper {
    border-radius: 16px;
    background: radial-gradient(
      45.61% 93.33% at 52.71% 116.67%,
      rgba(205, 245, 69, 0.1) 0%,
      rgba(0, 88, 64, 0.2) 100%
    );
    backdrop-filter: blur(5px);
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    padding: 16px 24px;
    display: flex;
    align-items: center;
  }

  .logos-wrapper > :first-child {
    color: #f4f1ea;
    font-size: 13px;
    font-family: Inter;
    width: 110px;
    line-height: 14px;
    margin-right: 24px;
  }

  .logos-wrapper > :nth-child(2) {
    margin-right: 12px;
  }

  @media (max-width: 768px) {
    padding-top: 140px;
    min-height: 760px;

    .logos-wrapper {
      padding: 16px 9px;
      bottom: 24px;
      background: radial-gradient(
        92.24% 143.33% at 52.35% 153.33%,
        rgba(205, 245, 69, 0.1) 0%,
        rgba(0, 88, 64, 0.2) 100%
      );
    }

    .logos-wrapper > :first-child {
      font-size: 12px;
      margin-right: 0px;
    }

    .logos-wrapper svg {
      width: 120px;
    }

    .navbar-parent {
      top: 24px;
    }

    .navbar {
      padding: 10px 10px 10px 16px;

      .logo {
        margin-right: 16px;
        width: 88px;
      }

      .navbar-items {
        display: none;
      }

      .reg-button {
        background: #cdf545;
        color: #005840;
        padding: 16px 24px;

        svg {
          display: none;
        }
      }

      .text-label {
        right: 34px;
      }
    }
  }

  @media (max-width: 400px) {
    .logos-wrapper svg {
      width: 100px;
    }
  }
`;

const StyledContentHeroBlock = styled.article`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 10px;

  & > svg {
    margin-top: 24px;
  }

  .grouped-badges {
    display: flex;
    gap: 8px;
  }

  .badge {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    border-radius: 100px;
    background: rgba(205, 245, 69, 0.2);
    height: 40px;
  }

  .badge.badge-date {
    padding-left: 12px;
    display: flex;
    align-items: center;
    color: #cdf545;
  }

  .badge-date > :nth-child(2) {
    font-size: 15px;
  }

  .text {
    margin-left: 6px;
    color: #cdf545;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    text-transform: uppercase;
  }

  .text-date {
    margin-left: 4px;
    font-family: Ligo;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  .badges-arrow-grouped {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .grouped-arrows {
    display: flex;
    gap: 12px;
  }

  .text-button-grouped {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 18px;
    position: relative;

    .text-22 {
      color: #f4f1ea;
      font-family: Inter;
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .reg-button {
      display: inline-flex;
      padding: 16px 32px;
      justify-content: center;
      align-items: center;
      gap: 12px;
      border-radius: 10px;
      background: #cdf545;
      color: #005840;

      & > span {
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        text-transform: uppercase;
      }

      & .text-label {
        display: inline-flex;
        transform: rotate(-2deg);
        padding: 8px 16px;
        justify-content: center;
        align-items: center;
        gap: 12px;
        border-radius: 8px;
        background: linear-gradient(350deg, #ffb3d3 -6.34%, #fff 164.35%);
        box-shadow: 0px 0px 10px 0px #ffb3d3;
        position: absolute;
        right: -14px;
        bottom: -20px;
        color: #81003b;
        font-size: 11px !important;
        font-style: normal;
        font-weight: 600;
        line-height: 16px; /* 145.455% */
        text-transform: uppercase;
      }
    }
  }

  .image-10k {
    width: 100%;
    max-width: 570px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
  }

  @media (max-width: 768px) {
    margin-top: 0px;

    & > svg {
      margin-top: 34px;
    }

    .grouped-badges {
      flex-direction: column;
    }

    .grouped-badges .badge {
      align-self: start;
    }

    .grouped-badges .badge.badge-text {
      padding: 10px 20px;
    }

    .grouped-badges .badge.badge-text .text {
      font-size: 13px;
    }

    .grouped-badges .badge.badge-date {
      padding: 8px 16px;
    }

    .grouped-arrows > :nth-child(1) {
      display: none;
    }

    .grouped-arrows > :nth-child(2) {
      display: none;
    }

    .grouped-arrows > :nth-child(3) {
      display: none;
    }

    .grouped-arrows {
      align-self: end;
      margin-bottom: 8px;
    }

    .text-button-grouped {
      flex-direction: column;
      justify-content: baseline;
      margin-top: 24px;
      gap: 24px;

      .text-20 {
        font-size: 17px;
        line-height: 24px;
      }

      .reg-button .text-label {
        right: 12px;
      }
    }

    .image-10k {
      margin-top: 80px;
    }
  }
`;

const HomepageHeroDesktop = ({ handleScrollToSection,  }) => {
  const { userInfo } = useContext(GlobalContext);
  return (
    <StyledContainer>
      <div className="navbar-parent">
        <div className="navbar">
          <span className="text-label">БЕСПЛАТНО</span>

          <div className="logo">{ultimathLogo}</div>
          {/* <div className="navbar-items">
                        <div
                            className="item"
                            onClick={() => {
                                handleScrollToSection("structure-section");
                            }}
                        >
                            Структура
                        </div>
                        <div
                            href="#"
                            className="item"
                            onClick={() => {
                                handleScrollToSection("competition-info-section");
                            }}
                        >
                            Информации
                        </div>
                        <a href="#" className="item">
                            ЧПП
                        </a>
                    </div> */}
          <Link
            to={
              userInfo.referred_by
                ? API.createRoute("applyWithReferral", {
                    REFERRAL_GUID: userInfo.referred_by,
                    ACADEMY_ID: 1,
                  })
                : API.createRoute("apply", { ACADEMY_ID: 1 })
            }
            className="reg-button"
          >
            {arrowRight}
            <span>ЗАПОЧНИ СО НАТПРЕВАРОТ</span>
          </Link>
        </div>
      </div>

      <Wrapper>
        <header></header>

        <StyledContentHeroBlock>
          <div className="badges-arrow-grouped">
            <div className="grouped-badges">
              <div className="badge badge-text">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M15.346 11.5816V7.373L9.64748 10.4668C9.44335 10.5821 9.22791 10.6398 9.00116 10.6398C8.77429 10.6398 8.55798 10.5821 8.35223 10.4668L2.62054 7.34131C2.49754 7.27106 2.4091 7.18563 2.35523 7.085C2.30135 6.9845 2.27441 6.87275 2.27441 6.74975C2.27441 6.62675 2.30135 6.51506 2.35523 6.41469C2.4091 6.31419 2.49754 6.22881 2.62054 6.15856L8.35204 3.03687C8.45379 2.98037 8.55835 2.938 8.66573 2.90975C8.7731 2.88138 8.88448 2.86719 8.99985 2.86719C9.11523 2.86719 9.2266 2.88162 9.33398 2.9105C9.44123 2.93937 9.54573 2.98269 9.64748 3.04044L16.1132 6.55813C16.2269 6.61513 16.3149 6.69775 16.3772 6.806C16.4397 6.91438 16.471 7.03125 16.471 7.15663V11.5816C16.471 11.741 16.417 11.8746 16.3092 11.9825C16.2013 12.0903 16.0677 12.1441 15.9083 12.1441C15.7488 12.1441 15.6152 12.0903 15.5076 11.9825C15.3999 11.8746 15.346 11.741 15.346 11.5816ZM8.35223 14.5269L4.83304 12.6243C4.61191 12.5013 4.4386 12.3325 4.3131 12.1179C4.1876 11.9031 4.12485 11.6715 4.12485 11.423V8.83269L8.35223 11.123C8.55635 11.2384 8.77179 11.2961 8.99854 11.2961C9.22541 11.2961 9.44173 11.2384 9.64748 11.123L13.8749 8.83269V11.4243C13.8749 11.6744 13.8121 11.9062 13.6866 12.1196C13.5611 12.3331 13.3878 12.5013 13.1667 12.6243L9.64748 14.5269C9.54523 14.5845 9.4401 14.6278 9.3321 14.6566C9.22423 14.6855 9.11348 14.6999 8.99985 14.6999C8.88623 14.6999 8.77548 14.6855 8.6676 14.6566C8.5596 14.6278 8.45448 14.5845 8.35223 14.5269Z"
                    fill="#CDF545"
                  />
                </svg>
                <span className="text">САМО ЗА СРЕДНОШКОЛЦИ</span>
              </div>
              <div className="badge badge-date">
                {ellipse1}
                <span>РУНДА 0 |</span>
                <span className="text-date">29.10 - 26.11</span>
              </div>
            </div>

            <div className="grouped-arrows">
              {arrowLeftDown}
              {arrowLeftDown}
              {arrowLeftDown}
              {arrowLeftDown}
            </div>
          </div>

          {ultimathLogoVector}

          <div className="text-button-grouped">
            <p className="text-22">
                Те бива за математика? Докажи!
            </p>

            <Link
              to={
                userInfo.referred_by
                  ? API.createRoute("applyWithReferral", {
                      REFERRAL_GUID: userInfo.referred_by,
                      ACADEMY_ID: 1,
                    })
                  : API.createRoute("apply", { ACADEMY_ID: 1 })
              }
              className="reg-button"
            >
              {arrowRight}
              <span>ЗАПОЧНИ СО НАТПРЕВАРОТ</span>
              <span className="text-label">БЕСПЛАТНО</span>
            </Link>
          </div>
          <div className="image-10k">{homepage10k}</div>
        </StyledContentHeroBlock>
      </Wrapper>

      <div className="logos-wrapper">
        <div>Натпреварот е организиран од</div>
        <div>{nu}</div>
        <div>{next}</div>
      </div>
    </StyledContainer>
  );
};

export default HomepageHeroDesktop;
