export const arrowRight = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="12"
        viewBox="0 0 22 12"
        fill="none"
    >
        <path
            d="M16.6588 0.5H14.66L14.6706 4.82249H0.445312C0.195465 4.82249 0.0466195 4.95389 0.00409246 5.21672V6.77957C-0.00466137 6.83272 0.000672792 6.88694 0.0196663 6.93797C0.0386599 6.989 0.0707956 7.03544 0.113556 7.07364C0.156316 7.11185 0.208534 7.14078 0.266099 7.15816C0.323663 7.17554 0.385007 7.18089 0.445312 7.1738H14.66V11.1021C14.651 11.1557 14.6564 11.2103 14.6757 11.2618C14.695 11.3132 14.7276 11.36 14.771 11.3983C14.8144 11.4366 14.8673 11.4654 14.9256 11.4824C14.9839 11.4994 15.0458 11.5042 15.1065 11.4963H16.6588C16.7191 11.5034 16.7804 11.498 16.838 11.4806C16.8955 11.4633 16.9478 11.4343 16.9905 11.3961C17.0333 11.3579 17.0654 11.3115 17.0844 11.2605C17.1034 11.2094 17.1087 11.1552 17.1 11.1021V9.34208H19.1147C19.175 9.34917 19.2363 9.34382 19.2939 9.32644C19.3515 9.30906 19.4037 9.28014 19.4464 9.24193C19.4892 9.20372 19.5214 9.15728 19.5403 9.10625C19.5593 9.05522 19.5647 9.001 19.5559 8.94785V7.1738H21.5547C21.615 7.18089 21.6763 7.17554 21.7339 7.15816C21.7915 7.14078 21.8437 7.11185 21.8864 7.07364C21.9292 7.03544 21.9613 6.989 21.9803 6.93797C21.9993 6.88694 22.0047 6.83272 21.9959 6.77957V5.21672C21.9959 4.99613 21.8471 4.86473 21.5547 4.82249H19.5559V3.06252C19.5559 2.84194 19.4071 2.71052 19.1147 2.66828H17.1V0.894232C17.1 0.673649 16.9511 0.542239 16.6588 0.5Z"
            fill="currentColor"
        />
    </svg>
);
