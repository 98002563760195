import React from "react";
import styled from "styled-components";

const Container = styled.section`
  border-radius: 16px;
  border: 1px solid #e1ddd4;
  background: #f4f1ea;
  padding: 32px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    h2 {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #005840;
    }
    span {
      cursor: pointer;
      border-radius: 100px;
      background: rgba(0, 88, 64, 0.1);
      display: inline-flex;
      padding: 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }

    .hide-button {
      z-index: 99 !important;
      svg {
        z-index: 99 !important;
        display: block;
      }

      path {
        z-index: 99 !important; 
      }
    }
  }
`;

const GameRules = ({ onHide, children }) => {
  return (
    <Container>
      <div className="header">
        <h2>ПРАВИЛНИК</h2>
        <span className="hide-button" onClick={onHide}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              d="M4.8 14.25L3.75 13.2L7.95 9L3.75 4.8L4.8 3.75L9 7.95L13.2 3.75L14.25 4.8L10.05 9L14.25 13.2L13.2 14.25L9 10.05L4.8 14.25Z"
              fill="#005840"
            />
          </svg>
        </span>
      </div>
      {children}
    </Container>
  );
};

export default GameRules;
