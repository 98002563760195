export const wvpLogo = <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 200 188" fill="none">
<path d="M48.4142 113.622V114.957H45.3466V116.848H47.8983V118.19H45.3466V121.096H43.6025V113.622H48.4142Z" fill="#010101"/>
<path d="M52.8835 119.727C53.0981 119.73 53.3113 119.692 53.5116 119.615C53.6873 119.54 53.8442 119.427 53.9715 119.284C54.1043 119.13 54.2037 118.951 54.2631 118.756C54.3335 118.527 54.3676 118.289 54.3641 118.049V113.627H56.0801V118.049C56.0869 118.481 56.0107 118.909 55.8558 119.312C55.7115 119.683 55.4941 120.02 55.2165 120.305C54.9282 120.588 54.5845 120.808 54.207 120.951C53.3398 121.257 52.3936 121.257 51.5264 120.951C51.1489 120.808 50.8052 120.588 50.5169 120.305C50.2479 120.02 50.0416 119.682 49.9113 119.312C49.7563 118.909 49.6802 118.481 49.6869 118.049V113.627H51.4198V118.044C51.4163 118.283 51.4504 118.522 51.5208 118.751C51.5802 118.945 51.6795 119.125 51.8124 119.278C51.9379 119.424 52.0952 119.539 52.2722 119.615C52.4708 119.691 52.682 119.73 52.8947 119.727" fill="#010101"/>
<path d="M85.1748 66.9485L77.0375 84.0195V66.9485H65.5074L59.0413 83.6323V66.9485H44.0117V109.761H57.1626L62.5463 101.298V109.761H76.4655L91.9045 81.9039V66.9485H85.1748Z" fill="#010101"/>
<path d="M116.137 66.9485L106.592 84.244V66.9485H93.1494V109.766H110.719L123.31 82.0498V66.9485H116.137Z" fill="#010101"/>
<path d="M138.048 82.0049C140.689 81.9656 144.177 81.9319 144.177 85.2373C144.177 89.3563 140.212 88.7895 137.195 88.6604V82.0105H138.048M124.555 66.9317V109.766H137.195V100.108C147.677 100.108 156.47 96.545 156.397 82.4314C156.341 73.1214 150.374 68.8453 143.039 67.5658C139.651 67.0238 136.223 66.7741 132.793 66.8194C129.882 66.8194 127.095 66.9317 124.555 66.9317Z" fill="#010101"/>
<mask id="mask0_562_106" maskUnits="userSpaceOnUse" x="124" y="66" width="33" height="44">
  <path d="M138.048 82.0049C140.689 81.9656 144.177 81.9319 144.177 85.2373C144.177 89.3563 140.212 88.7895 137.195 88.6604V82.0105H138.048M124.555 66.9317V109.766H137.195V100.108C147.677 100.108 156.47 96.545 156.397 82.4314C156.341 73.1214 150.374 68.8453 143.039 67.5658C139.651 67.0238 136.223 66.7741 132.793 66.8194C129.882 66.8194 127.095 66.9317 124.555 66.9317Z" fill="white"/>
</mask>
<g mask="url(#mask0_562_106)">
  <path d="M166.94 61.2301H36.4971V122.393H166.94V61.2301Z" fill="#010101"/>
</g>
<path d="M58.5982 113.627H58.7889L58.9291 113.666C58.972 113.687 59.0117 113.713 59.0469 113.745L59.1758 113.885L62.7201 118.375C62.7201 118.229 62.7201 118.089 62.7201 117.954C62.7201 117.819 62.7201 117.696 62.7201 117.572V113.644H64.2455V121.113H63.3426C63.2273 121.118 63.1122 121.097 63.0061 121.052C62.8996 121 62.8073 120.923 62.737 120.827L59.2207 116.377C59.2207 116.506 59.2207 116.629 59.2207 116.759C59.2207 116.888 59.2207 117 59.2207 117.106V121.113H57.6953V113.627H58.5982Z" fill="#010101"/>
<path d="M72.6805 117.359C72.685 117.87 72.5898 118.377 72.4001 118.852C72.2236 119.297 71.9562 119.7 71.615 120.036C71.2656 120.377 70.8494 120.643 70.3924 120.816C69.8861 121.01 69.3474 121.105 68.8054 121.096H65.9004V113.627H68.8054C69.3474 113.619 69.8861 113.714 70.3924 113.908C70.8483 114.083 71.2642 114.348 71.615 114.688C71.9548 115.022 72.2221 115.424 72.4001 115.866C72.5898 116.341 72.685 116.848 72.6805 117.359ZM70.9084 117.359C70.9121 117.02 70.8629 116.683 70.7626 116.36C70.6769 116.082 70.5357 115.824 70.3476 115.602C70.1691 115.394 69.9444 115.231 69.6914 115.125C69.4108 115.009 69.1091 114.952 68.8054 114.957H67.6445V119.766H68.8054C69.1091 119.772 69.4108 119.715 69.6914 119.598C69.9444 119.492 70.1691 119.329 70.3476 119.121C70.5357 118.899 70.6769 118.641 70.7626 118.363C70.8628 118.038 70.912 117.699 70.9084 117.359Z" fill="#010101"/>
<path d="M79.7685 117.679C79.8414 117.814 79.9087 117.948 79.9704 118.089C80.0321 118.229 80.0938 118.369 80.1498 118.521C80.2059 118.369 80.2676 118.223 80.3349 118.077C80.4022 117.931 80.4639 117.802 80.5368 117.668L82.4548 113.885C82.4831 113.832 82.5169 113.781 82.5557 113.734C82.5919 113.701 82.6336 113.674 82.6791 113.655C82.7274 113.637 82.7787 113.627 82.8305 113.627H84.3503V121.096H82.8193V116.798C82.8193 116.588 82.8305 116.364 82.8529 116.124L80.8733 119.963C80.819 120.076 80.7334 120.172 80.6265 120.238C80.5172 120.3 80.3932 120.331 80.2676 120.328H80.0321C79.9082 120.331 79.7858 120.3 79.6788 120.238C79.569 120.174 79.481 120.078 79.4264 119.963L77.4412 116.119C77.4412 116.237 77.4412 116.355 77.4412 116.472C77.4412 116.59 77.4412 116.702 77.4412 116.798V121.096H75.9102V113.627H77.4355C77.4855 113.627 77.5351 113.636 77.5814 113.655C77.6274 113.673 77.6694 113.699 77.7047 113.734C77.7472 113.779 77.7831 113.83 77.8113 113.885L79.7685 117.679Z" fill="#010101"/>
<path d="M92.9925 121.096H91.6522C91.5204 121.103 91.39 121.066 91.2821 120.99C91.186 120.928 91.1146 120.835 91.0802 120.726L90.6371 119.419H87.7995L87.3564 120.726C87.3163 120.83 87.246 120.92 87.1545 120.984C87.0499 121.063 86.9209 121.103 86.79 121.096H85.4385L88.3322 113.627H90.1044L92.9925 121.096ZM88.1976 118.24H90.239L89.5492 116.192L89.3921 115.732C89.3361 115.558 89.2744 115.367 89.2183 115.17C89.1622 115.378 89.1061 115.569 89.0501 115.732C88.994 115.894 88.9323 116.063 88.8818 116.192L88.1976 118.24Z" fill="#010101"/>
<path d="M94.9778 113.627H95.1685L95.3031 113.666C95.3475 113.684 95.3876 113.711 95.4209 113.745C95.4679 113.788 95.511 113.835 95.5499 113.885L99.0998 118.375C99.0998 118.229 99.0885 118.089 99.0661 117.954C99.0661 117.819 99.0661 117.696 99.0661 117.572V113.644H100.597V121.113H99.6942C99.5788 121.119 99.4637 121.098 99.3577 121.052C99.2493 121.003 99.1563 120.925 99.0885 120.827L95.5667 116.377C95.5667 116.506 95.5667 116.629 95.5947 116.759C95.6228 116.888 95.5947 117 95.5947 117.106V121.113H94.0693V113.627H94.9778Z" fill="#010101"/>
<path d="M109.228 121.096H107.888C107.756 121.103 107.625 121.066 107.517 120.99C107.421 120.928 107.35 120.835 107.316 120.726L106.872 119.419H104.035L103.592 120.726C103.552 120.83 103.481 120.92 103.39 120.984C103.285 121.063 103.156 121.103 103.025 121.096H101.674L104.568 113.627H106.34L109.228 121.096ZM104.433 118.24H106.474L105.785 116.192L105.628 115.732C105.571 115.558 105.51 115.367 105.454 115.17C105.398 115.378 105.341 115.569 105.285 115.732C105.229 115.894 105.168 116.063 105.117 116.192L104.433 118.24Z" fill="#010101"/>
<path d="M113.507 119.845C113.722 119.85 113.936 119.829 114.146 119.783C114.324 119.744 114.499 119.69 114.668 119.621V118.498H113.927C113.834 118.503 113.742 118.473 113.669 118.414C113.612 118.354 113.58 118.273 113.58 118.19V117.236H116.238V120.389C116.049 120.528 115.848 120.65 115.638 120.754C115.426 120.858 115.204 120.941 114.976 121.001C114.732 121.069 114.482 121.118 114.23 121.147C113.947 121.177 113.663 121.192 113.378 121.192C112.857 121.198 112.34 121.099 111.858 120.9C111.407 120.718 110.997 120.447 110.652 120.103C110.309 119.755 110.036 119.344 109.85 118.891C109.655 118.408 109.556 117.891 109.559 117.37C109.554 116.845 109.649 116.323 109.839 115.833C110.015 115.379 110.285 114.968 110.63 114.626C110.987 114.28 111.41 114.011 111.875 113.835C112.397 113.643 112.95 113.548 113.507 113.554C114.065 113.551 114.62 113.648 115.144 113.84C115.364 113.926 115.575 114.033 115.772 114.16C115.957 114.276 116.13 114.41 116.288 114.559L115.784 115.322C115.743 115.391 115.685 115.449 115.615 115.49C115.551 115.533 115.474 115.554 115.397 115.552C115.283 115.55 115.172 115.515 115.077 115.451C114.943 115.367 114.814 115.3 114.69 115.238C114.571 115.18 114.447 115.131 114.32 115.092C114.187 115.055 114.053 115.027 113.916 115.008C113.759 114.999 113.602 114.999 113.445 115.008C113.145 115.003 112.847 115.06 112.57 115.176C112.317 115.289 112.091 115.456 111.909 115.664C111.718 115.884 111.573 116.14 111.482 116.416C111.382 116.732 111.333 117.062 111.337 117.393C111.329 117.751 111.384 118.108 111.499 118.448C111.596 118.736 111.751 119.001 111.953 119.228C112.141 119.439 112.375 119.604 112.638 119.71C112.913 119.824 113.209 119.881 113.507 119.879" fill="#010101"/>
<path d="M122.564 113.622V114.957H119.496V116.702H121.846V117.988H119.496V119.766H122.564V121.096H117.758V113.622H122.564Z" fill="#010101"/>
<path d="M127.83 117.679C127.903 117.814 127.965 117.948 128.032 118.089C128.099 118.229 128.156 118.369 128.212 118.521C128.268 118.369 128.329 118.223 128.397 118.077C128.464 117.931 128.526 117.802 128.593 117.668L130.511 113.885C130.541 113.831 130.577 113.78 130.618 113.734C130.654 113.701 130.695 113.674 130.741 113.655C130.789 113.637 130.841 113.627 130.892 113.627H132.412V121.096H130.881V116.798C130.881 116.588 130.892 116.364 130.915 116.124L128.935 119.963C128.881 120.076 128.795 120.172 128.688 120.238C128.579 120.3 128.455 120.331 128.329 120.328H128.094C127.97 120.331 127.848 120.3 127.741 120.238C127.631 120.174 127.543 120.078 127.488 119.963L125.497 116.119C125.497 116.239 125.509 116.356 125.531 116.472C125.531 116.59 125.531 116.702 125.531 116.798V121.096H124V113.627H125.525C125.575 113.627 125.625 113.637 125.671 113.655C125.717 113.673 125.759 113.699 125.795 113.734C125.833 113.782 125.869 113.832 125.901 113.885L127.83 117.679Z" fill="#010101"/>
<path d="M138.912 113.622V114.957H135.85V116.702H138.194V117.988H135.85V119.766H138.912V121.096H134.105V113.622H138.912Z" fill="#010101"/>
<path d="M141.272 113.627H141.463L141.603 113.666C141.646 113.687 141.686 113.713 141.721 113.745L141.85 113.885L145.394 118.375C145.394 118.229 145.394 118.089 145.366 117.954C145.338 117.819 145.366 117.696 145.366 117.572V113.644H146.891V121.113H145.977C145.862 121.118 145.747 121.097 145.641 121.052C145.534 121 145.442 120.923 145.371 120.827L141.85 116.377C141.85 116.506 141.85 116.629 141.878 116.759C141.906 116.888 141.878 117 141.878 117.106V121.113H140.347V113.627H141.272Z" fill="#010101"/>
<path d="M153.997 113.622V115.002H151.899V121.096H150.166V115.002H148.063V113.622H153.997Z" fill="#010101"/>
</svg>