//npm imports
import styled, { keyframes, css, withTheme } from "styled-components/macro";
import { Text14 } from "../Text/Text";

const slideArrowIn = keyframes`
    0%{
        transform: translate(-100%, 0);
    }
    100%{   
        transform: translate(0%, 0)
    }
`;

//styled-components
export const StyledButtonWide = styled.button`
  width: 100%;
  height: 55px;
  background-color: ${(props) =>
    props.disabled
      ? props.theme.gray2
      : props.colored
        ? props.colored
        : props.theme.violet};
  outline: none;
  text-align: left;
  padding-left: 40px;
  padding-right: 40pzzx;
  border: none;
  border-radius: 6px;
  transition: background-color 0.1s linear;
  color:  ${(props) =>
    props.disabled ? props.theme.white : props.theme.neonYellow};

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
  text-transform: uppercase;
  /* ${Text14}{
        color: ${(props) => props.theme.white};
    } */
  display: flex;
  justify-content: space-between;
  align-items: center;
  .arrow-container {
    overflow: hidden;
  }
  &:hover {
    /* svg {
      ${(props) =>
    !props.disabled &&
    css`
        animation: ${slideArrowIn} 0.15s linear forwards;
      `}
    } */
    background-color: ${(props) =>
    props.disabled ? props.theme.gray2 : props.theme.violet};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  }
`;

//main component
const ButtonWide = ({ text, onClick, type, disabled, theme, colored }) => {
  return (
    <StyledButtonWide
      type={type}
      onClick={onClick}
      disabled={disabled}
      colored={colored}
    >
      <p fontWeight={700}>{text}</p>
      <div className="arrow-container">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="29"
          height="25"
          viewBox="0 0 29 25"
          fill="none"
        >
          <path
            d="M16.1624 0.0202596L28.2843 12.1421L26.264 14.1624L14.1421 2.04056L16.1624 0.0202596Z"
            fill="#CDF545"
          />
          <path
            d="M14.1421 22.2436L26.264 10.1218L28.2843 12.1421L16.1624 24.2639L14.1421 22.2436Z"
            fill="#CDF545"
          />
          <path
            d="M20.2031 12.1421L22.2234 10.1218L24.2437 12.1421L22.2234 14.1624L20.2031 12.1421Z"
            fill="#CDF545"
          />
          <path
            d="M16.1624 12.1421L18.1827 10.1218L20.2031 12.1421L18.1827 14.1624L16.1624 12.1421Z"
            fill="#CDF545"
          />
          <path
            d="M12.1218 12.1421L14.1421 10.1218L16.1624 12.1421L14.1421 14.1624L12.1218 12.1421Z"
            fill="#CDF545"
          />
          <path
            d="M8.08122 12.1421L10.1015 10.1218L12.1218 12.1421L10.1015 14.1624L8.08122 12.1421Z"
            fill="#CDF545"
          />
          <path
            d="M4.04061 12.1421L6.06092 10.1218L8.08122 12.1421L6.06092 14.1624L4.04061 12.1421Z"
            fill="#CDF545"
          />
          <path
            d="M0 12.1421L2.02031 10.1218L4.04061 12.1421L2.02031 14.1624L0 12.1421Z"
            fill="#CDF545"
          />
        </svg>
      </div>
    </StyledButtonWide>
  );
};

export default withTheme(ButtonWide);